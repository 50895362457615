import React, { useState } from "react";
import "./Modalpage.scss";
import img from "../../assests/ic_login.jpeg";
import img2 from "../../assests/googlePlay.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InstagramIcon from "@mui/icons-material/Instagram";
import AppleIcon from "@mui/icons-material/Apple";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ModalComponent from "../../components/ModalComponent/ModalComponent";

import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
const Modalpage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="mainDiv">
        <Box className="box1">
          {/* <div className="line1">
            <Box className="topright">
              <Box
                className="boxLogo"
                sx={{ display: "flex", flexDirection: "column", zIndex: "200" }}
                onClick={() => navigate("/login")}
              >
                <AccountCircleIcon className="logo" />
                <Typography
                  className="logo text-font-family"
                  variant="subtitle1"
                  gutterBottom
                >
                  Login
                </Typography>
              </Box>
            </Box>
          </div> */}
          <div
            style={{
              height: "3.5em",
              display: "flex",
              width: "100%",
              backgroundColor: "black",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                className="LoginButton"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "var(--pink)",
                  width: "313px",
                  borderRadius: "21px",
                }}
                onClick={() => {
                  // localStorage.clear();
                  navigate("/login");
                }}
              >
                Login
              </Button>
            </div>
          </div>
          <Box className="box2" sx={{ mt: 0 }}>
            <img className="image" src={img} alt="Sorry" />
          </Box>
        </Box>

        <Box className="textDiv">
          <Box>
            <Typography
              className="line_11 text-font-family"
              sx={{ fontSize: "1.8em" }}
              gutterBottom
            >
              {process.env.REACT_APP_NAME}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "90%",
            }}
          >
            <Typography
              className="line_1 text-font-family"
              sx={{ fontSize: "1.3em" }}
              gutterBottom
            >
              {process.env.REACT_APP_MODAL_INFO_FIRST_PAGE}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "90%",
              mt: 1,
            }}
          >
            <Typography
              className="line_1 text-font-family"
              sx={{ fontSize: "1.3em" }}
              gutterBottom
            >
              {/* {process.env.REACT_APP_MODAL_INFO_FIRST_PAGE1}
               */}
              Visha;
            </Typography>
          </Box>
        </Box>

        <Box sx={{ mt: 3, backgroundColor: "var(--firstPagePinkColor)" }}>
          <Typography
            sx={{
              pl: 3,
              fontSize: "1.5em",
              color: "var(--homepagetitlecolor)",
            }}
            className="text-font-family"
          >
            All Things Social
          </Typography>
        </Box>

        <Box className="social">
          <a
            href={process.env.REACT_APP_INSTAGRAM_LINK}
            style={{ textDecoration: "none", width: "87%", marginTop: "20px" }}
          >
            <div className="socialApps">
              <Box className="s1">
                <Box sx={{ ml: 3, display: "flex", justifyContent: "center" }}>
                  <InstagramIcon sx={{ color: "var(--instagramIconColor)" }} />
                </Box>

                <Box>
                  <Typography
                    sx={{
                      mr: 1,
                      ml: 2,
                      fontSize: "1em",
                      color: "var(--homepagetitlecolor)",
                    }}
                    className="text-font-family"
                  >
                    INSTAGRAM
                  </Typography>
                </Box>
              </Box>
            </div>
          </a>
          <Box className="downloadButtons">
            <a
              className="apple"
              // onClick={() => setOpen(true)}
              href={process.env.REACT_APP_APPLE_STORE_LINK}
            >
              <Box className="icons">
                <AppleIcon
                  sx={{
                    fontSize: "3em",
                    color: "var(--white)",
                    mr: 1,
                    aspectRatio: "75:25",
                  }}
                />
              </Box>

              <Box className="icons" sx={{ mr: 1 }}>
                <Typography
                  className="text1 text-font-family"
                  sx={{
                    fontSize: "0.6em",
                    color: "var(--white)",
                  }}
                >
                  AVAILABLE ON THE <br />{" "}
                  <Typography
                    className="text2 text-font-family"
                    sx={{
                      fontSize: "1.5em",
                      color: "var(--white)",
                    }}
                  >
                    APP STORE
                  </Typography>
                </Typography>
              </Box>
            </a>

            <a
              className="apple"
              href={process.env.REACT_APP_GOOGLE_PLAY_STORE_LINK}
            >
              <Box className="icons">
                <img
                  src={img2}
                  style={{
                    fontSize: "2.5em",
                    backgroundColor: "var(--black)",
                    color: "var(--white)",
                    height: "1em",
                    width: "1em",
                  }}
                />
              </Box>

              <Box className="icons" sx={{ ml: 1 }}>
                <Typography
                  className="text1 text-font-famil"
                  sx={{
                    fontSize: "0.6em",
                    color: "var(--white)",
                  }}
                >
                  GET IT ON <br />{" "}
                  <Typography
                    className="text2 text-font-family"
                    sx={{
                      fontSize: "1.35em",
                      color: "var(-white)",
                    }}
                  >
                    GOOGLE PLAY
                  </Typography>
                </Typography>
              </Box>
            </a>
          </Box>
        </Box>

        <Box className="rights" sx={{ mt: 4 }}>
          {/* <Typography
            className="text-font-family"
            sx={{ fontSize: "1.4em", color: "var(--homepagetitlecolor)" }}
          >
            {" "}
            Create your own app{" "}
          </Typography> */}

          <div
            className="socialApps_1"
            onClick={() => window.open("https://wa.me/919004940445")}
          >
            <Box className="s1">
              <Box
                sx={{
                  ml: 3,
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "0.3em",
                }}
              >
                <WhatsAppIcon sx={{ color: "var(--instagramIconColor)" }} />
              </Box>

              <Box>
                <Typography
                  sx={{
                    mr: 1,
                    ml: 2,
                    fontSize: "1em",
                    color: "var(--homepagetitlecolor)",
                    width: "max-content",
                    height: "1.5em",
                  }}
                  className="text-font-family"
                >
                  Contact Support
                </Typography>
              </Box>
            </Box>
          </div>
          <Typography
            sx={{
              fontSize: "0.7em",
              mt: 3,
              color: "var(--homepagetitlecolor)",
              mb: 3,
            }}
            className="text-font-family"
          >
            {" "}
            {process.env.REACT_APP_COPY_RIGHT_TEXT}{" "}
          </Typography>
        </Box>
      </div>

      {open && (
        <ModalComponent
          open={open}
          close={() => setOpen(false)}
          text="Coming soon..."
          title={process.env.REACT_APP_DIALOG_APP_NAME}
        />
      )}
    </>
  );
};

export default Modalpage;
