import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import CollectionsIcon from "@mui/icons-material/Collections";
import MovieIcon from "@mui/icons-material/Movie";
import WifiTetheringIcon from "@mui/icons-material/WifiTethering";
import ForumIcon from "@mui/icons-material/Forum";

const BottomBar = () => {
  const [values, setValues] = useState(0);
  const navigate = useNavigate();
  const route = useLocation();
  let isModal = JSON.parse(localStorage.getItem(process.env.REACT_APP_ISMODAL_FLAG));

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValues(newValue);
  };

  return (
    <Box className="general">
      <Box className="homecss">
        <Tabs
          value={values}
          onChange={()=>handleChange()}
          className="bottom-menu "
          aria-label="basic tabs example"
        >
          <Tab
            className="typography-loginpage"
            onClick={() =>{navigate(isModal ?"/a/Home" : "/userhomepage/home")}}
            label={{
              ...(route.pathname  === "/userhomepage/home" || route.pathname === "/a/Home" ? (
                <>
                  <div>
                    <HomeIcon className="icon" style={{color:'var(--white)'}}/>
                    <span className="icon" style={{color:'var(--white)'}}>&nbsp;{process.env.REACT_APP_BOTTOM_BAR_ICON_NAME1}</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="actviepage">
                    <HomeIcon className="icon" />
                    <span className="icon">&nbsp;{process.env.REACT_APP_BOTTOM_BAR_ICON_NAME1}</span>
                  </div>
                </>
              )),
            }}
            {...a11yProps(0)}
          />
          <Tab
            className="typography-loginpage"
            onClick={() => navigate(isModal? "/a/uploadphoto":"/userhomepage/photos")}
            label={{
              ...(route.pathname === "/userhomepage/photos" || route.pathname ==="/a/uploadphoto"? (
                <>
                  <div>
                    <CollectionsIcon className="icon" style={{color:'var(--white)'}}/>
                    <span className="icon" style={{color:'var(--white)'}}>&nbsp;{process.env.REACT_APP_BOTTOM_BAR_ICON_NAME2}</span>
                  </div>
                </>
              ) : (
                <>
                  <div  className="actviepage">
                    <CollectionsIcon className="icon" />
                    <span className="icon">&nbsp;{process.env.REACT_APP_BOTTOM_BAR_ICON_NAME2}</span>
                  </div>
                </>
              )),
            }}
            {...a11yProps(1)}
          />
          <Tab
            className="typography-loginpage"
            onClick={() => navigate(isModal?"/a/uploadvideo":"/userhomepage/videos")}
            label={{
              ...(route.pathname === "/userhomepage/videos"  || route.pathname ==="/a/uploadvideo"? (
                <>
                  <div>
                    <MovieIcon className="icon" style={{color:'var(--white)'}}/>
                    <span className="icon" style={{color:'var(--white)'}}>&nbsp;{process.env.REACT_APP_BOTTOM_BAR_ICON_NAME3}</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="actviepage">
                    <MovieIcon className="icon" />
                    <span className="icon">&nbsp;{process.env.REACT_APP_BOTTOM_BAR_ICON_NAME3}</span>
                  </div>
                </>
              )),
            }}
            {...a11yProps(2)}
          />
          <Tab
            className="typography-loginpage"
            onClick={() => navigate(isModal?"/a/live":"/userlive")}

            label={{
              ...(route.pathname == "/userlive" || route.pathname == "/a/live" ? (
                <>
                  {" "}
                  <div>
                    <WifiTetheringIcon className="icon" style={{color:'var(--white)'}}/>
                    <span className="icon" style={{color:'var(--white)'}}>&nbsp;{process.env.REACT_APP_BOTTOM_BAR_ICON_NAME4}</span>
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <div className="actviepage">
                    <WifiTetheringIcon className="icon" />
                    <span className="icon">&nbsp;{process.env.REACT_APP_BOTTOM_BAR_ICON_NAME4}</span>
                  </div>
                </>
              )),
            }}
            {...a11yProps(3)}
          />

          <Tab
            className="typography-loginpage"
            onClick={() => navigate(isModal?"/modalsideuserlisting":"/userdm")}
            label={{
              ...(route.pathname === "/userdm" || route.pathname === "/modalsideuserlisting" ? (
                <>
                  {" "}
                  <div>
                    <ForumIcon className="icon" style={{color:'var(--white)'}}/>
                    <span className="icon" style={{color:'var(--white)'}}>&nbsp;{process.env.REACT_APP_BOTTOM_BAR_ICON_NAME5}</span>
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <div className="actviepage">
                    <ForumIcon className="icon" />
                    <span className="icon">&nbsp;{process.env.REACT_APP_BOTTOM_BAR_ICON_NAME5}</span>
                  </div>
                </>
              )),
            }}
            {...a11yProps(4)}
          />
        </Tabs>
      </Box>
    </Box>
  );
};

export default BottomBar;
