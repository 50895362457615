import Recharge_Gems from "../../assests/recharge_gems.png";
import Diamands from "../../assests/diamands.png";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Rechargepage.scss";
import {
  addDoc,
  collection,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import { useNavigate } from "react-router-dom";
import Spinners from "../../components/Spinner/Spinners";

function Rechargepage() {
  const [rechargeArray, setRechargeArray] = useState([]);
  const [loading, setIsLoding] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [wallet, setWallet] = useState();
  useEffect(() => {
    const q2 = doc(
      db,
      process.env.REACT_APP_WALLET_COLLECTION_NAME,
      user.idUser
    );
    getDoc(q2).then((response) => {
      //  setSelectedUserWallet(response.data());
      const walletData = response?.data();
      setWallet(walletData);
      // console.log(response.data(), "RESPONSE");
    });

    window.scrollTo({ behavior: "smooth" });
    setIsLoding(true);
    const q1 = query(
      collection(
        db,
        process.env.REACT_APP_FEATURES_ENABLED,
        process.env.REACT_APP_RECHARGE_GEMS_DOCUMENT_ID,
        process.env.REACT_APP_RECHARGE_GEMS_SUBCOLLECTION_NAME
      ),
      orderBy("price", "asc")
    );
    let array = [];
    getDocs(q1).then((result) => {
      result.forEach((doc) => {
        array.push({ ...doc.data(), paymentObjId: doc.id });
        setRechargeArray(array);
      });

      setIsLoding(false);
    });
  }, []);

  const handleClick = (val) => {
    setIsLoding(true);
    let accessToken = Math.floor(100000 + Math.random() * 900000);
    let rezarPayPaymentObject = {
      paymentToken: accessToken,
      status: process.env.REACT_APP_RECHARGE_GEMS_STATUS_START,
      uid: user.idUser,
      time: serverTimestamp(),
      prize: val.price,
      gems: val.gems,
      free_gems: val?.free_gems ?? 0,
    };
    addDoc(
      collection(db, process.env.REACT_APP_RECHARGE_GEMS_ADD_COLLECTION_NAME),
      rezarPayPaymentObject
    ).then((result) => {
      let paymentObject = JSON.stringify({
        ...val,
        token: rezarPayPaymentObject.paymentToken,
        platform: process.env.REACT_APP_PLATFORM,
        appId: process.env.REACT_APP_RAZARPAY_APPID,
        username: user.name,
        email: user.email,
        mobile: user.mobile,
        testMode: true,
      });
      paymentObject = btoa(paymentObject);
      setIsLoding(false);
      window.location.href =
        process.env.REACT_APP_RECHARGE_URL.concat(paymentObject);
    });
  };
  return (
    <Box>
      {loading ? <Spinners /> : null}
      <Box className="rechargebox">
        <img alt="" src={Recharge_Gems} className="rechargeimg" />
      </Box>
      <Box className="rechargeheadingbox">
        <Typography variant="h4" id="rechargeheading">
          Wallet Balance:
        </Typography>
        <Typography variant="h4" id="rechargeheading">
          {wallet?.balance}
        </Typography>
      </Box>
      <Box className="rechargecontentbox">
        {rechargeArray.length === 0 ? (
          <Typography style={{ top: "50%", left: "40%", position: "fixed" }}>
            No Data
          </Typography>
        ) : (
          rechargeArray.map((val) => {
            const stateData = {
              price: val.price,
              gems: val.gems,
            };
            return (
              <Box
                className="rechargecontent button-color"
                onClick={
                  // () => (testUser ? handleTestUserWallet(val) : handleClick(val))
                  () => {
                    navigate("/mainback/paymentPage", { state: stateData });

                    console.log("bbbbbbbbbbbbbbbbbbbbbbbbbb");
                  }
                }
              >
                <Box style={{ display: "flex" }}>
                  <img
                    alt=""
                    src={Diamands}
                    style={{
                      height: "25px",
                      width: "25px",
                      marginRight: "10px",
                    }}
                  />
                  <Typography variant="body1">
                    {val.gems}
                    {val?.free_gems > 0 ? ` + ${val?.free_gems} free` : null}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1">Rs {val.price}</Typography>
                </Box>
              </Box>
            );
          })
        )}
      </Box>
    </Box>
  );
}

export default Rechargepage;
