import { Box, Typography, duration } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./UserVideoCall.scss";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import Spinner2 from "../../components/Spinner/Spinner2";

function VideoPending() {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );

  const [userPendingRequest, setUserPendingRequet] = useState([]);
  useEffect(() => {
    getPendingRequest();
  }, []);

  const getPendingRequest = async () => {
    try {
      let q1 = query(
        collection(
          db,
          process.env.REACT_APP_VIDEO_CALL_REQUEST_COLLECTION_NAME
        ),
        where("uid", "==", user.idUser),
        orderBy("requestedAt", "desc")
      );
      getDocs(q1).then((result) => {
        let details = [];
        result.forEach((doc) => {
          details.push(doc.data());
        });
        setUserPendingRequet(details);
      });
    } catch (error) {
      console.log(error, "ERROR");
    }
  };

  return (
    <>
      <Box>
        {userPendingRequest.length === 0 ? (
          <Typography style={{ left: "40%", position: "fixed" }}>
            No Records
          </Typography>
        ) : (
          userPendingRequest.map((val) => {
            return (
              <>
                <Box className="cmdbox">
                  <Typography variant="body2" className="cpmmsg">
                    {process.env.REACT_APP_REQIESTED_ON_TEXT.replaceAll(
                      '"',
                      " "
                    )}{" "}
                    {val.time} {val.date}{" "}
                  </Typography>
                  <Typography variant="body2" className="cpmmsg">
                    {process.env.REACT_APP_VIDEO_CALL_DURATION_TEXT}: {val.mins}{" "}
                    mins
                  </Typography>
                </Box>
              </>
            );
          })
        )}
      </Box>
    </>
  );
}

export default VideoPending;
