import { Box, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";

function Model_Update_gems() {
  const navigate = useNavigate();
  const [data, setData] = useState({ name: "", email: "" });
  const [user, setUser] = useState([]);

  const getData = () => {
    const q2 = query(
      collection(db, process.env.REACT_APP_USERS_COLLECTION_NAME),
      where("email", "==", data.email.trim())
    );
    getDocs(q2).then((result) => {
      let users = [];
      result.forEach((user) => {
        users.push(user.data());
      });
      setUser(users);
    });
  };

  const enter = () => {
    navigate("/updatebalance", { state: { val: user } });
  };
  return (
    <Box className="m_ug">
      <Box className="AddDminputbox">
        <TextField
          type="email"
          placeholder={process.env.REACT_APP_SEARCH_WITH_EMAIL_PLACEHOLDER_TEXT}
          variant="outlined"
          focused={false}
          id="myprofile-form-field"
          fullWidth
          size="small"
          onChange={(e) => setData({ ...data, email: e.target.value })}
        />
        <Box
          className="searchbtnbox"
          onClick={() => {
            data.email !== "" && getData();
          }}
        >
          <SearchIcon style={{ margin: "0px" }} />
        </Box>
      </Box>
      <Box className="search_result_box">
        {user.length > 0 &&
          user.map((ele) => {
            return (
              <Box className="search_result_boxa" onClick={() => enter()}>
                <Typography variant="body1" className="or">
                  {ele.name}
                </Typography>
                <Typography variant="body1" className="or">
                  {ele.email}
                </Typography>
                <Typography variant="body1" className="or">
                  {ele.mobile}
                </Typography>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
}

export default Model_Update_gems;
