import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import LiveImage from "../../assests/LIVE IMAGE.png";
import { useNavigate } from "react-router-dom";
import AgoraRTC from "agora-rtc-sdk-ng";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import {
  addDoc,
  collection,
  getDocs,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import ModalforPurchase from "../Photopage/ModalforPurchase";

const LiveStrimingPage = (props) => {
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [liveData, setLiveData] = useState({});
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [joinedUser, setJoiedUser] = useState({});
  const [open, setOpen] = useState({ open: false, val: "" });
  const [wallet, setWallet] = useState();

  useEffect(() => {
    let q4 = query(
      collection(db, process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME),
      orderBy("createdOn", "desc"),
      limit(1)
    );
    onSnapshot(q4, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setLiveData({ ...doc.data(), liveStrimingDocId: doc.id });
      });
    });

    const q = query(
      collection(db, process.env.REACT_APP_WALLET_COLLECTION_NAME),
      where(
        "uid",
        "==",
        JSON.parse(
          localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE)
        ).idUser
      )
    );
    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setWallet(doc.data());
        localStorage.setItem(
          process.env.REACT_APP_WALLET_LOCALSTORAGE,
          JSON.stringify(doc.data())
        );
      });
    });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  var rtc = {
    client: null,
    joined: false,
    published: false,
    localStream: null,
    remoteStreams: [],
    params: {},
  };

  // Options for joining a channel
  var option = {
    appID: process.env.REACT_APP_AGORA_APP_ID,
    channel: process.env.REACT_APP_AGORA_CHANNEL_NAME,
    uid: null,
    token: liveData.accessToken,
    key: "",
    secret: "",
  };

  // async function joinChannel(role) {
  //   // Create a client
  //   rtc.client = AgoraRTC.createClient({ mode: "live", codec: "h264" });

  //   rtc.client.on("user-published", async (user, mediaType) => {
  //     if (mediaType === "video") {
  //       const remoteVideoStream = await rtc.client.subscribe(user, mediaType);
  //       remoteVideoStream.play("remote_video_");
  //     }
  //   });

  //   rtc.client.on("user-unpublished", async (user) => {
  //     const remoteVideoElement = document.getElementById(
  //       `remote_video_${user.uid}`
  //     );
  //     if (remoteVideoElement) {
  //       remoteVideoElement.innerHTML = "";
  //     }
  //   });

  //   rtc.client.on("connection-state-change", (currentState, prevState) => {
  //     console.log("Connection state changed:", currentState, prevState);

  //     if (currentState === "DISCONNECTED") {
  //     }
  //   });

  //   try {
  //     await rtc.client.join(option.appID, option.channel, option.token, null);
  //   } catch (err) {
  //     console.error("Error joining channel:", err);
  //     return;
  //   }

  //   rtc.params.uid = rtc.client.uid;

  //   if (role === "host") {
  //     rtc.client.setClientRole("host");

  //     // Create a local audio track (but don't play it immediately)
  //     rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();

  //     rtc.client.on("connection-state-change", function (evt) {
  //       console.log("audience", evt);
  //     });
  //   }

  //   if (role === "audience") {
  //     rtc.client.on("user-published", async (user, mediaType) => {
  //       if (mediaType === "audio") {
  //         // Subscribe to the host's audio stream and play it
  //         const remoteAudioStream = await rtc.client.subscribe(user, mediaType);
  //         remoteAudioStream.play(); // Automatically plays the host's audio
  //       }
  //       if (mediaType === "video") {
  //         const remoteVideoStream = await rtc.client.subscribe(user, mediaType);
  //         remoteVideoStream.play("remote_video_");
  //       }
  //     });

  //     rtc.client.on("user-unpublished", async (user) => {
  //       const remoteVideoElement = document.getElementById(
  //         `remote_video_${user.uid}`
  //       );
  //       if (remoteVideoElement) {
  //         remoteVideoElement.innerHTML = "";
  //       }
  //     });

  //     rtc.client.on("connection-state-change", function (evt) {
  //       console.log("audience", evt);
  //     });
  //   }
  // }

  const getUserJoinInfo = () => {
    let q1 = query(
      collection(
        db,
        process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
        props.liveData?.liveStrimingDocId,
        process.env.REACT_APP_WHO_JOIN_LIVE_COLLECTION_NAME
      ),
      where("uid", "==", user.idUser),
      limit(1)
    );
    onSnapshot(q1, (querySnapshot) => {
      querySnapshot.forEach((result) => {
        setJoiedUser({ ...result.data(), userJoinId: result.id });
      });
    });
  };

  useEffect(() => {
    getUserJoinInfo();
  }, []);

  const handleprejoinqueries = () => {
    if (Object.keys(joinedUser).length > 0) {
      // joinChannel("audience");
      updateDoc(
        doc(
          db,
          process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
          props.liveData?.liveStrimingDocId,
          process.env.REACT_APP_WHO_JOIN_LIVE_COLLECTION_NAME,
          joinedUser.userJoinId
        ),
        { liveJoinStatus: "join" }
      );
      navigate("/livestrmingpage", {
        state: {
          role: "audience",
          strimingid: props.liveData?.liveStrimingDocId,
          accessToken: props.liveData?.accessToken,
          enableCommentsInLiveStreaming:
            props.liveData?.enableCommentsInLiveStreaming,
          gemForUserComment: props.liveData?.gemForUserComment,
        },
      });
    } else {
      setOpen({ open: true, val: props.liveData });
    }
  };
  const handlePurchase = (val) => {
    if (wallet.balance >= val.noOfGems) {
      const q2 = query(
        collection(db, process.env.REACT_APP_USERS_COLLECTION_NAME),
        where("idUser", "==", wallet?.uid)
      );

      getDocs(q2).then((result) => {
        result.forEach((users) => {
          if (users.data()?.blocked) {
            // navigate("/login");
            navigate("/login");
            localStorage.clear();
          } else {
            if (Object.keys(joinedUser).length == 0) {
              let updatedWallet = {
                ...wallet,
                balance: wallet?.balance - props.liveData?.noOfGems,
              };
              setWallet(updatedWallet);
              localStorage.setItem(
                process.env.REACT_APP_WALLET_LOCALSTORAGE,
                JSON.stringify(updatedWallet)
              );

              const querySnapshot = doc(
                db,
                process.env.REACT_APP_WALLET_COLLECTION_NAME,
                user.idUser
              );
              updateDoc(querySnapshot, {
                balance: updatedWallet.balance,
              }).then(() => {});
              let userTransactionnsTable = {
                dm_message_gif: "",
                dm_message_image: "",
                dm_message_text: "",
                live_session_purchase_gif_name: "",
                live_session_ref_id: props.liveData?.liveStrimingDocId,
                platform: process.env.REACT_APP_PLATFORM,
                purchased_media_description: "",
                purchased_media_title: "",
                purchased_media_url: "",
                purchased_product_description: "",
                purchased_product_title: "",
                recharge_gems_transaction_amount: "",
                recharge_gems_transaction_id: "",
                transaction_gems: props.liveData?.noOfGems,
                transaction_gems_string: `-${props.liveData?.noOfGems}`,
                transaction_ref_id: props.liveData?.liveStrimingDocId,
                transaction_time: serverTimestamp(),
                transaction_type_id: "5",
                transaction_type_name: "joined_live_streaming",
                updated_balance_after_transaction: `${updatedWallet.balance}`,
                user_email: user.email,
                user_id: user.idUser,
                user_name: user.name,
                version: process.env.REACT_APP_VERSION,
                "model-identifier": process.env.REACT_APP_MODEL_IDENTIFIER,
              };
              addDoc(
                collection(
                  db,
                  process.env.REACT_APP_USERTRANSACTIONS_COLLECTION
                ),
                userTransactionnsTable
              ).then(() => {});

              let liveJoinUser = {
                brand: "web",
                deviceModel: "web",
                deviceOsVersion: "-",
                liveJoinStatus: "join",
                liveSessionId: props.liveData?.liveStrimingDocId,
                liveSessionJoinAt: serverTimestamp(),
                noOfGems: props.liveData?.noOfGems,
                platform: process.env.REACT_APP_PLATFORM,
                uid: user.idUser,
                userDp: user.urlAvatar,
                userEmail: user.email,
                userName: user.name,
                userWalletBalance: parseInt(updatedWallet.balance),
                version: process.env.REACT_APP_VERSION,
              };

              addDoc(
                collection(
                  db,
                  process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
                  props.liveData?.liveStrimingDocId,
                  process.env.REACT_APP_WHO_JOIN_LIVE_COLLECTION_NAME
                ),
                liveJoinUser
              );
            }
            // joinChannel("audience");
            navigate("/livestrmingpage", {
              state: {
                role: "audience",
                strimingid: props.liveData?.liveStrimingDocId,
                accessToken: props.liveData?.accessToken,
                enableCommentsInLiveStreaming:
                  props.liveData?.enableCommentsInLiveStreaming,
                gemForUserComment: props.liveData?.gemForUserComment,
              },
            });
            setOpen({ open: false, val: "" });
          }
        });
      });
    } else {
      setOpen({ open: true, val: props.liveData });
    }
  };
  return (
    <>
      <Box
        style={{
          backgroundColor: "var(--pink)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <Button
          variant="contained"
          className="livebtn text-shadow-typography"
          style={{
            marginTop: "62px",
            backgroundColor: "var(--pinkDark)",
            textTransform: "none",
            width: "90vw",
          }}
          onClick={() => {
            handleprejoinqueries(props.liveData);
          }}
        >
          {process.env.REACT_APP_LIVE_BUTTON}
        </Button>
      </Box>
      <img
        src={LiveImage}
        style={{ height: "-webkit-fill-available", width: "100vw" }}
      />
      {open.open && (
        <ModalforPurchase
          open={open.open}
          val={open}
          wallet={wallet?.balance}
          close={() => handleClose()}
          handlePurchase={(ele) => handlePurchase(ele)}
        />
      )}
    </>
  );
};

export default LiveStrimingPage;
