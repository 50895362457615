import { Box, Button, Grid } from '@mui/material'
import VideocamIcon from '@mui/icons-material/Videocam';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../Images/logo.png'
import React from 'react'
import './Adminheader.css'
import { Outlet } from 'react-router-dom';

function Adminheader() {
    return (
        <>
            <Box className='headermain'>

                <Grid container rowSpacing={1} className='headerbox' >
                    <Grid item >
                        <Box className='menubtn'>
                            <MenuIcon />
                        </Box>
                    </Grid>
                    <Grid item >
                        <img alt='' src={logo} className='simranimg' />
                    </Grid>
                    <Grid item >
                        <Button id='videobtn' variant="contained" startIcon={<VideocamIcon />}>{process.env.REACT_APP_YES_BUTTON}</Button>
                    </Grid>

                </Grid>
                <Box className='cmline'></Box>
            </Box>
            <Box>
                <Outlet />
            </Box>
        </>
    )
}

export default Adminheader
