import { Box, Button, Grid, Typography } from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useEffect, useState } from "react";
import logo from "../../../component/Images/logo.png";
import jems from "../../../component/Images/jems.png";
import "./Mainhead.scss";
import { Outlet, json } from "react-router-dom";
import Menu from "../../../components/Navbar user/Menu";
import {
  collection,
  onSnapshot,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig/firebaseConfig";
import { useNavigate } from "react-router-dom";

function Mainhead() {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const navigate = useNavigate();
  let isModal = JSON.parse(
    localStorage.getItem(process.env.REACT_APP_ISMODAL_FLAG)
  );
  const [open, setOpen] = useState(false);
  const [wallet, setWallet] = useState();

  useEffect(() => {
    if (!isModal) {
      getData();
      const q = query(
        collection(db, process.env.REACT_APP_WALLET_COLLECTION_NAME),
        where(
          "uid",
          "==",
          JSON.parse(
            localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE)
          ).idUser
        )
      );
      onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setWallet(doc?.data()?.balance);
          localStorage.setItem(
            process.env.REACT_APP_WALLET_LOCALSTORAGE,
            JSON.stringify(doc.data())
          );
        });
      });
    }
  }, []);

  const getData = () => {
    const q = query(
      collection(db, process.env.REACT_APP_WALLET_COLLECTION_NAME),
      where(
        "uid",
        "==",
        JSON.parse(
          localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE)
        ).idUser
      )
    );
    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setWallet(doc?.data()?.balance);
        localStorage.setItem(
          process.env.REACT_APP_WALLET_LOCALSTORAGE,
          JSON.stringify(doc.data())
        );
      });
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const videocallpage = () => {
    if (isModal) {
      navigate("/back-s/model_call", { state: { muted: false } });
    } else {
      navigate("/mainback/uservideocall", { state: { muted: false } });
    }
  };
  return (
    <Box className="hh">
      <Box className="header">
        <Grid container className="headbox">
          <Grid item xs={1.5}>
            <Box>
              <Box onClick={() => setOpen(!open)} className="menubtn">
                <MenuIcon />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <img alt="" src={logo} className="simranimg" />
          </Grid>
          {!isModal && (
            <Grid
              item
              xs={2.5}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
              onClick={() =>
                // window.open(process.env.REACT_APP_DARKMATTER_PAYMENT_LINK)
                navigate("/back/wallet")
              }
            >
              <img alt="" src={jems} className="jemsimg" />
              <Typography variant="body2" id="jemstext">
                {wallet}
              </Typography>
            </Grid>
          )}
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Button
              onClick={() => videocallpage()}
              id="videobtn"
              variant="contained"
              startIcon={<VideocamIcon />}
            >
              {process.env.REACT_APP_CALL_BUTTON}
            </Button>
          </Grid>
          {open ? <Menu open={open} close={() => handleClose()} /> : ""}
        </Grid>
        <Box className="cmline"></Box>
      </Box>
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
}

export default Mainhead;
