import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LockIcon from "@mui/icons-material/Lock";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  limit,
  orderBy,
  where,
  query,
  serverTimestamp,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import { useNavigate } from "react-router-dom";
import PlayIcon from "../../assests/play_icon.png";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ModalforPurchaseVideo from "../../User/Videopage/ModalforPurchaseVideo";
import { getAuth } from "firebase/auth";

const TopVideos = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [open, setOpen] = useState({ open: false, val: "" });
  const [wallet, setWallet] = useState();

  const [video, setVideo] = useState([]);
  const [myPurchedVideos, setMyPurchedVideos] = useState(
    JSON.parse(
      localStorage.getItem(
        process.env.REACT_APP_PURCHASEDVIDEOS_COLLECTION_NAME
      )
    )
  );
  useEffect(() => {
    getData();
    const q2 = doc(
      db,
      process.env.REACT_APP_WALLET_COLLECTION_NAME,
      user.idUser
    );
    getDoc(q2).then((response) => {
      const walletData = response?.data();
      setWallet(walletData);
    });
  }, []);

  const getData = () => {
    const q5 = query(
      collection(db, process.env.REACT_APP_VIDEOS_COLLECTION),
      orderBy("likeCounter", "desc"),
      limit(18)
    );
    getDocs(q5).then((result) => {
      const videos = [];
      result.forEach((doc) => {
        videos.push({ ...doc.data(), id: doc.id });
      });
      setVideo(videos);
    });
  };

  const handlePurchase = (val) => {
    const q2 = query(
      collection(db, process.env.REACT_APP_USERS_COLLECTION_NAME),
      where("idUser", "==", wallet?.uid)
    );

    getDocs(q2).then((result) => {
      result.forEach((users) => {
        if (users.data()?.blocked) {
          navigate("/login");
          localStorage.clear();
        } else {
          let videoPurchase = {
            boughtOn: serverTimestamp(),
            thumbUrl: val.thumbUrl,
            vdoUrl: val.vdoUrl,
            vdocreatedAt: val.createdAt,
            vdodesc: val.desc,
            vdoid: val.id,
            vdomonitizeState: "",
            vdonoofgems: val.noOfGems,
            vdotitle: val.title,
            platform: process.env.REACT_APP_PLATFORM,
            uid: auth.currentUser.uid,
            version: process.env.REACT_APP_VERSION,
            "model-identifier": process.env.REACT_APP_MODEL_IDENTIFIER,
          };
          let updatedWallet = {
            ...wallet,
            balance: wallet?.balance - val.noOfGems,
          };
          setWallet(updatedWallet);
          localStorage.setItem(
            process.env.REACT_APP_WALLET_LOCALSTORAGE,
            JSON.stringify(updatedWallet)
          );

          const querySnapshot = doc(
            db,
            process.env.REACT_APP_WALLET_COLLECTION_NAME,
            user.idUser
          );
          updateDoc(querySnapshot, {
            balance: updatedWallet.balance,
          });

          setMyPurchedVideos([...myPurchedVideos, videoPurchase]);
          localStorage.setItem(
            process.env.REACT_APP_PURCHASEDVIDEOS_COLLECTION_NAME,
            JSON.stringify([...myPurchedVideos, videoPurchase])
          );
          addDoc(
            collection(
              db,
              process.env.REACT_APP_PURCHASEDVIDEOS_COLLECTION_NAME
            ),
            videoPurchase
          );

          setOpen({ open: false, val: "" });
        }
      });
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return video?.length > 0 ? (
    <Box className="video-content">
      {video.length > 0 && (
        <Typography>{process.env.REACT_APP_TOP_VDOS_TITLE_TEXT}</Typography>
      )}
      {/* <Typography className="top-videos">
        {process.env.REACT_APP_TOP_VDOS_TITLE_TEXT}
      </Typography> */}
      <div className="rec-carousel">
        <div className="rec-carousel-item">
          <div className="jZpChr">
            <div
              className="events"
              itemsToShow={1.8}
              outerSpacing={0}
              pagination={false}
            >
              {video.map((ele) => {
                const isFree = ele.noOfGems === 0;
                const isPurchased =
                  myPurchedVideos.filter((pho) => pho.vdoid === ele.id).length >
                  0;
                const lockedStrip = !isFree
                  ? `${
                      !isPurchased
                        ? process.env.REACT_APP_LOCKED_STRIP_TEXT
                        : process.env.REACT_APP_UNLOCKED_STRIP_TEXT
                    } Video`
                  : false;

                return (
                  <div
                    className="items"
                    onClick={() =>
                      isFree || isPurchased
                        ? navigate("/videos", { state: { val: ele } })
                        : setOpen({ open: true, val: ele })
                    }
                    key={ele.id} // Add a unique key for each element
                  >
                    {lockedStrip && (
                      <div
                        className="strip-text typography-loginpage page-label"
                        style={{
                          fontSize: "14px !important",
                          top: "15px",
                        }}
                      >
                        {!isPurchased ? (
                          <LockIcon style={{ fontSize: "14px" }} />
                        ) : (
                          <LockOpenIcon style={{ fontSize: "14px" }} />
                        )}
                        &nbsp; {lockedStrip}
                      </div>
                    )}
                    <div className="video-play-icon">
                      <img src={PlayIcon} id="playicon" alt="Play Icon" />
                    </div>
                    <img
                      src={ele?.thumbUrl}
                      alt={`Video thumbnail for ${ele.vdotitle}`}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {open.open && (
        <ModalforPurchaseVideo
          open={open.open}
          val={open}
          wallet={wallet?.balance}
          close={() => handleClose()}
          handlePurchase={(ele) => handlePurchase(ele)}
        />
      )}
    </Box>
  ) : (
    <div>
      {/* <p>No videos available.</p> */}
      {/* You can customize this message or UI component according to your design preferences */}
    </div>
  );
};

export default TopVideos;
