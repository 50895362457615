import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Wallet from "../../assests/wallet_drawer_icon.png";
import Diamands from "../../assests/diamands.png";
import "./Walletpage.scss";
import ForumIcon from "@mui/icons-material/Forum";
import PlaceHolderImage from "../../assests/place_holder.png";
import WifiTetheringIcon from "@mui/icons-material/WifiTethering";
import GifBoxIcon from "@mui/icons-material/GifBox";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import InfiniteScroll from "react-infinite-scroll-component";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import VideocamIcon from "@mui/icons-material/Videocam";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useNavigate } from "react-router-dom";

function Walletpage() {
  const [wallet, setWallet] = useState();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [userTransaction, setUserTransaction] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    getTransactions();
    window.scrollTo({ top: 0, behavior: "smooth" });

    const q2 = doc(
      db,
      process.env.REACT_APP_WALLET_COLLECTION_NAME,
      user.idUser
    );
    getDoc(q2).then((response) => {
      //  setSelectedUserWallet(response.data());
      const walletData = response?.data();
      setWallet(walletData);
      // console.log(response.data(), "RESPONSE");
    });
  }, []);

  const getTransactions = () => {
    let q4;
    if (lastDoc) {
      q4 = query(
        collection(db, process.env.REACT_APP_USERTRANSACTIONS_COLLECTION),
        where("user_id", "==", user.idUser),
        orderBy("transaction_time", "desc"),
        startAfter(lastDoc),
        limit(15)
      );
    } else {
      q4 = query(
        collection(db, process.env.REACT_APP_USERTRANSACTIONS_COLLECTION),
        where("user_id", "==", user.idUser),
        orderBy("transaction_time", "desc"),
        limit(15)
      );
    }

    onSnapshot(q4, (querySnapshot) => {
      let userPersonalTransactions = [];
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      querySnapshot.forEach((doc) => {
        userPersonalTransactions.push({
          ...doc.data(),
          transaction_id: doc.id,
        });
      });

      setUserTransaction([...userTransaction, ...userPersonalTransactions]);
    });
  };

  return (
    <>
      <Box className="MyprofileHeadingMain" style={{ marginTop: "2px" }}>
        <Box
          className="MyprofileHeadingdiv"
          style={{
            position: "fixed",
            marginTop: "9px",
            width: "100%",
            zIndex: "500",
            backgroundColor: "var(--pink)",
          }}
        >
          <img alt="" src={Wallet} id="myprofileimg" />
          <Box className="mypvrline" />
          <Typography variant="h6" id="myprofileheading">
            {process.env.REACT_APP_WALLET_PAGE_TITLE}
          </Typography>
          <Divider />
        </Box>

        {/* <Box className="mypline" /> */}
      </Box>
      <Box className="walletmaindiv">
        <Box
          className="walletdivbalance"
          style={{ borderTop: "2px solid white" }}
        >
          <Typography variant="h6" id="myprofie-form-categaries">
            {process.env.REACT_APP_CURRENT_BALANCE_TEXT}&nbsp;{wallet?.balance}
          </Typography>

          <img
            alt=""
            src={Diamands}
            style={{ height: "30px", width: "30px", marginLeft: "10px" }}
          />
        </Box>
        <Box
          className="recharge-gems-style"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            top: "200px",
            padding: "10px",
            width: "calc(100vw - 20px)",
            zIndex: "100",
          }}
        >
          <Button
            onClick={() =>
              window.open(process.env.REACT_APP_DARKMATTER_PAYMENT_LINK)
            }
            className="shadow"
            style={{
              backgroundColor: "var(--blueLight)",
              borderRadius: "25px",
              padding: "10px 20%",
              boxShadow: "0px 0px 7px 1px var(--black)",
            }}
          >
            <span style={{ color: "var(--white)", textTransform: "none" }}>
              {process.env.REACT_APP_RECHARGE_GEMS_BUTTON}
            </span>
          </Button>
        </Box>
        {userTransaction?.length === 0 && (
          <Typography
            style={{
              position: "fixed",
              top: "50%",
              width: "100vw",
              textAlign: "center",
              left: 0,
            }}
          >
            No records found
          </Typography>
        )}

        <InfiniteScroll
          dataLength={userTransaction.length}
          next={() => {
            getTransactions(lastDoc);
          }}
          scrollableTarget="dapage"
          hasMore={true}
          scrollThreshold={"300px"}
          initialScrollY={0}
        >
          {userTransaction.map((val) => {
            const findTransactionType = (object) => {
              let param = object.transaction_type_name;
              switch (param) {
                case "dm_purchase":
                  return "Deducted for chat";
                case "photo_purchase":
                  return "Purchased Image";
                case "video_purchase":
                  return "Purchased Video";
                case "recharge_gems":
                  return `${val.transaction_gems} Gems for ₹${parseInt(
                    val.recharge_gems_transaction_amount
                  )}`;
                case "gems_gifted_by_model":
                  return "Gems gifted by Modal 10 Gems";
                case "joined_live_streaming":
                  return "Join Live";
                case "sent_gif_in_live_streaming":
                  return "Deducted for live chat giphys";
                case "video_call_request":
                  return "Video call requested";
                case "shoutout_request":
                  return "ShoutOut Requested";
                case "shop_item_purchased":
                  return "Deducted for chat";
                case "sent_gif_in_live_streaming":
                  return "Deducted for live chat giphys";
                case "comment_in_live_streaming":
                  return "Spent on live comment";
                default:
                  return "Spent On App";
              }
            };
            let dateTimeString;
            if (val.transaction_time) {
              dateTimeString = `${val.transaction_time
                .toDate()
                .toLocaleTimeString("en-IN", {
                  year: "numeric",

                  month: "numeric",
                  day: "numeric",
                  hour12: false,
                })}
            `
                .replaceAll("/", "-")
                .replaceAll(",", "");
            }

            return (
              <>
                <Box className="walletgiftbox">
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Box className="giftbox">
                      {val.purchased_media_url !== "" &&
                        val.transaction_type_name == "photo_purchase" && (
                          <img
                            alt=""
                            style={{ background: "cover" }}
                            src={val.purchased_media_url}
                            className="img"
                          />
                        )}
                      {val.transaction_type_name == "dm_purchase" && (
                        <ForumIcon className="icon" />
                      )}
                      {val.transaction_type_name == "shoutout_request" && (
                        <VolumeUpIcon />
                      )}
                      {val.transaction_type_name == "video_purchase" && (
                        <img
                          alt=""
                          style={{ background: "cover" }}
                          src={PlaceHolderImage}
                          className="img"
                        />
                      )}
                      {val.transaction_type_name == "gems_gifted_by_model" && (
                        <CardGiftcardIcon />
                      )}
                      {val.transaction_type_name == "joined_live_streaming" && (
                        <WifiTetheringIcon />
                      )}
                      {val.transaction_type_name ==
                        "sent_gif_in_live_streaming" && <GifBoxIcon />}
                      {val.transaction_type_name == "video_call_request" && (
                        <VideocamIcon />
                      )}
                      {val.transaction_type_name == "recharge_gems" && (
                        <CurrencyRupeeIcon />
                      )}
                    </Box>
                    <Box className="contentbox">
                      <Typography
                        variant="body1"
                        id="myprofie-form-categaries"
                        style={{ lineHeight: "1.5rem" }}
                      >
                        {findTransactionType(val)}
                      </Typography>
                      <Typography
                        variant="body2"
                        id="myprofie-form-categaries"
                        className="date"
                      >
                        {val.purchased_media_description}
                      </Typography>
                      <Typography
                        variant="body2"
                        id="myprofie-form-categaries"
                        className="date"
                      >
                        {dateTimeString}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography variant="body1" id="myprofie-form-categaries">
                      {val.transaction_gems_string} Gems
                    </Typography>
                  </Box>
                </Box>
              </>
            );
          })}
        </InfiniteScroll>
      </Box>
    </>
  );
}

export default Walletpage;
