import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import "../../component/UploadVideo/Uploadvideo.scss";
import jems from "../../component/Images/jems.png";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import axios from "axios";
import Spinners from "../../components/Spinner/Spinners";
import { useLocation, useNavigate } from "react-router-dom";

function UpdateVideo() {
  const location = useLocation();
  console.log(location?.state?.videoobject);
  const [videoDescription, setVideoDescription] = useState({
    thumbUrl: "",
    title: "",
    desc: "",
    noOfGems: "",
    isPortrait: false,
    vdoUrl: "",
  });
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [file, setFile] = useState({ vdoUrl: "", thumbUrl: "" });
  const fileUpload = useRef();
  const videoUpload = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (location?.state?.videoobject) {
      let videoobj = location?.state?.videoobject;
      console.log(videoobj);
      setVideoDescription({
        vdoUrl: videoobj?.thumbUrl,
        title: videoobj.title,
        desc: videoobj.desc,
        noOfGems: videoobj.noOfGems,
        thumbUrl: videoobj?.thumbUrl,
        isPortrait: videoobj?.isPortrait,
      });
    }
  }, []);

  const handleUpdate = () => {
    if (videoDescription.title.trim() == "") {
      setText(process.env.REACT_APP_MODAL_TEXT_ENTER_TITLE);
      setOpen(true);
    } else if (videoDescription.desc.trim() == "") {
      setText(process.env.REACT_APP_MODAL_TEXT_ENTER_DISCRIPTION);
      setOpen(true);
    } else if (videoDescription.noOfGems == null) {
      setText(process.env.REACT_APP_MODAL_TEXT_ENTER_GEMS);
      setOpen(true);
    } else {
      setIsLoading(true);
      let video = { ...location?.state?.videoobject };
      delete video.commentCounter;
      delete video.id;
      delete video.myLiked;
      delete video.likedCounter;

      updateDoc(
        doc(
          db,
          process.env.REACT_APP_TEST_COLLECTION_NAME_VIDEO,
          location?.state?.videoobject?.id
        ),
        {
          ...video,
          title: videoDescription.title,
          desc: videoDescription.desc,
          noOfGems: parseInt(videoDescription.noOfGems),
          isPortrait: videoDescription.isPortrait,
          createdAt: new Date(
            location?.state?.videoobject?.createdAt.seconds * 1000 +
              location?.state?.videoobject?.createdAt.nanoseconds / 1000000
          ),
        }
      ).then(() => {
        setIsLoading(false);
        setOpen(true);
        setText("Video Details are updated successfully!");
        setVideoDescription({
          ...location?.state?.videoobject,
          title: videoDescription.title,
          desc: videoDescription.desc,
          noOfGems: videoDescription.noOfGems,
          isPortrait: videoDescription.isPortrait,
        });
      });
    }
  };

  const close = () => {
    setOpen(false);
  };

  const preview = (e) => {
    setFile({ ...file, thumbUrl: e.target.files[0] });
    setVideoDescription({
      ...videoDescription,
      thumbUrl: URL.createObjectURL(e.target.files[0]),
    });
  };
  const videopreview = (e) => {
    setFile({ ...file, vdoUrl: e.target.files[0] });
    setVideoDescription({
      ...videoDescription,
      vdoUrl: URL.createObjectURL(e.target.files[0]),
    });
  };

  let arr = [
    {
      img: false,
      url: "https://encrypted-vtbn0.gstatic.com/video?q=tbn:ANd9GcS0WwcoS0cHitzAMTzrYfm-S5JhCHfFYJ8EFw",
    },
  ];
  return (
    <>
      <Box className="uvmain">
        <Box>
          <Box>
            <Typography variant="h6" id="uvdiv">
              Update Video Details
            </Typography>
          </Box>
          <Box id="line" />
          <Box className="vformdiv">
            <Typography variant="h6" id="vformheadig">
              Video Thumbnail
            </Typography>
            <Box className="vformdiv">
              <Box className="vformimgdiv">
                {videoDescription.thumbUrl !== "" ? (
                  <Box className="boxvideo">
                    <img src={videoDescription.thumbUrl} className="img" />
                  </Box>
                ) : (
                  <Typography variant="body1" id="vformimgheadig">
                    {
                      process.env
                        .REACT_APP_UPLOAD_VIDEO_PAGE_NO_THUMBNAIL_SELECTED_HEADING
                    }
                  </Typography>
                )}
              </Box>
            </Box>
            <Typography variant="h6" id="vformheadig">
              Video
            </Typography>

            <Box className="vformimgdiv">
              {videoDescription.vdoUrl !== "" ? (
                <Box className="boxvideo">
                  <img
                    src={videoDescription.thumbUrl}
                    className="img"
                    onClick={() =>
                      navigate("/videos", {
                        state: { val: location?.state?.videoobject },
                      })
                    }
                  />
                </Box>
              ) : (
                <Typography variant="body1" id="vformimgheadig">
                  {
                    process.env
                      .REACT_APP_UPLOAD_VIDEO_PAGE_NO_VIDEO_SELECTED_HEADING
                  }
                </Typography>
              )}
            </Box>

            <Box>
              <Grid container className="vbox" columnSpacing={{ xs: 1 }}>
                <Grid item xs={4}>
                  <Typography variant="body1" id="vformtext">
                    {process.env.REACT_APP_TITLE_TEXT}
                  </Typography>
                </Grid>
                <Grid item xs={8} className="uvinput">
                  <TextField
                    type="text"
                    placeholder={
                      process.env.REACT_APP_PLACEHOLDER_TEXT_ENTER_TITLE
                    }
                    onChange={(e) =>
                      setVideoDescription({
                        ...videoDescription,
                        title: e.target.value,
                      })
                    }
                    value={videoDescription.title}
                    variant="outlined"
                    focused={false}
                    id="vtextfild"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container className="vbox" columnSpacing={{ xs: 1 }}>
                <Grid item xs={4}>
                  <Typography variant="body2" id="vformtext">
                    {process.env.REACT_APP_DISCRIPTION_TEXT}
                  </Typography>
                </Grid>
                <Grid item xs={8} className="uvinput2">
                  <TextField
                    type="text"
                    placeholder={
                      process.env.REACT_APP_PLACEHOLDER_TEXT_ENTER_DISCRIPTION
                    }
                    variant="outlined"
                    focused={false}
                    id="vtextfild"
                    value={videoDescription.desc}
                    onChange={(e) =>
                      setVideoDescription({
                        ...videoDescription,
                        desc: e.target.value,
                      })
                    }
                    fullWidth
                    multiline
                    rows={3}
                  />
                </Grid>
              </Grid>
              <Grid container className="vbox" columnSpacing={{ xs: 1 }}>
                <Grid item xs={4}>
                  <img alt="" src={jems} className="vformimg" />
                </Grid>
                <Grid item xs={8} className="uvinput">
                  <TextField
                    type="number"
                    placeholder={
                      process.env.REACT_APP_PLACEHOLDER_TEXT_ENTER_GEMS
                    }
                    variant="outlined"
                    onChange={(e) =>
                      setVideoDescription({
                        ...videoDescription,
                        noOfGems: e.target.value,
                      })
                    }
                    value={videoDescription.noOfGems}
                    focused={false}
                    id="vtextfild"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container className="vbox" columnSpacing={{ xs: 1 }}>
                <Grid item xs={4}></Grid>
                <Grid item xs={8}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={videoDescription.isPortrait}
                        onChange={(e) =>
                          setVideoDescription({
                            ...videoDescription,
                            isPortrait: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Video is in Portrait Mode"
                    id="chek"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                className="box"
                columnSpacing={{ xs: 1 }}
                style={{ marginLeft: 0, paddingInline: 0 }}
              >
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    id="publishbtn"
                    onClick={() => handleUpdate()}
                    style={{ width: "100%" }}
                  >
                    Update & Publish
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    style={{ width: "calc(100% - 15px)" }}
                    variant="contained"
                    id="publishbtn"
                  >
                    Promote Photo
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
      <ModalComponent
        close={close}
        open={open}
        text={text}
        title={process.env.REACT_APP_DIALOG_APP_NAME}
      />
    </>
  );
}

export default UpdateVideo;
