import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./UserDM.scss";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import diamands from "../../assests/diamands.png";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
// import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
  serverTimestamp,
  startAfter,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import InfiniteScroll from "react-infinite-scroll-component";
import Gifs from "../../components/gifs/Gifs";
import { GifsData, DisplayImage } from "../../configs/gifAssests";
import jems from "../../component/Images/jems.png";
import axios from "axios";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { RestrictedWords } from "./RestrictedWords";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import Spinners from "../../components/Spinner/Spinners";
import { useNavigate } from "react-router-dom";

function UserDM() {
  const navigate = useNavigate();
  const [lastDoc, setLastDoc] = useState(null);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [gif, setGif] = useState(false);
  const [openAbussiveRestrictor, setOpenAbussiveRestrictor] = useState({
    open: false,
    text: "",
  });
  const [open, setOpen] = useState({ open: false });
  const [inputMessage, setInPutMessage] = useState("");
  const [sentMessage, setSendMessage] = useState({ open: false, val: "" });
  const [wallet, setWallet] = useState();
  const [file, setFile] = useState();
  const fileUpload = useRef();

  const [userLastMessageDoc, setUserLastMessageDoc] = useState();
  const [ModalDeviceToken, setModalDeviceToken] = useState("");
  const [textFieldCount, setTextFieldCount] = useState(0);
  const [inSufficientWallet, setInsufficientWallet] = useState({
    open: false,
    val: "",
  });
  const [openImage, setOpenImage] = useState({ open: false, val: "" });

  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);

  const [isLatestMessageVisible, setIsLatestMessageVisible] = useState(true);
  const lastMessageRef = useRef(null);
  let lastScrollTop = 0;
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const chatContainer = document.getElementById("dapage");
  //     if (chatContainer) {
  //       const isScrolledToBottom =
  //         chatContainer.scrollHeight - chatContainer.scrollTop ===
  //         chatContainer.clientHeight;

  //       const isScrollingUp = lastScrollTop > chatContainer.scrollTop;

  //       if (!isScrolledToBottom && isScrollingUp) {
  //         setIsScrolledToBottom(false);
  //         setIsLatestMessageVisible(false);
  //       } else {
  //         setIsScrolledToBottom(true);
  //         setIsLatestMessageVisible(true);
  //       }

  //       // Update lastScrollTop for the next iteration
  //       lastScrollTop = chatContainer.scrollTop;
  //     }
  //   };

  //   const chatContainer = document.getElementById("dapage");
  //   if (chatContainer) {
  //     chatContainer.addEventListener("scroll", handleScroll);

  //     return () => {
  //       chatContainer.removeEventListener("scroll", handleScroll);
  //     };
  //   }
  // }, [messages, lastMessageRef]);

  // const handleScroll = () => {
  //   const chatContainer = document.getElementById("dapage");
  //   if (chatContainer) {
  //     const isScrolledDown = chatContainer.scrollTop > 0;

  //     setIsLatestMessageVisible(isScrolledDown);
  //     setIsScrolledToBottom(
  //       chatContainer.scrollHeight - chatContainer.scrollTop ===
  //         chatContainer.clientHeight
  //     );
  //   }
  // };

  // useEffect(() => {
  //   const chatContainer = document.getElementById("dapage");
  //   if (chatContainer) {
  //     chatContainer.addEventListener("scroll", handleScroll);

  //     return () => {
  //       chatContainer.removeEventListener("scroll", handleScroll);
  //     };
  //   }
  // }, [messages, lastMessageRef]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const chatContainer = document.getElementById("dapage");
  //     if (chatContainer) {
  //       const isScrolledDown = chatContainer.scrollTop > 0;
  //       setIsLatestMessageVisible(isScrolledDown);

  //       const isScrolledToBottom =
  //         chatContainer.scrollHeight - chatContainer.scrollTop <=
  //         chatContainer.clientHeight;

  //       setIsScrolledToBottom(isScrolledToBottom);
  //     }
  //   };

  //   const chatContainer = document.getElementById("dapage");
  //   if (chatContainer) {
  //     chatContainer.addEventListener("scroll", handleScroll);

  //     // Check if initially at the bottom
  //     const isInitiallyAtBottom =
  //       chatContainer.scrollHeight - chatContainer.scrollTop <=
  //       chatContainer.clientHeight;
  //     setIsScrolledToBottom(isInitiallyAtBottom);

  //     return () => {
  //       chatContainer.removeEventListener("scroll", handleScroll);
  //     };
  //   }
  // }, [messages, lastMessageRef, setIsScrolledToBottom]);

  // After Infinite scroll

  // {
  //   !isLatestMessageVisible && (
  //     <Box
  //       style={{
  //         marginBottom: "1em",
  //         position: "fixed",
  //         marginLeft: "1em",
  //         backgroundColor: "var(--pitch)",
  //         borderRadius: "50%",
  //         height: "3em",
  //         width: "3em",
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //       onClick={scrollToLatestMessage}
  //     >
  //       <KeyboardDoubleArrowDownIcon style={{ fontSize: "2em" }} />
  //     </Box>
  //   );
  // }
  useEffect(() => {
    getData();
    getFcmTokenOfModal();
    window.scrollTo({ behavior: "smooth" });
    const q = query(
      collection(db, process.env.REACT_APP_WALLET_COLLECTION_NAME),
      where(
        "uid",
        "==",
        JSON.parse(
          localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE)
        ).idUser
      )
    );
    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setWallet(doc.data());
        localStorage.setItem(
          process.env.REACT_APP_WALLET_LOCALSTORAGE,
          JSON.stringify(doc.data())
        );
      });
    });
  }, []);
  const getFcmTokenOfModal = () => {
    let q1 = query(
      collection(db, process.env.REACT_APP_MODAL_DEVICE_FCM_TOKEN_COLLECTION),
      orderBy("fcmTokenTime", "desc"),
      limit(1)
    );
    getDocs(q1).then((result) => {
      result.forEach((doc) => {
        setModalDeviceToken(doc.data().deviceToken);
      });
    });
  };

  const getData = (lastDoc = null) => {
    setLoading(true);
    onSnapshot(
      doc(db, process.env.REACT_APP_NEW_CHATS_COLLECTION, `${user.idUser}`),
      (doc) => {
        setUserLastMessageDoc(doc.data());

        let q4;
        if (lastDoc) {
          q4 = query(
            collection(
              db,
              process.env.REACT_APP_NEW_CHATS_COLLECTION,
              `${user.idUser}`,
              process.env.REACT_APP_MESSAGES_COLLECTION
            ),
            orderBy("sentAt", "desc"),
            startAfter(lastDoc),
            limit(15)
          );
        } else {
          q4 = query(
            collection(
              db,
              process.env.REACT_APP_NEW_CHATS_COLLECTION,
              `${user.idUser}`,
              process.env.REACT_APP_MESSAGES_COLLECTION
            ),
            orderBy("sentAt", "desc"),
            limit(15)
          );
        }

        onSnapshot(q4, (querySnapshot) => {
          let chats = [];
          setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
          querySnapshot.forEach((doc) => {
            chats.push(doc.data());
          });
          setMessages([...messages, ...chats]);
          setLoading(false);
        });
      }
    );
  };
  const handleClose = (ele) => {
    if (ele.noOfGems !== 0) {
      if (wallet?.balance >= ele.noOfGems) {
        setSendMessage({ open: true, val: ele });
      } else {
        setInsufficientWallet({ open: true, val: ele });
      }
    } else {
      setSendMessage({ open: false, val: ele });
    }
    setOpen({ open: false });
  };
  const handleSendMessagesClose = () => {
    setSendMessage({ open: false });
  };
  const handleMessages = () => {
    if (wallet.balance < wallet.noOfGemsperChat) {
      console.log(wallet.balance, "GOING IN ");
      console.log(wallet.noOfGemsperChat, "GOING IN ");
      setInsufficientWallet({ open: true, val: wallet?.noOfGemsperChat });
    } else {
      let flag = false;
      if (inputMessage.trim() !== "" && inputMessage.trim() !== ".jpg") {
        RestrictedWords.map((ele) => {
          if (inputMessage.toLowerCase().includes(ele.toLowerCase())) {
            flag = true;
            setOpenAbussiveRestrictor({
              open: true,
              text: "You cannot use Bad Language",
            });
          }
        });
        if (wallet?.noOfGemsperChat <= wallet?.balance && !flag) {
          setSendMessage({ open: true, val: "chat" });
        }
      }
    }
  };
  const handleSendMessages = (val) => {
    if (wallet.balance > val.noOfGemsperChat || wallet.balance > 0) {
      const q2 = query(
        collection(db, process.env.REACT_APP_USERS_COLLECTION_NAME),
        where("idUser", "==", wallet?.uid)
      );

      getDocs(q2).then((result) => {
        console.log(result, "REsult");
        result.forEach((users) => {
          if (users.data()?.blocked) {
            console.log("TRUE");
            // navigate("/login");
            navigate("/login");
            localStorage.clear();
          } else {
            const isChat = val == "chat";
            const isImage = val == "image";
            if (isImage) {
              console.log("first");
              setLoading(true);
              setSendMessage({ open: false, val: sentMessage.val });
            }
            if (
              (!isChat &&
                typeof val === "object" &&
                val !== null &&
                val.name != "" &&
                sentMessage !== null) ||
              (inputMessage.trim() !== "" && inputMessage.trim() !== ".jpg") ||
              isImage
            ) {
              console.log("Fitststtst");
              let DMPurchased = {
                transaction_type_id:
                  process.env.REACT_APP_DM_TRANSACTION_TYPE_ID,
                transaction_type_name:
                  process.env.REACT_APP_DM_TRANSACTION_TYPE_NAME,
              };
              let obj = {
                chatMessage: isChat
                  ? inputMessage.replaceAll(".gif", "")
                  : isImage
                  ? ""
                  : `${val.name}`,
                isRead: false,
                noOfGems:
                  isChat || isImage ? wallet?.noOfGemsperChat : val.noOfGems,
                platform: process.env.REACT_APP_PLATFORM,
                sentAt: Timestamp.fromDate(new Date()),
                sentBy: process.env.REACT_APP_DM_FROM_SENDER,
                version: process.env.REACT_APP_VERSION,
                "model-identifier": process.env.REACT_APP_MODEL_IDENTIFIER,
              };
              console.log(obj, "OBJ");
              let updatedWallet = {
                ...wallet,
                balance:
                  wallet?.balance -
                  (isChat || isImage ? wallet?.noOfGemsperChat : val.noOfGems),
              };
              let updateLastMessageOfUser = {
                dpUrl: user.urlAvatar,
                fcmDeviceToken: ModalDeviceToken,
                lastChatMessage: obj.chatMessage,
                lastMessageFromUser: true,
                chatAlreadyDone: true,
                lastMessageTime: new Date(),
                "model-identifier": process.env.REACT_APP_MODEL_IDENTIFIER,
                name: user.name,
                totalUnreadCount: userLastMessageDoc
                  ? userLastMessageDoc.totalUnreadCount + 1
                  : 1,
              };
              let userTransactionnsTable = {
                dm_message_gif: isChat || isImage ? "" : obj.chatMessage,
                dm_message_image: isImage ? obj.chatMessage : "",
                dm_message_text: isChat ? obj.chatMessage : "",
                live_session_purchase_gif_name: "",
                live_session_ref_id: "",
                platform: process.env.REACT_APP_PLATFORM,
                purchased_media_description: "",
                purchased_media_title: "",
                purchased_media_url: "",
                purchased_product_description: "",
                purchased_product_title: "",
                recharge_gems_transaction_amount: "",
                recharge_gems_transaction_id: "",
                transaction_gems: obj.noOfGems,
                transaction_gems_string: `-${obj.noOfGems}`,
                transaction_ref_id: "",
                transaction_time: serverTimestamp(),
                transaction_type_id: DMPurchased.transaction_type_id,
                transaction_type_name: DMPurchased.transaction_type_name,
                updated_balance_after_transaction: `${updatedWallet.balance}`,
                user_email: user.email,
                user_id: user.idUser,
                user_name: user.name,
                version: process.env.REACT_APP_VERSION,
                "model-identifier": process.env.REACT_APP_MODEL_IDENTIFIER,
              };
              localStorage.setItem(
                process.env.REACT_APP_WALLET_LOCALSTORAGE,
                JSON.stringify(updatedWallet)
              );
              setWallet(updatedWallet);
              const querySnapshot = doc(
                db,
                process.env.REACT_APP_WALLET_COLLECTION_NAME,
                user.idUser
              );
              updateDoc(querySnapshot, {
                balance: updatedWallet.balance,
              }).then(() => {});

              if (file == null) {
                setInPutMessage("");
                let newMessage = [...messages];
                newMessage.unshift(obj);
                setMessages(newMessage);
                setSendMessage({ open: false, val: "" });
                addDoc(
                  collection(
                    db,
                    process.env.REACT_APP_NEW_CHATS_COLLECTION,
                    user.idUser,
                    "messages"
                  ),
                  {
                    ...obj,
                    sentAt: serverTimestamp(),
                  }
                )
                  .then((result) => {
                    userTransactionnsTable = {
                      ...userTransactionnsTable,
                      transaction_ref_id: result.id,
                    };
                  })
                  .then((result) => {
                    addDoc(
                      collection(
                        db,
                        process.env.REACT_APP_USERTRANSACTIONS_COLLECTION
                      ),
                      userTransactionnsTable
                    ).then(() => {});
                  });
                const updateLastChat = doc(
                  db,
                  process.env.REACT_APP_NEW_CHATS_COLLECTION,
                  user.idUser
                );
                updateDoc(updateLastChat, updateLastMessageOfUser).then(() => {
                  setSendMessage({ open: false, val: "" });
                  setLoading(false);
                });
                // DEMO
                addDoc(
                  collection(
                    db,
                    process.env.REACT_APP_NEW_CHATS_COLLECTION,
                    user.idUser
                  ),
                  updateLastMessageOfUser
                );

                updateDoc(
                  doc(
                    db,
                    process.env.REACT_APP_NEW_CHATS_COLLECTION,
                    user?.idUser
                  ),
                  updateLastMessageOfUser
                );
              } else {
                console.log("ELSEEEE");
                const formData = new FormData();
                formData.append("file", file);
                let axiosConfig = {
                  headers: {
                    "X-Auth-Email": process.env.REACT_APP_X_AUTH_EMAIL,
                    "X-Auth-Key": process.env.REACT_APP_X_AUTH_KEY,
                    Authorization: process.env.REACT_APP_AUTHORIZATION,
                    "Content-Type": process.env.REACT_APP_CONTENT_TYPE,
                    "Access-Control-Allow-Origin":
                      process.env.REACT_APP_ACCESS_CONTROL_ALLOW_ORIGIN,
                    Accept: process.env.REACT_APP_ACCEPT,
                  },
                };
                console.log(file, "FILESS");
                axios
                  .post(
                    process.env.REACT_APP_IMAGE_UPLOAD_CLOUDFAIR_URL,
                    { file: file },
                    axiosConfig
                  )
                  .then((response) => {
                    fileUpload.current.value = null;
                    console.log(response, "SUCCESS");
                    if (response.data.success) {
                      console.log("data success");
                      let imgID = response.data.result.id;
                      let imagePath = `${process.env.REACT_APP_IMAGE_GET_CLOUDFAIR_URL1}${imgID}${process.env.REACT_APP_IMAGE_GET_CLOUDFAIR_URL2}`;
                      obj = { ...obj, chatMessage: imagePath };
                      setFile(null);
                      let newMessage = [...messages];
                      newMessage.unshift(obj);
                      setMessages(newMessage);
                      updateLastMessageOfUser = {
                        ...updateLastMessageOfUser,
                        lastChatMessage: obj.chatMessage,
                      };
                      setSendMessage({ open: false, val: "" });
                      addDoc(
                        collection(
                          db,
                          process.env.REACT_APP_NEW_CHATS_COLLECTION,
                          user.idUser,
                          process.env.REACT_APP_MESSAGES_COLLECTION
                        ),
                        {
                          ...obj,
                          sentAt: serverTimestamp(),
                        }
                      )
                        .then((result) => {
                          userTransactionnsTable = {
                            ...userTransactionnsTable,
                            transaction_ref_id: result.id,
                            dm_message_image: obj.chatMessage,
                          };
                        })
                        .then((result) => {
                          addDoc(
                            collection(
                              db,
                              process.env.REACT_APP_USERTRANSACTIONS_COLLECTION
                            ),
                            userTransactionnsTable
                          ).then(() => {});
                        });
                      const updateLastChat = doc(
                        db,
                        process.env.REACT_APP_NEW_CHATS_COLLECTION,
                        user.idUser
                      );

                      updateDoc(updateLastChat, updateLastMessageOfUser).then(
                        () => {
                          setSendMessage({ open: false, val: "" });
                          setLoading(false);
                        }
                      );
                    }
                  });
              }
            }
          }
        });
      });
    } else {
      console.log(wallet.balance, "GOING IN ");
      console.log(wallet.noOfGemsperChat, "GOING IN ");
      setInsufficientWallet({ open: true, val: "" });
    }
  };
  const scrollToLatestMessage = () => {
    const chatContainer = document.getElementById("dapage");
    if (chatContainer) {
      chatContainer.scrollTo({
        top: chatContainer.scrollHeight,
        behavior: "smooth",
      });
    }
  };
  const preview = (e) => {
    if (wallet.balance > wallet.noOfGemsperChat) {
      console.log("preview 1");
      setFile(e.target.files[0]);
      setSendMessage({ open: true, val: "image" });
    } else {
      setInsufficientWallet({ open: true, val: "ele" });
    }
  };

  return (
    <Box className="userdmpage" style={{ position: "absolute" }}>
      {loading ? <Spinners /> : null}
      <Box className="dm_head" style={{ position: "fixed", zIndex: "200" }}>
        <Typography variant="body1" className="dm_head_1">
          {wallet?.noOfGemsperChat}&nbsp;
          {process.env.REACT_APP_GEMS_PER_CHAT_HEADING}
        </Typography>
        <Typography variant="body1" className="dm_head_2">
          {process.env.REACT_APP_DM_CHAT_WARNIGN_HEADING}
        </Typography>
      </Box>
      {messages.length === 0 && !loading ? (
        <Typography style={{ top: "50%", left: "40%", position: "fixed" }}>
          No Data
        </Typography>
      ) : (
        <>
          <Box
            className="dm"
            id="dapage"
            style={{
              display: "flex",
              flexDirection: "column-reverse",
              overflow: "auto",
              height: "calc(100% - 245px)",
              position: "fixed",
              bottom: "165px",
              zIndex: "100",
              width: "100vw",
            }}
          >
            <InfiniteScroll
              dataLength={messages.length}
              next={() => {
                getData(lastDoc);
              }}
              style={{ display: "flex", flexDirection: "column-reverse" }}
              inverse={true}
              scrollableTarget="dapage"
              hasMore={true}
              scrollThreshold={"300px"}
              initialScrollY={0}
            >
              {messages.map((val, index) => {
                let isMessageGif = val.chatMessage.includes(".gif");
                let isPhoto = val.chatMessage.includes("https://");
                let gifPhotoSrc;
                if (isMessageGif) {
                  gifPhotoSrc = GifsData.filter(
                    (ele) => ele.name == val.chatMessage
                  );
                  if (gifPhotoSrc.length > 0) {
                    gifPhotoSrc = gifPhotoSrc[0];
                  }
                }
                let dateTimeString;
                if (val.sentAt) {
                  dateTimeString = `${val.sentAt
                    .toDate()
                    .toLocaleTimeString("en-IN", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour12: false,
                    })}`
                    .replaceAll("/", "-")
                    .replaceAll(",", "");
                }
                return (
                  <Box
                    ref={index === messages.length - 1 ? lastMessageRef : null}
                    className={
                      val.sentBy === "sender" ? "sendmsgside" : "recivemsgside"
                    }
                  >
                    <Box
                      className={
                        val.sentBy === "sender"
                          ? "senddm_msgbox"
                          : "recdm_msgbox"
                      }
                    >
                      {!isMessageGif ? (
                        isPhoto ? (
                          <img
                            alt=""
                            style={{ aspectRatio: "1/1.5" }}
                            src={val.chatMessage}
                            onClick={() =>
                              setOpenImage({ open: true, val: val.chatMessage })
                            }
                            id="msgimg"
                          />
                        ) : (
                          <Typography variant="body1">
                            {val.chatMessage}
                          </Typography>
                        )
                      ) : (
                        <div className="gifPhoto">
                          <DisplayImage
                            src={gifPhotoSrc}
                            style={{
                              minWidth: "350px",
                              minHeight: "-webkit-fill-available",
                            }}
                          />
                        </div>
                      )}
                      <Typography
                        variant="body2"
                        style={{ fontSize: "10px", marginTop: "5px" }}
                      >
                        {val.sentAt !== null ? dateTimeString : null}
                      </Typography>
                    </Box>
                    {val.sentBy === "sender" ? (
                      <Typography
                        variant="body2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: "var(--white)",
                          fontFamily: "var(--font)",
                          textShadow: "2px 1px 1px var(--black)",
                        }}
                      >
                        {process.env.REACT_APP_DM_CHAT_SPENT_MESSAGE}{" "}
                        {val.noOfGems} &nbsp;&nbsp;
                        <img
                          alt=""
                          src={diamands}
                          style={{ hight: "30px", width: "30px" }}
                        />
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                );
              })}
            </InfiniteScroll>
          </Box>
        </>
      )}

      <Box
        style={{
          position: "fixed",
          height: "110px",
          bottom: "59px",
          width: "100vw",
          backgroundColor: "var(--pink)",
          zIndex: "1000",
        }}
      >
        <Box
          className="dm_bottom"
          style={{ position: "fixed", bottom: "65px" }}
        >
          <TextField
            focused={false}
            value={inputMessage}
            onChange={(e) => {
              if (e.target.value.length <= 120) {
                setInPutMessage(e.target.value);
                setTextFieldCount(textFieldCount + 1);
              }
            }}
            rows={2}
            multiline
            size="small"
            id="dm_bottm_textfild_userdm"
            placeholder="Type your message"
            inputProps={{ maxLength: 120 }}
            InputProps={{
              endAdornment: (
                <Typography
                  className="text-shadow-typography"
                  style={{
                    width: "-webkit-fill-available",
                    textAlign: "end",
                    paddingRight: "10px",
                    fontSize: "10px",
                  }}
                >
                  {inputMessage.length}/
                  {parseInt(process.env.REACT_APP_DM_CHAT_TEXT_COUNT)}
                </Typography>
              ),
              maxLength: parseInt(process.env.REACT_APP_DM_CHAT_TEXT_COUNT),
            }}
          />
          <Box className="dma">
            <SendIcon
              style={{ color: "var(--white) !important" }}
              onClick={() => {
                setGif(false);
                handleMessages();
              }}
            />
          </Box>
          <Box
            className="dmb"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => fileUpload.current.click()}
          >
            <AttachFileIcon style={{ height: "70%" }} className="dmbimg" />
            <input
              hidden
              accept="image/*"
              ref={fileUpload}
              multiple={false}
              type="file"
              onChange={(e) => preview(e)}
            />
          </Box>
          <Box
            className="dmc"
            onClick={() => {
              setGif(true);
              setOpen({ open: true });
            }}
          >
            <CardGiftcardIcon />
          </Box>
        </Box>
      </Box>
      {open.open && (
        <Gifs
          open={open.open}
          close={(ele) => handleClose(ele)}
          color="black"
        />
      )}
      {sentMessage.open && (
        <Dialog
          open={sentMessage.open}
          onClose={() => handleSendMessagesClose()}
          className="photopopup"
          aria-labelledby="draggable-dialog-title"
        >
          <DialogContent id="context">
            <DialogContentText className="context-div">
              <Typography variant="h6" id="modeltext">
                {process.env.REACT_APP_WALLET_BALANCE_TEXT}:{wallet?.balance}
              </Typography>
              <img alt="" src={jems} className="jemsimg" />
            </DialogContentText>
          </DialogContent>
          <DialogActions className="modelbtndiv">
            <Button
              variant="contained"
              onClick={() => handleSendMessages(sentMessage.val)}
              id="popbtn"
            >
              {process.env.REACT_APP_BUY_FOR_TEXT} &nbsp;
              {sentMessage.val == "chat" || sentMessage.val == "image"
                ? wallet?.noOfGemsperChat
                : sentMessage.val.noOfGems}
              <img alt="" src={jems} className="jemsimg" />
            </Button>
            <Button
              variant="contained"
              onClick={() => handleSendMessagesClose()}
              id="popbtn"
            >
              {process.env.REACT_APP_MODAL_CANCEL_BUTTON_TEXT}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {inSufficientWallet.open && (
        <Dialog
          open={inSufficientWallet.open}
          onClose={() => {
            setGif(false);
            setInsufficientWallet({ open: false, val: "" });
          }}
          className="photopopup"
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle id="modeltext" className="context-div">
            {process.env.REACT_APP_INSUFFICIENT_BALANCE}
          </DialogTitle>
          <Divider style={{ backgroundColor: "var(--white)" }} />

          <DialogContent id="context">
            <DialogContentText className="context-div">
              <Typography variant="h6" id="modeltext">
                {process.env.REACT_APP_WALLET_BALANCE_TEXT}: {wallet?.balance}
              </Typography>
              <img alt="" src={jems} className="jemsimg" />
            </DialogContentText>
          </DialogContent>

          <DialogContent id="context">
            <DialogContentText className="context-div">
              <Typography variant="h6" id="modeltext">
                {process.env.REACT_APP_NEED_GEMS_FOR_TEXT}:{" "}
                {gif
                  ? inSufficientWallet.val.noOfGems - wallet?.balance
                  : wallet.noOfGemsperChat - wallet?.balance}
              </Typography>
              <img alt="" src={jems} className="jemsimg" />
            </DialogContentText>
          </DialogContent>

          <DialogActions className="modelbtndiv">
            <Button
              variant="contained"
              id="popbtn"
              onClick={() =>
                window.open(process.env.REACT_APP_DARKMATTER_PAYMENT_LINK)
              }
            >
              {process.env.REACT_APP_RECHARGE_TEXT}
              <img alt="" src={jems} className="jemsimg" />
            </Button>

            <Button
              variant="contained"
              onClick={() => setInsufficientWallet({ open: false, val: "" })}
              id="popbtn"
            >
              {process.env.REACT_APP_MODAL_CANCEL_BUTTON_TEXT}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {openImage.open && (
        <Dialog fullScreen open={openImage.open} className="spop">
          <Box>
            <Box className="op">
              <Box className="mainop">
                <Box
                  className="backicon"
                  style={{ position: "absolute", top: "2%", zIndex: "2" }}
                  onClick={() => {
                    setOpenImage({ open: false, val: "" });
                  }}
                >
                  <ArrowBackIcon />
                </Box>
                <Box className="opBox">
                  <TransformWrapper
                    defaultScale={1}
                    defaultPositionX={100}
                    defaultPositionY={200}
                  >
                    <TransformComponent
                      contentStyle={{ width: "100vw", height: "100vh" }}
                    >
                      <img alt="" src={openImage.val} className="opimg" />
                    </TransformComponent>
                  </TransformWrapper>
                </Box>
              </Box>
            </Box>
          </Box>
        </Dialog>
      )}
      <ModalComponent
        open={openAbussiveRestrictor.open}
        close={() => setOpenAbussiveRestrictor({ open: false, text: "" })}
        text={openAbussiveRestrictor.text}
        title={process.env.REACT_APP_DIALOG_APP_NAME}
      />
    </Box>
  );
}
export default UserDM;
