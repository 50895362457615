import React, { useEffect, useState } from "react";
import "./Dmpage.scss";
import profile from "../Images/profile.jpg";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import InfiniteScroll from "react-infinite-scroll-component";
import { db } from "../../firebaseConfig/firebaseConfig";
import { useNavigate } from "react-router-dom";
import Spinner2 from "../../components/Spinner/Spinner2";
function Dmpage() {
  const navigate = useNavigate();
  const [lastDoc, setLastDoc] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState();

  const getData = async (lastDoc = null) => {
    try {
      setLoading(true);
      let q4;
      if (lastDoc) {
        q4 = query(
          collection(db, process.env.REACT_APP_NEW_CHATS_COLLECTION),
          // where("lastChatMessage", "!=", process.env.REACT_APP_INITIAL_MESSAGE),
          // orderBy("lastChatMessage"),
          orderBy("lastMessageTime", "desc"),
          startAfter(lastDoc),
          limit(15)
        );
      } else {
        q4 = query(
          collection(db, process.env.REACT_APP_NEW_CHATS_COLLECTION),
          // where("lastChatMessage", "!=", process.env.REACT_APP_INITIAL_MESSAGE),
          // orderBy("lastChatMessage"),
          orderBy("lastMessageTime", "desc"),
          limit(15)
        );
      }

      onSnapshot(q4, (querySnapshot) => {
        let chats = [];
        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
        querySnapshot.forEach((doc) => {
          if (
            doc.data().lastChatMessage !== process.env.REACT_APP_INITIAL_MESSAGE
          ) {
            const lastChatMessage = doc.data().lastChatMessage;
            if (lastChatMessage !== process.env.REACT_APP_INITIAL_MESSAGE) {
              chats.push({ ...doc.data(), userId: doc.id });
            }
          }
        });
        setMessages([...messages, ...chats]);
        setLoading(false);
      });
      // setLoading(false);
    } catch (error) {
      console.log(error.messages, "ERROOORR");
    }
  };

  useEffect(() => {
    getData();
    window.scrollTo({ behavior: "smooth" });
  }, []);

  return (
    <>
      {/* <Spinner2 /> */}
      <Box
        className="commentdiv"
        style={{
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        {loading && <Spinner2 />}
        <InfiniteScroll
          dataLength={messages.length}
          next={() => {
            getData(lastDoc);
          }}
          style={{ display: "flex", flexDirection: "column" }}
          scrollableTarget="dapage"
          hasMore={true}
          scrollThreshold={"300px"}
          initialScrollY={0}
        >
          {messages.length === 0 && !loading ? (
            <Typography style={{ top: "50%", left: "40%", position: "fixed" }}>
              No Data
            </Typography>
          ) : (
            messages.map((val) => {
              let isGif = val.lastChatMessage.includes(".gif");
              let isImage = val.lastChatMessage.includes("https://");
              return (
                <>
                  <Box
                    className="comment"
                    onClick={() =>
                      navigate("/back/modalsideuserchats", { state: { val } })
                    }
                  >
                    {val.dpUrl ? (
                      <img alt="" src={val.dpUrl} className="imgprofile" />
                    ) : (
                      <img alt="" src={profile} className="imgprofile" />
                    )}
                    <Box id="cmnt">
                      <Typography variant="h6" id="user">
                        {val.name}
                      </Typography>
                      <Typography variant="body1" id="msgs">
                        {isGif
                          ? process.env.REACT_APP_ANIMATED_GIF_TEXT
                          : isImage
                          ? process.env.REACT_APP_IMAGE_TEXT
                          : val.lastChatMessage}
                      </Typography>
                    </Box>
                    {val.totalUnreadCount !== 0 ? (
                      <Box className="cmcount">
                        <Typography
                          variant="body2"
                          style={{ fontSize: "12px" }}
                        >
                          {val.totalUnreadCount > 99
                            ? "99+"
                            : val.totalUnreadCount}
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                  <Box className="cmline"></Box>
                </>
              );
            })
          )}
        </InfiniteScroll>
        <Box className="add">
          <AddIcon
            onClick={() =>
              navigate("/back/serchuser", { state: { val: "dmpage" } })
            }
          />
        </Box>
      </Box>
    </>
  );
}

export default Dmpage;
