import { Box, Dialog, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ModelProfilePicture from "../../assests/modelprofilepic.jpeg";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import Spinners from "../../components/Spinner/Spinners";
import Spinner2 from "../../components/Spinner/Spinner2";

function MyPurchasePhoto() {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [myPurchedPhotos, setMyPurchedPhotos] = useState([]);
  const [open, setOpen] = useState({ open: false, val: "" });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo({ behavior: "smooth" });
    let result1 = [];
    const q1 = query(
      collection(db, process.env.REACT_APP_MYPURCHASEDPHOTOS_COLLECTION_NAME),
      where("uid", "==", user.idUser),
      orderBy("boughtOn", "desc")
    );

    getDocs(q1).then((result) => {
      result.forEach((doc) => {
        result1.push(doc.data());
        setMyPurchedPhotos(result1);
      });

      setIsLoading(false);
    });
  }, []);

  return (
    <>
      {isLoading ? <Spinner2 /> : null}
      {myPurchedPhotos.length == 0 && !isLoading && (
        <Typography
          style={{
            position: "fixed",
            top: "50%",
            width: "100vw",
            textAlign: "center",
            left: 0,
          }}
        >
          No records found
        </Typography>
      )}
      {myPurchedPhotos.map((ele) => {
        return (
          <Box
            className="photopurch"
            style={{ marginTop: "68px", position: "relative", zIndex: "1" }}
          >
            <Grid container className="photodiv">
              <Grid item className="photodiv_imgdiv">
                <div className="profilepic">
                  <img alt="" src={ModelProfilePicture} className="photoimg" />
                </div>
              </Grid>
              <Grid item style={{ width: "calc(100% - 65px)" }}>
                <Typography
                  variant="body1"
                  className="text-align-start"
                  id="photousername"
                >
                  {process.env.REACT_APP_MODAL_NAME}
                </Typography>
                <Typography
                  variant="body2"
                  className="text-align-start"
                  id="photocaption"
                >
                  {ele.photodesc}
                </Typography>
              </Grid>
            </Grid>
            <Box className="postimgdiv" style={{ marginBottom: "18px" }}>
              <Box
                className="postdiv"
                onClick={() => setOpen({ open: true, val: ele })}
              >
                <img alt="" src={ele.photoUrl} className="postimg" />
              </Box>
            </Box>
            <Box className="photoendline" />
          </Box>
        );
      })}
      {open.open && (
        <Dialog fullScreen open={open.open} className="spop">
          <Box>
            <Box className="op">
              <Box className="mainop">
                <Box
                  className="backicon"
                  style={{ position: "absolute", top: "2%", zIndex: "2" }}
                  onClick={() => setOpen(false)}
                >
                  <ArrowBackIcon />
                </Box>
                <Box className="opBox">
                  <TransformWrapper
                    defaultScale={1}
                    defaultPositionX={100}
                    defaultPositionY={200}
                  >
                    <TransformComponent
                      contentStyle={{ width: "100vw", height: "100vh" }}
                    >
                      <img alt="" src={open.val.photoUrl} className="opimg" />
                    </TransformComponent>
                  </TransformWrapper>
                </Box>
              </Box>
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  );
}

export default MyPurchasePhoto;
