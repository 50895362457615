import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import supercrown1 from "../../assests/super_crown_1.png";
import supercrown2 from "../../assests/super_crown_2.png";
import supercrown3 from "../../assests/super_crown_3.png";
import placeholder_image from "../../assests/placeholder_image.jpg";
import diamands from "../../assests/diamands.png";
import "./SuperFan.scss";
import {
  collection,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import InfiniteScroll from "react-infinite-scroll-component";
import CopyMailPhoneNumberModal from "./CopyMailPhoneNumberModal";
import { toast } from "react-toastify";
import Spinners from "../../components/Spinner/Spinners";

function AllTime() {
  const [data, setData] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [model, setModel] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_MODEL))
  );
  const [userDetailsModal, setUserDetailsModal] = useState({
    open: false,
    val: "",
  });
  let isModal = JSON.parse(
    localStorage.getItem(process.env.REACT_APP_ISMODAL_FLAG)
  );

  useEffect(() => {
    window.scrollTo({ behavior: "smooth" });
    getAllTimeSuperFans();
  }, []);
  const notify = (val) => toast(val);
  const handleClose = () => {
    setUserDetailsModal(true);
  };

  const getAllTimeSuperFans = () => {
    setIsLoading(true);

    let q1;
    if (lastDoc) {
      q1 = query(
        collection(db, process.env.REACT_APP_WALLET_COLLECTION_NAME),
        orderBy("totalPurchasedGems", "desc"),
        startAfter(lastDoc),
        limit(15)
      );
      setIsLoading(false);
    } else {
      q1 = query(
        collection(db, process.env.REACT_APP_WALLET_COLLECTION_NAME),
        orderBy("totalPurchasedGems", "desc"),
        limit(15)
      );
    }

    onSnapshot(q1, (querySnapshot) => {
      const allTimeFans = [];
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      querySnapshot.forEach((element) => {
        allTimeFans.push(element.data());
      });
      if (lastDoc) {
        setData([...data, ...allTimeFans]);
        setIsLoading(false);
      } else {
        let temp = allTimeFans[0];
        allTimeFans[0] = allTimeFans[1];
        allTimeFans[1] = temp;
        setData([...data, ...allTimeFans]);
        setIsLoading(false);
      }
    });
  };

  return (
    <Box className="month">
      {isLoading ? <Spinners /> : null}

      <Box className="win">
        {data &&
          model &&
          data.map((val, index) => {
            return (
              <>
                {index === 0 && val?.totalPurchasedGems > 0 && (
                  <Box
                    className="no2"
                    onClick={() => {
                      setUserDetailsModal({ open: true, val: val });
                    }}
                  >
                    <img alt="" src={supercrown2} className="crown2" />
                    <Box className="placeimgbox2">
                      <img alt="" src={val?.dp} className="placeimg2" />
                    </Box>
                    <Typography
                      variant="body1"
                      className="typography-loginpage "
                      style={{
                        lineHeight: "1.5rem",
                        marginTop: "10px",
                        fontSize: "12px",
                      }}
                    >
                      {val?.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      className="je typography-loginpage "
                    >
                      {val?.totalPurchasedGems}
                      <img
                        alt=""
                        src={diamands}
                        style={{ height: "20px", marginLeft: "5px" }}
                      />
                    </Typography>
                  </Box>
                )}
                {index === 1 && val?.totalPurchasedGems > 0 && (
                  <Box
                    className="no1"
                    onClick={() => {
                      setUserDetailsModal({ open: true, val: val });
                    }}
                  >
                    <img alt="" src={supercrown1} className="crown1" />
                    <Box className="placeimgbox1">
                      <img alt="" src={val?.dp} className="placeimg1" />
                    </Box>
                    <Typography
                      variant="body1"
                      className="typography-loginpage "
                      style={{
                        lineHeight: "1.5rem",
                        marginTop: "10px",
                        fontSize: "13px",
                      }}
                    >
                      {val?.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      className="je typography-loginpage "
                    >
                      {val?.totalPurchasedGems}
                      <img
                        alt=""
                        src={diamands}
                        style={{ height: "20px", marginLeft: "5px" }}
                      />
                    </Typography>
                  </Box>
                )}
                {index === 2 && val?.totalPurchasedGems > 0 && (
                  <Box
                    className="no3"
                    onClick={() => {
                      setUserDetailsModal({ open: true, val: val });
                    }}
                  >
                    <img alt="" src={supercrown3} className="crown3" />
                    <Box className="placeimgbox3">
                      <img alt="" src={val?.dp} className="placeimg3" />
                    </Box>
                    <Typography
                      variant="body1"
                      className="typography-loginpage "
                      style={{
                        lineHeight: "1.5rem",
                        marginTop: "10px",
                        fontSize: "12px",
                      }}
                    >
                      {val?.name}{" "}
                    </Typography>
                    <Typography
                      variant="body2"
                      className="je typography-loginpage "
                    >
                      {val?.totalPurchasedGems}
                      <img
                        alt=""
                        src={diamands}
                        style={{ height: "20px", marginLeft: "5px" }}
                      />
                    </Typography>
                  </Box>
                )}
              </>
            );
          })}
      </Box>
      <Box id="list" style={{ width: "94vw" }}>
        <InfiniteScroll
          dataLength={data.length}
          next={() => {
            getAllTimeSuperFans(lastDoc);
          }}
          style={{ overflow: "inherit" }}
          // scrollableTarget="list"
          hasMore={true}
          scrollThreshold={"300px"}
          initialScrollY={0}
        >
          {model &&
            data.map((val, index) => {
              return (
                <>
                  {index >= 3 && index < 100 && val.totalPurchasedGems > 0 && (
                    <Box
                      className="list_box"
                      onClick={() => {
                        setUserDetailsModal({ open: true, val: val });
                      }}
                    >
                      <Typography
                        variant="body2"
                        className="je typography-loginpage "
                        sx={{ width: "20px" }}
                      >
                        {index + 1}
                      </Typography>
                      <div className="superfan-profile-pic">
                        <img
                          alt=""
                          src={val?.dp}
                          className="listimg input-field-shadow"
                        />
                      </div>
                      <Typography
                        variant="body1"
                        className="typography-loginpage "
                        style={{
                          lineHeight: "1rem",
                          fontSize: "13px",
                          width: "70%",
                          textAlign: "left",
                        }}
                      >
                        {val?.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        className="je typography-loginpage "
                        sx={{ textAlign: "right", marginLeft: "10px" }}
                      >
                        {val?.totalPurchasedGems}
                        <img
                          alt=""
                          src={diamands}
                          style={{ height: "20px", marginLeft: "5px" }}
                        />
                      </Typography>
                    </Box>
                  )}
                </>
              );
            })}

          {!model && (
            <Typography style={{ position: "fixed", top: "50%", left: "35%" }}>
              No records found
            </Typography>
          )}
        </InfiniteScroll>
      </Box>
      {userDetailsModal.open && isModal && (
        <CopyMailPhoneNumberModal
          close={() => handleClose()}
          val={userDetailsModal.val}
          toast={(val) => notify(val)}
        />
      )}
    </Box>
  );
}

export default AllTime;
