import React, { useEffect, useState } from "react";
import "./ShoutOutpage.scss";
import { Box, Button, IconButton, Typography } from "@mui/material";
import shoutoutvdo from "../../assests/shoutoutvdo.mp4";
import diamands from "../../assests/diamands.png";
import shoutout from "../../assests/shoutout image.png";
import message_icon from "../../assests/message_icon.png";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { Link, useLocation } from "react-router-dom";

function ShoutOutpage() {
  const location = useLocation();
  const [muted, setMuted] = useState(true);
  const [model, setModel] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_MODEL))
  );
  useEffect(() => {
    if (location) {
      setMuted(location.state.muted);
    }
  }, []);

  return (
    <Box className="shoutdiv">
      <Box>
        {model === process.env.REACT_APP_MODEL_IDENTIFIER && (
          <video
            src={shoutoutvdo}
            autoplay="true"
            loop
            playsInline={true}
            muted={muted}
            style={{ maxWidth: "100vw" }}
          ></video>
        )}

        <Box className="volumebox">
          <IconButton onClick={() => setMuted(!muted)}>
            {!muted ? <VolumeUpIcon /> : <VolumeOffIcon />}
          </IconButton>
        </Box>
      </Box>
      <Box className="shoutoutmaindiv">
        <Typography variant="h6" className="shoutoutheading">
          {process.env.REACT_APP_SHOUTOUT_HEADING}
        </Typography>
      </Box>
      <Box className="line" />
      <Box className="shoutoutcontent">
        <Link to="/mainback/shoutoutmsg" style={{ textDecoration: "none" }}>
          <Button variant="contained" className="shoutoutbtn" fullWidth>
            {process.env.REACT_APP_REQUEST_VIDEO_MESSAGE_NOW_HEADING}
            <img
              alt=""
              src={diamands}
              style={{ height: "15px", marginLeft: "3px" }}
            />
          </Button>
        </Link>
        <Typography variant="h6" className="shouttext">
          {process.env.REACT_APP_PROCESS_HEADING}
        </Typography>
        <img
          alt=""
          src={shoutout}
          style={{ width: "100%", margin: "10px 0" }}
        />
        <Link
          to="/mainback/shoutoutmymessages"
          style={{ textDecoration: "none" }}
        >
          <Button variant="contained" className="shoutoutbtna">
            <img alt="" src={message_icon} style={{ height: "30px" }} />
            <Box className="prline" />
            {process.env.REACT_APP_MY_MESSAGES_HEADING}
          </Button>
        </Link>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            alt=""
            src={message_icon}
            style={{ height: "30px", marginRight: "10px" }}
          />

          <Typography variant="h6" className="shouttext">
            {process.env.REACT_APP_FANS_SHARED_SHOUTOUT_HEADING}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default ShoutOutpage;
