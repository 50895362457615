import React, { useEffect, useRef, useState } from "react";
import "../UploadBanners/UploadBanner.scss";
import "../../App.scss";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  addDoc,
  collection,
  getDocs,
  deleteDoc,
  doc,
  query,
  orderBy,
  serverTimestamp,
} from "@firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import Spinners from "../../components/Spinner/Spinners";
import axios from "axios";

function UploadStory() {
  const [allbanners, setAllBanners] = useState([]);
  const [deletePopUp, setDeletePopUp] = useState({
    open: false,
    val: "",
    index: null,
  });
  const [file, setFile] = useState(null);
  const fileUpload = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [count, setCount] = useState(0);
  const [photoDescription, setPhotoDescription] = useState({
    storyType: "",
    storyPicUrl: "",
    storyUploadTime: "",
    storyVideoUrl: "",
  });

  useEffect(() => {
    getBanners();
  }, [count]);

  const handleUpload = () => {
    if (file == null) {
      setOpen(true);
      setText(process.env.REACT_APP_PLEASE_SELECT_STORY_TEXT);
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      let axiosConfig = {
        headers: {
          "X-Auth-Email": process.env.REACT_APP_X_AUTH_EMAIL,
          "X-Auth-Key": process.env.REACT_APP_X_AUTH_KEY,
          Authorization: process.env.REACT_APP_AUTHORIZATION,
          "Content-Type": process.env.REACT_APP_CONTENT_TYPE,
          "Access-Control-Allow-Origin":
            process.env.REACT_APP_ACCESS_CONTROL_ALLOW_ORIGIN,
          Accept: process.env.REACT_APP_ACCEPT,
        },
      };
      axios
        .post(
          process.env.REACT_APP_IMAGE_UPLOAD_CLOUDFAIR_URL,
          { file: file },
          axiosConfig
        )
        .then((response) => {
          if (response.data.success) {
            let imgID = response.data.result.id;
            let imagePath = `${process.env.REACT_APP_IMAGE_GET_CLOUDFAIR_URL1}${imgID}${process.env.REACT_APP_IMAGE_GET_CLOUDFAIR_URL2}`;
            addDoc(
              collection(db, process.env.REACT_APP_STORY_COLLECTION_NAME),
              {
                ...photoDescription,
                storyType: "image",
                storyPicUrl: imagePath,
                storyUploadTime: serverTimestamp(),
                storyVideoUrl: "",
                "model-identifier": process.env.REACT_APP_MODEL_IDENTIFIER,
              }
            ).then(() => {
              setIsLoading(false);
              setFile(null);
              setCount(count + 1);
              setOpen(true);
              setText(process.env.REACT_APP_STORY_ADDED_SUCESSFULLY_MODAL_TEXT);
            });
          }
        });
    }
  };

  const getBanners = () => {
    let bannersArr = [];
    let bannerQuery = query(
      collection(db, process.env.REACT_APP_STORY_COLLECTION_NAME),
      orderBy("storyUploadTime", "desc")
    );
    getDocs(bannerQuery).then((banners) => {
      banners.forEach((banner) => {
        bannersArr.push({ ...banner.data(), docId: banner.id });
      });
      setAllBanners(bannersArr);
    });
  };

  const deletebanner = (index, val) => {
    deleteDoc(
      doc(db, process.env.REACT_APP_UPLOAD_STORY_COLLECTION_NAME, val.docId)
    ).then(() => {
      setText(process.env.REACT_APP_STORY_DELETED_SUCESSFULLY_MODAL_TEXT);
      let newArr = [...allbanners];
      let a = newArr.splice(index, 1);
      setAllBanners(newArr);
      setCount(count + 1);
      setFile(null);
      setDeletePopUp({ open: false, index: null, val: "" });
    });
  };

  const preview = (e) => {
    setFile(e.target.files[0]);
    setPhotoDescription({
      ...photoDescription,
      storyPicUrl: URL.createObjectURL(e.target.files[0]),
    });
  };
  const close = () => {
    setPhotoDescription({
      storyType: "",
      storyPicUrl: "",
      storyUploadTime: "",
      storyVideoUrl: "",
    });
    setFile(null);
    setOpen(false);
  };

  return (
    <Box className="m_upbanner">
      {isLoading ? <Spinners /> : null}

      <Box className="m_upbanner_header">
        <Typography
          variant="h6"
          className="typography-loginpage m_upbanner_head text-shadow-typography"
        >
          {process.env.REACT_APP_UPLOAD_STORY_TITLE}
        </Typography>
      </Box>
      <Box className="line" />
      <Box className="cc">
        <Button
          variant="contained"
          className="upbanner_btn typography-loginpage button-text-transform"
          onClick={() => fileUpload.current.click()}
        >
          {process.env.REACT_APP_GALLARY_BUTTON_TEXT}
          <input
            hidden
            accept="image/*"
            ref={fileUpload}
            multiple
            type="file"
            onChange={(e) => preview(e)}
          />
        </Button>{" "}
        {photoDescription?.storyPicUrl !== "" ? (
          <img alt="" src={photoDescription?.storyPicUrl} className="img" />
        ) : (
          <Typography
            variant="body2"
            className="typography-loginpage no_banner text-shadow-typography"
          >
            {process.env.REACT_APP_NO_STORY_SELECTED_TITLE}
          </Typography>
        )}
        <Box
          style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "center",
            width: "90%",
          }}
        >
          <Button
            variant="contained"
            className="upbanner_btn typography-loginpage button-text-transform"
            onClick={() => handleUpload()}
          >
            {process.env.REACT_APP_UPLOAD_STORY_TITLE}
          </Button>
        </Box>
      </Box>
      <Box className="line" />
      <Typography variant="body2" className="typography-loginpage c_head">
        {process.env.REACT_APP_ALL_STORYS_TITLE}
      </Typography>

      <Box className="m_upbanner_contant ">
        <Box className="banner_box">
          {allbanners.length === 0 ? (
            <Typography style={{ top: "50%", left: "40%", position: "fixed" }}>
              No Data
            </Typography>
          ) : (
            allbanners.map((val, index) => {
              return (
                <>
                  <Box className="banner_img_box">
                    <img alt="" src={val?.storyPicUrl} className="banner_img" />
                    <DeleteIcon
                      className="upbanner_delete"
                      onClick={() =>
                        setDeletePopUp({ index: index, val: val, open: true })
                      }
                    />
                  </Box>
                </>
              );
            })
          )}
        </Box>
      </Box>
      <ModalComponent
        close={close}
        open={open}
        text={text}
        title={process.env.REACT_APP_DIALOG_APP_NAME}
      />
      {deletePopUp.open && (
        <Dialog
          open={deletePopUp.open}
          aria-labelledby="responsive-dialog-title "
          className="loguotpop"
          style={{ backgroundColor: "var(--pinkDark)" }}
        >
          <DialogTitle className="dialog-title-background typography-loginpage">
            {process.env.REACT_APP_DIALOG_APP_NAME}
          </DialogTitle>

          <DialogContent>
            <DialogContentText className="typography-loginpage">
              {process.env.REACT_APP_DELETE_STORY_MODAL_TEXT}
            </DialogContentText>
            <Button
              className="typography-loginpage loginpage-button"
              onClick={() => deletebanner(deletePopUp.index, deletePopUp.val)}
            >
              {process.env.REACT_APP_YES_BUTTON1}
            </Button>
            <Button
              className="typography-loginpage loginpage-button"
              onClick={() =>
                setDeletePopUp({ open: false, index: null, val: "" })
              }
            >
              {process.env.REACT_APP_NO_BUTTON}
            </Button>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
}

export default UploadStory;
