import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Model_shoutout.scss";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import Spinners from "../../components/Spinner/Spinners";

function Model_shoutout_Completed() {
  const [shoutoutCompleted, setShoutoutCompleted] = useState([]);
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    getCompletedShotout();
  }, []);

  const getCompletedShotout = async () => {
    try {
      setIsLoading(true);
      let q1 = query(
        collection(
          db,
          process.env.REACT_APP_COMPLETED_SHOUTOUT_REQUEST_COLLECTION
        ),
        orderBy("requestedOn", "desc")
      );
      getDocs(q1).then((completedShoutOut) => {
        completedShoutOut.forEach((shoutout) => {
          let allShoutOuts = [];
          shoutout.forEach((shoutout) => {
            allShoutOuts.push(shoutout.data());
          });
          setIsLoading(false);
          setShoutoutCompleted(allShoutOuts);
        });
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error, "ERROR");
    }
  };

  return (
    <Box
      sx={{
        padding: "10px 0px 10px 0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "fit-content",
      }}
      className="box-complete"
    >
      {isLoading ? <Spinners /> : null}
      {shoutoutCompleted.length === 0 ? (
        <Typography
          style={{
            position: "fixed",
            top: "50%",
            width: "100vw",
            textAlign: "center",
            left: 0,
          }}
        >
          No records found
        </Typography>
      ) : (
        // shoutoutCompleted &&
        shoutoutCompleted.map((val) => {
          let dateTimeString;
          if (val?.completedOn) {
            dateTimeString = `${val?.completedOn
              .toDate()
              .toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })} ${new Date(val.completedOn.toDate()).toLocaleDateString(
              "en-US",
              { year: "numeric", month: "long", day: "numeric" }
            )}`;
          }
          const dateTimeString2 = `${val.requestedOn
            .toDate()
            .toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })} ${new Date(val.requestedOn.toDate()).toLocaleDateString(
            "en-US",
            { year: "numeric", month: "long", day: "numeric" }
          )}`;

          return (
            <Box className="contant_box m_model_sh_cop_box1">
              <Typography variant="body1" className="typography-loginpage">
                {process.env.REACT_APP_REQUESTED_BY_TEXT} {val.requestedBy}
              </Typography>
              <Typography variant="body1" className="typography-loginpage">
                {process.env.REACT_APP_COMPLETED_ON_TEXT} {dateTimeString}
              </Typography>
              <Typography variant="body1" className="typography-loginpage">
                {process.env.REACT_APP_REQUESTED_ON_TEXT} {dateTimeString2}
              </Typography>
              <Typography variant="body1" className="typography-loginpage">
                {process.env.REACT_APP_MESSAGE_TEXT}:
              </Typography>
              <Typography
                variant="body2"
                className="typography-loginpage"
                style={{ margin: "10px 0px" }}
              >
                {val.message}
              </Typography>
            </Box>
          );
        })
      )}
    </Box>
  );
}

export default Model_shoutout_Completed;
