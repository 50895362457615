import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import giftme from "../../assests/giftme.mp4";
import "./GiftMe.scss";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import Fullpopup from "../Settingpage/Fullpopup";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import {
  addDoc,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";

function GiftMe() {
  const [muted, setMuted] = useState(false);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [modalFcmToken, setModalFcmToken] = useState("");
  const [openSuccessDialog, setOpenSucessDialog] = useState(false);
  useEffect(() => {
    getFcmTokenOfModal();
  }, []);

  const getFcmTokenOfModal = () => {
    let q1 = query(
      collection(db, process.env.REACT_APP_MODAL_DEVICE_FCM_TOKEN_COLLECTION),
      orderBy("fcmTokenTime", "desc"),
      limit(1)
    );
    getDocs(q1).then((result) => {
      result.forEach((doc) => {
        setModalFcmToken(doc.data().deviceToken);
      });
    });
  };
  const closeable = () => {
    setModalOpen(false);
  };

  const close = () => {
    setOpen(false);
  };

  const handleSendRequest = () => {
    if (user.name.trim() == "") {
      setText(process.env.REACT_APP_PLEASE_ENTER_NAME_TEXT);
      setOpen(true);
    } else if (user.email.trim() == "") {
      setText(process.env.REACT_APP_ENTER_EMAIL_MODAL_TEXT);
      setOpen(true);
    } else if (
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(user.email) ==
      false
    ) {
      setText(process.env.REACT_APP_INVALID_EMAIL_MODAL_TEXT);
      setOpen(true);
    } else if (user.mobile.trim() == "") {
      setText(process.env.REACT_APP_MOBILE_VALIDATION_TEXT_MODAL);
      setOpen(true);
    } else if (user.mobile.length < 10 || user.mobile.length > 15) {
      setText(
        process.env.REACT_APP_MODILE_NUMBER_VALIDATION_TEXT_FOR_MOBILE_NUMBER
      );
      setOpen(true);
    } else {
      const giftMeObj = {
        createdAt: serverTimestamp(),
        email: user.email,
        giftRequestStatus: process.env.REACT_APP_GIFTME_INITIAL_STATUS,
        modelFcmToken: modalFcmToken,
        name: user.name,
        phone: user.mobile,
        uid: user.idUser,
        platform: process.env.REACT_APP_PLATFORM,
        version: process.env.REACT_APP_VERSION,
        "model-identifier": process.env.REACT_APP_MODEL_IDENTIFIER,
      };
      addDoc(
        collection(db, process.env.REACT_APP_MYGIFTS_COLLECTION),
        giftMeObj
      ).then(() => {});
      setText(process.env.REACT_APP_GIFTME_RECEIVE_CALL_TEXT);
      setOpenSucessDialog(true);
    }
  };

  return (
    <Box className="giftmaindiv">
      <Box>
        <video
          src={giftme}
          autoPlay
          muted={muted}
          loop
          playsInline={true}
          style={{ maxWidth: "100vw", objectFit: "cover" }}
        ></video>
        <Box className="volumebox">
          <IconButton id="btnMute" onClick={() => setMuted(!muted)}>
            {!muted ? <VolumeUpIcon /> : <VolumeOffIcon />}
          </IconButton>
        </Box>
      </Box>
      <Box className="giftmehead">
        <Typography variant="h6" className="giftmeheading">
          {process.env.REACT_APP_GIFTME_HEADING}
        </Typography>
        <Box className="line" />
        <Typography variant="body1" className="giftmeheadingtext">
          {process.env.REACT_APP_USER_GIFT_TO_MODAL_HEADING}
        </Typography>
      </Box>
      <Box className="giftmetextfield">
        <TextField
          type="text"
          placeholder={process.env.REACT_APP_NAME_PLACEHOLDER}
          variant="outlined"
          focused={false}
          onChange={(e) => setUser({ ...user, name: e.target.value })}
          value={user.name}
          id="pink-background-field"
          fullWidth
          size="small"
        />
        <TextField
          type="text"
          placeholder={process.env.REACT_APP_MY_PROFILE_EMAIL_TEXTFIELD}
          variant="outlined"
          value={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
          focused={false}
          id="pink-background-field"
          fullWidth
          size="small"
        />
        <TextField
          type="tel"
          placeholder={process.env.REACT_APP_MOBILE_NUMBER_PLACEHOLDER}
          variant="outlined"
          focused={false}
          value={user.mobile}
          onChange={(e) => setUser({ ...user, mobile: e.target.value })}
          id="pink-background-field"
          fullWidth
          size="small"
        />
        <Typography variant="body1" className="giftmeheadingtext2">
          {process.env.REACT_APP_GIFT_BUTTON_TAPING_HEADING}
        </Typography>
        <Button
          variant="text"
          className="giftmebtn2"
          onClick={() => setModalOpen(true)}
        >
          <span className="link-color">
            {process.env.REACT_APP_TERMS_AND_CONDITION_LINK_TEXT}
          </span>
        </Button>
        <Button
          variant="contained"
          className="giftmebtn text-shadow-typography"
          fullWidth
          onClick={() => handleSendRequest()}
        >
          {process.env.REACT_APP_SEND_REQUEST_BUTTON}
        </Button>
      </Box>
      {modalOpen && (
        <Fullpopup
          close={() => closeable()}
          open={modalOpen}
          text={"Terms-Condition"}
        />
      )}
      {open && (
        <ModalComponent
          close={close}
          open={open}
          text={text}
          title={process.env.REACT_APP_DIALOG_APP_NAME}
        />
      )}
      {openSuccessDialog && (
        <Dialog
          open={openSuccessDialog}
          aria-labelledby="responsive-dialog-title"
          className="popup"
        >
          <DialogTitle className="dialog-title-background typography-loginpage">
            {process.env.REACT_APP_THANK_YOU_MODAL_TITLE}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              className="typography-loginpage"
              style={{ marginBottom: "20px" }}
            >
              {text}
            </DialogContentText>
            <Button
              className="typography-loginpage"
              onClick={() => setOpenSucessDialog(false)}
            >
              {process.env.REACT_APP_MODAL_OKAY_BUTTON}
            </Button>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
}

export default GiftMe;
