import React, { useEffect, useRef, useState } from "react";
import { TabPanel } from "./Tabelpanel";
import "../../App.scss";
import "./Login.scss";
import BackGround from "../../assests/ic_login.jpeg";
import Logo from "../../assests/logo copy.png";
import { toast, ToastContainer } from "react-toastify";

import { useNavigate } from "react-router";
import ModalComponent from "../ModalComponent/ModalComponent";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { authErrors } from "./errors";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { authentication, db } from "../../firebaseConfig/firebaseConfig";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  where,
} from "@firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import "react-phone-input-2/lib/style.css";
import Country1 from "./Countycode";
import { FlagIcon } from "react-flag-kit";
import Spinners from "../Spinner/Spinners";

const Login = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  let timeOut = null;
  const [tabChange, setTabChange] = useState();
  const [search, setSearch] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("+91");
  const [value, setValue] = useState(
    // Retrieve the saved tab value from localStorage or set a default value
    parseInt(localStorage.getItem("selectedTab")) || 0
  );
  // const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [goData, setGoData] = useState();
  const [text, setText] = useState("");
  const [error, setError] = useState(null);
  const [count, setCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [confirmResultHook, setConfirmResultHook] = useState("");
  const [appVerifier, setAppVerifier] = useState(null);
  // const [user] = useAuthState(auth);
  const [editDetails, setEditDetails] = useState(0);
  const [informationText, setInformationText] = useState();
  const [modelUid, setModelUid] = useState(false);

  const [mobileData, setMobileData] = useState({
    mobile_number: "",
    OTP: "",
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [alreadyLoginuser, setAlreadyLoginUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );

  const [modal, setModal] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_ISMODAL_FLAG))
  );
  const [modelTrue, setModelTrue] = useState();

  useEffect(() => {
    localStorage.setItem("selectedTab", value.toString());
  }, [value]);

  useEffect(() => {
    const isModalOpen = localStorage.getItem("tabChanged");
    if (isModalOpen) {
      setOpen(true);
      setText(
        `Due to technical reasons, we are unable to send OTP from a web browser. To continue using the app\n\n1) You can use the Android Mobile app to continue login using your Mobile Number.\n\n2) We encourage you to migrate your account to Email authentication. Kindly send a request to migrate the account on WhatsApp at +91-9004940445. All your purchases will be migrated .\n\nWe are sorry for the inconvenience.
              `
      );
    }
  }, []);
  useEffect(() => {
    // console.log(alreadyLoginuser, "ALREADY LOGGED IN ");
    // debuuger
    localStorage.clear();
    // Commented DATA
    // if (alreadyLoginuser) {
    //   console.log(goData, "GoDATA");
    //   if (!alreadyLoginuser.blocked) {
    //     navigate("/userhomepage/home");
    //   }
    // } else if (modal) {
    //   const modaldeatails = doc(
    //     db,
    //     process.env.REACT_APP_FEATURES_ENABLED,
    //     "allowModelLogin"
    //   );
    //   getDoc(modaldeatails).then((result) => {
    //     if (
    //       result.data().web &&
    //       result.data["model-identifier"] ===
    //         process.env.REACT_APP_MODEL_IDENTIFIER
    //     ) {
    //       localStorage.setItem("isModal", true);
    //       navigate("/a/Home");
    //     }
    //   });
    // } else {
    //   // if (!window.recaptchaVerifier) {
    //   //   window.recaptchaVerifier = new RecaptchaVerifier(
    //   //     "recaptcha-container",
    //   //     {
    //   //       size: "invisible",
    //   //       callback: (response) => {},
    //   //       "expired-callback": () => {},
    //   //     },
    //   //     authentication
    //   //   );
    //   // }
    // }
  }, [editDetails]);

  useEffect(() => {
    const q = collection(
      db,
      process.env.REACT_APP_FEATURES_ENABLED,
      "modelUser",
      "modelData"
    );
    getDocs(q).then((result) => {
      result.forEach((doc) => {
        setModelUid(doc.data().model_id);
      });
    });
  }, []);

  useEffect(() => {
    if (count > 0) {
      const intervalId = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [count]);
  function stopCountdown() {
    clearTimeout(timeOut);
  }
  const notify = () => {
    setLoading(false);
    toast("Your account has been blocked");
  };

  useEffect(() => {
    return () => {
      if (appVerifier) {
        appVerifier.clear();
      }
    };
  }, [appVerifier]);
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const verifyFields = () => {
    setError(false);

    setConfirmResultHook("");
    setError(null);
    if (mobileData.mobile_number.trim() == "" && !blocked) {
      setText(process.env.REACT_APP_MOBILE_VALIDATION_TEXT_MODAL);
      setOpen(true);
    } else if (
      (mobileData.mobile_number.trim().length < 8 ||
        mobileData.mobile_number.trim().length > 15) &&
      !blocked
    ) {
      setText(
        process.env.REACT_APP_MODILE_NUMBER_VALIDATION_TEXT_FOR_MOBILE_NUMBER
      );
      setOpen(true);
    } else {
      const phoneNumber = `${selectedCountry.trim()}${mobileData.mobile_number.trim()}`;
      // const appVerifier = window.recaptchaVerifier;/
      if (appVerifier) {
        appVerifier.clear();
      }
      let tempAppVerifier = null;

      if (!appVerifier) {
        tempAppVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: (response) => {},
            "expired-callback": () => {},
          },
          authentication
        );
      } else {
        tempAppVerifier = appVerifier;
      }
      setCount(60);
      setCounter(counter + 1);
      signInWithPhoneNumber(authentication, phoneNumber, tempAppVerifier)
        .then((confirmationResult) => {
          if (!appVerifier) {
            setAppVerifier(tempAppVerifier);
          }
          setConfirmResultHook(confirmationResult);
        })
        .catch((error) => {
          const errArr = error.code.split("/");
          console.log(authErrors[`${errArr[errArr.length - 1]}`], "Auth error");
          setValue(1);
          stopCountdown();
          console.log("spec_chcek_ecrr", error);
          setError(true);
          setCount(0);
          setCounter(0);
          setOpen(true);

          setText(error.message);
        });
    }

    // }
  };
  const proceed = () => {
    if (mobileData.OTP.trim() == "") {
      setOpen(true);
      setText(process.env.REACT_APP_OTP_CODE_MODAL_TEXT);
    } else if (mobileData.OTP.trim().length !== 6) {
      setOpen(true);
      setText(process.env.REACT_APP_INVALID_OTP_CODE_MODAL_TEXT);
    } else if (count > 0) {
      setLoading(true);
      confirmResultHook
        .confirm(mobileData.OTP)
        .then(async (result) => {
          setLoading(true);
          const user = result.user;
          const userInfo = user.reloadUserInfo.providerUserInfo[0].providerId;

          const q1 = doc(
            db,
            process.env.REACT_APP_USERS_COLLECTION_NAME,
            `${user.uid}`
          );
          const querySnapshot1 = await getDoc(q1);

          localStorage.setItem(
            process.env.REACT_APP_USERS_LOCALSTORAGE,
            JSON.stringify(querySnapshot1.data())
          );
          if (querySnapshot1.data() && querySnapshot1.data()?.blocked) {
            setLoading(false);

            notify();
            setMobileData({ ...mobileData, OTP: "" });
            return;
          }
          if (!querySnapshot1.data()?.blocked && querySnapshot1.data()) {
            if (querySnapshot1.data()?.transfer) {
              setOpen(true);
              setText(
                "Your account transffered.Please login with email and password"
              );
              console.log("Transferred Error block");
              localStorage.clear();
              stopCountdown();
              setCount(0);
              setCounter(0);
              setLoading(false);
              setError(true);
              // setTimeout(() => {
              //   window.location.reload();
              // }, 3000);
              setValue(1);
            } else {
              console.log(user.uid, "USERUIDD");
              fetchWalletDataAndStoreLocal(
                db,
                process.env.REACT_APP_WALLET_COLLECTION_NAME,
                user.uid,
                process.env.REACT_APP_WALLET_LOCALSTORAGE
              );
              await fetchDataAndStoreLocal(
                process.env.REACT_APP_MYPURCHASEDPHOTOS_COLLECTION_NAME,
                process.env.REACT_APP_MYPURCHASEDPHOTOS_COLLECTION_NAME,
                user,
                process.env.REACT_APP_MODEL_IDENTIFIER
              );

              await fetchDataAndStoreLocal(
                process.env.REACT_APP_PURCHASEDVIDEOS_COLLECTION_NAME,
                process.env.REACT_APP_PURCHASEDVIDEOS_COLLECTION_NAME,
                user,
                process.env.REACT_APP_MODEL_IDENTIFIER
              );
              const deviceToken = {
                deviceToken: JSON.parse(
                  localStorage.getItem(
                    process.env.REACT_APP_DEVICETOKEN_LOCALSTORAGE
                  )
                ),
                fcmTokenTime: serverTimestamp(),
                localAppVersionOfTheUser: "",
                platform: process.env.REACT_APP_PLATFORM,
                uid: user.uid,
                "model-identifier": process.env.REACT_APP_MODEL_IDENTIFIER,
              };
              await addDeviceTokenAndStoreLocal(
                db,
                process.env.REACT_APP_DEVICETOKEN_COLLECTION,
                deviceToken,
                process.env.REACT_APP_PROMATEIMAGE_LOCALSTORAGE
              );

              {
                !error && navigate("/userhomepage/home", { state: userInfo });
              }
              setLoading(false);
            }
          } else {
            setCount(0);
            setCounter(0);
            stopCountdown();
            setLoading(false);
            setOpen(true);
            setError(true);
            setText("This user doesn't exist. Please sign up to the website.");
            console.log("User exsist Block");
            // setTimeout(() => {
            //   window.location.reload();
            // }, 3000);
            setValue(1);
          }
        })
        .catch((error) => {
          console.log("User exsist Block 111");

          setLoading(false);
          setOpen(true);
          stopCountdown();

          setText(process.env.REACT_APP_INVALID_PHONE_CREDENTIAL_MODAL_TEXT);
          setValue(1);
        });
      setLoading(false);
    } else {
      setText(process.env.REACT_APP_TIME_EXCEEDS_MODAL_TEXT);
      setOpen(true);
    }
  };

  const close = () => {
    setOpen(false);
    setValue(0);
    localStorage.removeItem("tabChanged");
  };

  async function fetchData(collectionName, uid, modelIdentifier) {
    const q = query(collection(db, collectionName), where("uid", "==", uid));

    const data = [];
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });

    return data;
  }

  async function fetchWalletDataAndStoreLocal(
    db,
    collectionName,
    userUid,
    localStorageKey
  ) {
    try {
      const docRef = doc(db, collectionName, userUid);
      const querySnapshot = await getDoc(docRef);

      if (querySnapshot.exists()) {
        const data = querySnapshot.data();
        localStorage.setItem(localStorageKey, JSON.stringify(data));
        return data;
      } else {
        console.log("Document not found");
        return null;
      }
    } catch (error) {
      console.error("Error fetching document:", error);
      return null;
    }
  }

  async function fetchDataAndStoreLocal(
    collectionName,
    localKeyName,
    user,
    modelIdentifier
  ) {
    const data = await fetchData(collectionName, user.uid, modelIdentifier);
    localStorage.setItem(localKeyName, JSON.stringify(data));
  }

  async function addDeviceTokenAndStoreLocal(
    db,
    collectionName,
    deviceToken,
    localStorageKey
  ) {
    try {
      const docRef = await addDoc(collection(db, collectionName), deviceToken);
      localStorage.setItem(localStorageKey, true);
      return docRef;
    } catch (error) {
      console.error("Error adding device token:", error);

      return null;
    }
  }

  const verifyEmailFields = () => {
    localStorage.clear();
    if (mobileData.email.trim() == "") {
      setText(process.env.REACT_APP_ENTER_EMAIL_MODAL_TEXT);
      setOpen(true);
    } else if (
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
        mobileData.email
      ) == false
    ) {
      setText(process.env.REACT_APP_INVALID_EMAIL_MODAL_TEXT);
      setOpen(true);
    } else if (mobileData.password.trim() == "") {
      setText(process.env.REACT_APP_ENTER_PASSWORD_MODAL_TEXT);
      setOpen(true);
    } else {
      setLoading(true);
      let flag = process.env.REACT_APP_MODEL_UID;
      signInWithEmailAndPassword(
        auth,
        mobileData.email.trim(),
        mobileData.password.trim()
      )
        .then(async (userCredential) => {
          const user = userCredential.user;
          const userInfo = user.reloadUserInfo.providerUserInfo[0].providerId;

          // setTimeout(async () => {
          setTimeout(async () => {
            let isModel;
            let modelData;
            let count = 0;

            const q = collection(
              db,
              process.env.REACT_APP_FEATURES_ENABLED,
              "modelUser",
              "modelData"
            );
            getDocs(q).then(async (result) => {
              var isModelAccount = false;
              result.forEach((doc) => {
                if (doc.data().model_id == user.uid) {
                  isModelAccount = true;
                  modelData = doc.data();
                }
              });

              if (isModelAccount) {
                //Check identifier and proceed with Model login

                if (
                  modelData["model-identifier"] ==
                  process.env.REACT_APP_MODEL_IDENTIFIER
                ) {
                  //Proceed with Model Login

                  console.log("MODEL");
                  const q4 = query(
                    collection(db, process.env.REACT_APP_FEATURES_ENABLED)
                  );
                  getDocs(q4).then((result) => {
                    result.forEach((doc) => {
                      if (doc.data()?.web) {
                        localStorage.setItem("isModal", true);
                        navigate("/a/Home");
                      } else {
                        setLoading(false);
                        setText("Incorrect user credentials");
                        setOpen(true);
                      }
                    });
                  });
                } else {
                  //Invalid credential alert
                  setText("Invalied Credentials");
                  setOpen(true);
                  setLoading(false);
                }
              } else {
                //User Login

                const q1 = doc(
                  db,
                  process.env.REACT_APP_USERS_COLLECTION_NAME,
                  `${user.uid}`
                );

                const querySnapshot1 = await getDoc(q1);

                localStorage.setItem(
                  "users",
                  JSON.stringify(querySnapshot1.data())
                );

                if (!querySnapshot1.data().blocked) {
                  fetchWalletDataAndStoreLocal(
                    db,
                    process.env.REACT_APP_WALLET_COLLECTION_NAME,
                    user.uid,
                    process.env.REACT_APP_WALLET_LOCALSTORAGE
                  );
                  await fetchDataAndStoreLocal(
                    process.env.REACT_APP_MYPURCHASEDPHOTOS_COLLECTION_NAME,
                    process.env.REACT_APP_MYPURCHASEDPHOTOS_COLLECTION_NAME,
                    user,
                    process.env.REACT_APP_MODEL_IDENTIFIER
                  );

                  await fetchDataAndStoreLocal(
                    process.env.REACT_APP_PURCHASEDVIDEOS_COLLECTION_NAME,
                    process.env.REACT_APP_PURCHASEDVIDEOS_COLLECTION_NAME,
                    user,
                    process.env.REACT_APP_MODEL_IDENTIFIER
                  );
                  const deviceToken = {
                    deviceToken: JSON.parse(
                      localStorage.getItem(
                        process.env.REACT_APP_DEVICETOKEN_LOCALSTORAGE
                      )
                    ),
                    fcmTokenTime: serverTimestamp(),
                    localAppVersionOfTheUser: process.env.REACT_APP_VERSION,
                    platform: process.env.REACT_APP_PLATFORM,
                    uid: user.uid,
                    "model-identifier": process.env.REACT_APP_MODEL_IDENTIFIER,
                  };
                  await addDeviceTokenAndStoreLocal(
                    db,
                    process.env.REACT_APP_DEVICETOKEN_COLLECTION,
                    deviceToken,
                    process.env.REACT_APP_PROMATEIMAGE_LOCALSTORAGE
                  );
                  navigate("/userhomepage/home", { state: userInfo });
                  setLoading(false);
                } else {
                  notify();
                  setMobileData({ ...mobileData, email: "", password: "" });
                }
              }
            });
          }, 1000);
        })
        .catch((err) => {
          setLoading(false);
          const errArr = err.code.split("/");
          setText(authErrors[`${errArr[errArr.length - 1]}`]);
          setOpen(true);
        });
    }
  };

  return (
    <Box className="general">
      <ToastContainer
        toastStyle={{ backgroundColor: "var(--black)", color: "white" }}
        hideProgressBar="true"
        autoClose="2000"
      />
      {loading ? <Spinners /> : ""}
      <Box
        className="login-container"
        style={{
          backgroundImage: `url(${BackGround})`,
        }}
      >
        <img src={Logo} />
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            className="typography-loginpage"
            label={process.env.REACT_APP_EMAIL_BUTTON}
            {...a11yProps(0)}
          />
          <Tab
            className="typography-loginpage"
            label={process.env.REACT_APP_MOBILE_BUTTON}
            // {...a11yProps(1)}
            style={{ border: "2px solid var(--pink)", color: "white" }}
            // {...a11yProps(1)}
            onClick={() => {
              setOpen(true);
              setText(
                `Due to technical reasons, we are unable to send OTP from a web browser. To continue using the app\n\n1) You can use the Android Mobile app to continue login using your Mobile Number.\n\n2) We encourage you to migrate your account to Email authentication. Kindly send a request to migrate the account on WhatsApp at +91-9004940445. All your purchases will be migrated .\n\nWe are sorry for the inconvenience.
              `
              );
              localStorage.setItem("tabChanged", true);
            }}
          />
        </Tabs>
        {/* <TabPanel value={value} index={1} style={{ width: "90%" }}>
          <Box sx={{ display: "flex" }}>
            <FormControl className="selectfield">
              <Select
                labelId="demo-simple-select-label"
                renderValue={(selectedCountry) => selectedCountry}
                id="demo-simple-select"
                className="selectcode"
                value={selectedCountry}
                MenuProps={{ autoFocus: false }}
                onChange={(e) => {
                  setSelectedCountry(e.target.value);
                }}
              >
                <Box sx={{ padding: "0px 14px" }}>
                  <Typography variant="h6">
                    {process.env.REACT_APP_SELECT_HEADING}
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    label="Search..."
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                  ></TextField>
                </Box>
                {Country1.filter((val) => {
                  if (search === "") {
                    return val;
                  } else if (
                    val.name.toLowerCase().includes(search.toLowerCase()) ||
                    val.code.toLowerCase().includes(search.toLowerCase()) ||
                    val.cca2.toLowerCase().includes(search.toLowerCase())
                  ) {
                    return val;
                  }
                }).map((ev) => {
                  return (
                    <MenuItem value={ev.code} className="menucountry">
                      <FlagIcon code={ev.flag} />
                      <span id="countryname">
                        {ev.name}
                        &nbsp;({ev.cca2})
                      </span>
                      <span className="ccode">{ev.code}</span>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <TextField
              type="tel"
              placeholder={
                process.env.REACT_APP_ENTER_MOBILE_NUMBER_PLACEHOLDER
              }
              variant="outlined"
              value={mobileData.mobile_number}
              onChange={(e) =>
                setMobileData({ ...mobileData, mobile_number: e.target.value })
              }
              style={{ marginBottom: "15px", width: "80%", marginLeft: "5px" }}
              focused={false}
              inputProps={{ maxLength: 15 }}
            />
          </Box>

          {count !== 0 && !error && (
            <Typography
              className="text-align-center"
              style={{ marginBottom: "15px" }}
            >
              Resend OTP in {count}
            </Typography>
          )}

          {counter > 0 && (
            <TextField
              placeholder="Enter OTP"
              type="number"
              variant="outlined"
              value={mobileData.OTP}
              onChange={(e) =>
                setMobileData({ ...mobileData, OTP: e.target.value })
              }
              style={{ marginBottom: "15px", width: "100%" }}
              focused={false}
              inputProps={{ maxLength: 6 }}
            />
          )}
          {(count === 0 || counter === 0) && (
            <Button
              variant="contained"
              className="loginpage-button typography-loginpage"
              color="primary"
              style={{
                marginBottom: "25px",
                width: "100%",
                textTransform: "none",
                fontSize: "20px",
              }}
              onClick={() => {
                setEditDetails(editDetails + 1);
                verifyFields();
              }}
            >
              {process.env.REACT_APP_SEND_OTP_BUTTON}
            </Button>
          )}

          {count > 0 && !error && (
            <Button
              variant="contained"
              className="loginpage-button typography-loginpage"
              color="primary"
              style={{
                marginBottom: "25px",
                width: "100%",
                textTransform: "none",
                fontSize: "20px",
              }}
              onClick={() => {
                if (count === 0) {
                  setEditDetails(editDetails + 1);
                  verifyFields();
                } else {
                  proceed();
                }
              }}
            >
              {count === 0
                ? "Resend OTP"
                : process.env.REACT_APP_PROCEED_BUTTON}
            </Button>
          )}
        </TabPanel> */}
        <TabPanel value={value} index={0} style={{ width: "90%" }}>
          <TextField
            placeholder={process.env.REACT_APP_ENTER_EMAIL_ID_PLACEHOLDER}
            variant="outlined"
            type="email"
            focused={false}
            value={mobileData.email}
            onChange={(e) =>
              setMobileData({ ...mobileData, email: e.target.value })
            }
          />
          <FormControl
            variant="outlined"
            fullWidth
            style={{ marginBottom: "10px" }}
          >
            <OutlinedInput
              autoComplete="no"
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              className="passinput"
              focused={false}
              onChange={(e) =>
                setMobileData({ ...mobileData, password: e.target.value })
              }
              value={mobileData.password}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    style={{ color: "var(--white)" }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            className="loginpage-button typography-loginpage"
            style={{
              width: "100%",
              marginBottom: "25px",
              textTransform: "none",
              fontSize: "20px",
            }}
            onClick={() => {
              verifyEmailFields();
            }}
          >
            {process.env.REACT_APP_PROCEED_BUTTON}
          </Button>
        </TabPanel>
        <TabPanel value={value} index={1} style={{ width: "90%" }}></TabPanel>
        <Typography
          className="typography-loginpage"
          style={{ marginBottom: "15px", fontFamily: "var(--font)" }}
          onClick={() => navigate("/forgot-password")}
        >
          {process.env.REACT_APP_FORGOT_PASSWORD_HEADING}
        </Typography>
        <Typography
          className="typography-loginpage"
          style={{ marginBottom: "25px", fontFamily: "var(--font)" }}
        >
          {process.env.REACT_APP_NEW_USER_HEADING}
          <a
            href="#"
            style={{ marginLeft: "5px" }}
            onClick={() => navigate("/prelogin/register")}
          >
            {process.env.REACT_APP_SIGNIN_USER_HEADING}
          </a>
        </Typography>
        <ModalComponent
          close={close}
          open={open}
          text={text}
          title={process.env.REACT_APP_DIALOG_APP_NAME}
          information={informationText}
        />
        {!error && <div id="recaptcha-container" />}
      </Box>
    </Box>
  );
};

export default Login;
