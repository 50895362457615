import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../App.scss";
import "./Fullpopup.scss";

const Fullpopup = (props) => {
  return (
    <Dialog fullScreen open={props.open} className="spop">
      <>
        {props.text == "Privacy-Pollicy" ? (
          <Typography variant="body1" className="stext">
            <Typography variant="body1" className="stext">
              PRIVACY POLICY:
              <br />
              <br />
              Simran k app official makes no representations or warranties as to
              the quality or availability of its entertainment products
              including but not limited to films, short films, funny videos,
              performing arts videos, culinary videos, travel videos, fitness
              videos, news, games; and/or viewing/purchasing personalized
              celebrity video messages (shout outs for occasions), video
              one-on-one calls, celebrity chats, one-on-one live interaction
              with celebrity, live broadcast with celebrity, direct line with
              celebrity, access to personal channels of celebrity, celebrity
              content, games with celebrities, in-app purchasing / e-shopping,
              features, celebrity members, documents, text, graphics,
              publications, downloads (such as mobile applications), content,
              tools, forums, resources and other wide range of services on its
              Website/App (“content”) to an individual and/or user and/or
              recipient (the “user”). Such content may be cancelled or suspended
              at any time. Content made available under third party links and/or
              third party app and/or celebrity apps and/or Pearl Arcade
              Amusement Pvt.Ltd. and/or any other aggregated content and/or
              licensed content shall be governed by Payment & Refund Policy of
              its respective third party link, third party app, celebrity app,
              Pearl Arcade Amusement Pvt.Ltd.or licensor.
              <br />
              <br />
              An individual and/or user and/or recipient (the “user”) accessing
              the Website/App provides his/her/its details/information during
              the registration process of the Website/App, represents and
              warrants as under:
              <br />
              <br />
              1. The user is above the age of 18 years
              <br />
              <br />
              2. The user is legally authorized to enter into this agreement
              with thesimrankaur.com.
              <br />
              <br />
              The user covenants that:
              <br />
              <br />
              The user is aware and acknowledges that the T&C govern his/her/its
              use of the Website/App together with his/her/its use and/or
              purchase of thesimrankaur.com Content.
              <br />
              <br />
              The user is aware and acknowledges that the T&C include and
              incorporate the policies and guidelines referenced below and
              consents to be bound by the terms of use of the Entertainment
              Content and/or other Content and/or services described and made
              available on the Website/App.
              <br />
              <br />
              The user acknowledges that thesimrankaur.com reserves the right to
              change and/or revise the T&C at any time by posting any changes or
              a revised agreement on the Website/App with the date of such
              change and/or revision and the user consents to be bound by any
              such revised and/or updated T&C regarding the Entertainment
              Content, Content and/or services described or made available on
              the Website/App as per the sole discretion of thesimrankaur.com
              <br />
              <br />
              The user consents to be bound by the T&C regarding the
              Entertainment Content and/or other Content and/or services
              described or made available on the Website/App; and/or made
              available at each log-in; and/or access to the Website/App.
              <br />
              <br />
              The user’s consent implies acceptance of the T&C of the
              Entertainment Content and/or other Content and/or services
              described or made available on the Website/App thereby creating a
              binding contract between the user and thesimrankaur.com.
              <br />
              <br />
              THESIMRANKAUR.COM ENCOURAGES THE USER TO REVIEW THE T&C WHENEVER
              HE/SHE/IT VISITS THE WEBSITE TO MAKE ENSURE THAT THE USER
              UNDERSTANDS THE TERMS AND CONDITIONS GOVERNING USE OF THE
              WEBSITE/APP AND/OR ENTERTAINMENT CONTENT AND/OR OTHER CONTENT
              AND/OR SERVICES OFFERRED ON THE WEBSITE/APP. IF THE USER DOES NOT
              AGREE TO THE T&C, THE USER MAY IMMEDIATELY TERMINATE USE OF THE
              WEBSITE/APP.
              <br />
              <br />
              The user is aware and acknowledges that the T&C include and
              incorporate the policies and guidelines referenced below and
              consents to be bound by the terms of use of the Entertainment
              Content and/or other Content and/or services described and made
              available on the Website/App.
              <br />
              <br />
              1. WEBSITE/APP/THESIMRANKAUR.COM CONTENT USAGE TERMS:
              <br />
              <br />
              1.1. Intellectual Property. Copyright, trade marks, trade names,
              trade dress, layout of Website/App and brand elements, in all
              Content including Entertainment Content and services made
              available on and/or associated with the Website/App, is owned by
              thesimrankaur.com (with an exception of Content aggregated under
              license contracts and/or created by celebrity users (“Celeb
              Content”) under their designated space or based on paid request of
              a user of the Website/App and/or any third-party
              information/website links made available on the Website/App). All
              intellectual property (other than licensed content, Celeb Content
              and third-party information/website links) including but not
              limited to Entertainment Content, software, ideas, processes,
              data, text, media, information, marketing materials and all other
              Content available on thesimrankaur.com Website/App (individually,
              and collectively, “thesimrankaur.com Content”) are owned by
              thesimrankaur.com and/or its subsidiaries, affiliated companies
              and/or its third-party licensors. Any links provided to
              third-party information/website are provided solely as a
              convenience to the user. Thesimrankaur.com does not endorse the
              information/contents regarding/on any such third-party
              website/celebrity app. Thesimrankaur.com is not responsible for
              such third-party content/celebrity app content or any damage that
              may result to the user from the user’s access to or reliance on
              such third-party/celebrity-app; content/information/website. All
              access to links to third-party/celebrity-app;
              content/information/website shall be at the sole risk of the user.
              Unless otherwise specified, thesimrankaur.com Content, products
              and services made available on the Website/App are for the
              personal use of the user and are for non-commercial use. The user
              shall not modify, make copies, distribute, sell, rent, assign,
              transmit, display, perform, reproduce, publish, license, create
              derivative works from, reverse engineer; create internet “links”
              to or from the service and/or products and/or thesimrankaur.com
              Content; send spam, infringing, unlawful or tortious material,
              viruses, harmful computer codes/files/programs; transfer or sell
              any or any part of information, software, services or products or
              thesimrankaur.com Content obtained from the Website/App without
              the written permission from thesimrankaur.com. thesimrankaur.com
              shall have an irrevocable, perpetual and world-wide license to use
              feedback, recommendations, testimonials, suggestions or such other
              related information provided by its user and/or network of
              professionals to enhance its goodwill and/or present its service.
              <br />
              <br />
              1.2. License. The user has a limited, non-exclusive,
              non-transferable right to access thesimrankaur.com Content and/or
              products and/or service information made available on
              thesimrankaur.com Website/App. The user is bound by the
              representation and warranties together with the covenants of the
              user and the limited non-exclusive license granted by
              thesimrankaur.com, as set out in the Agreement. The user shall not
              attempt to gain any unauthorized access to the service or the data
              contained on the Website/App. The acceptable use of
              thesimrankaur.com Content and/or products and/or service
              information made available on thesimrankaur.com Website/App shall
              be limited to the user’s personal purpose. The user is granted a
              limited, non-exclusive, non-transferable right to use
              thesimrankaur.com Content, products, materials and service on the
              Website/App in connection with the user's normal, non-commercial
              use of the Website/App. User shall not copy, reproduce, transmit,
              distribute or create derivative works of thesimrankaur.com
              Content, or information or intellectual property without express
              written authorization from thesimrankaur.com or the applicable
              third-party (if third-party content is at issue).
              <br />
              <br />
              1.3. User posts. By posting, storing or transmitting any content
              on the Website/App, the user hereby grants thesimrankaur.com a
              perpetual, worldwide, non-exclusive, royalty-free, assignable,
              right and license to use, copy, display, perform, create
              derivative works from, distribute, have distributed, transmit and
              assign such content in any form, in all media now known or
              hereinafter created, anywhere in perpetuity and throughout the
              world. thesimrankaur.com does not have the ability to control the
              nature of the user-generated content offered through the
              Website/App. The user shall be solely responsible for his/her/its
              interactions with other users of the Website/App and any content
              such users post. Thesimrankaur.com shall not be liable for any
              damage or harm resulting from any posts by or interactions between
              users. Thesimrankaur.com reserves the right, but has no
              obligation, to monitor interactions between and among users of the
              Website/App and to remove any content thesimrankaur.com deems
              objectionable.
              <br />
              <br />
              1.4. User acknowledgement and covenant, regarding, use of
              Website/App/thesimrankaur.com Content.
              <br />
              <br />
              1.5. i. The user acknowledges that;
              <br />
              <br />
              a. thesimrankaur.com is not responsible for any damages resulting
              from use of the Website/App/thesimrankaur.com Content by anyone.
              <br />
              <br />
              b. The Website/App/thesimrankaur.com Content provided by
              thesimrankaur.com is intended to merely provide one stop
              entertainment OTT platform with a wide range of Entertainment
              Content and/or Content and/or Celeb Content and/or
              thesimrankaur.com Content and to facilitate interactions between
              users inter-se and/or with celebrity users.
              <br />
              <br />
              c. Each request/message/activity created by the user is a
              submission under the Agreement.
              <br />
              <br />
              d. Each message from a celebrity user is owned by the celebrity
              user who created it, and subject to full payment by the user, the
              user shall have the right to use such message for his/her/its
              personal, non-commercial and non-promotional use under the
              Agreement.
              <br />
              <br />
              e. Each piece of thesimrankaur.com Content subscribed to or viewed
              or requested (paid or otherwise) by the user, shall give the user
              a right to view such thesimrankaur.com Content for his/her/its
              personal, non-commercial and non-promotional use under the
              Agreement.
              <br />
              <br />
              f. thesimrankaur.com does not represent or act on behalf of any
              celebrity user and/or user.
              <br />
              <br />
              g. Each user acts as a principal. Thesimrankaur.com has a limited
              role of neutrally making available thesimrankaur.com Content
              and/or facilitating interactions between users inter-se and/or
              with celebrity users.
              <br />
              <br />
              h. thesimrankaur.com is not a guarantor of performance of a user’s
              product request by/from a celebrity user.
              <br />
              <br />
              i. thesimrankaur.com assumes no responsibility over the safety
              and/or availability and/or quality and/or legality of
              thesimrankaur.com Content and/or user requests communicated to
              celebrity users received and/or communicated through the
              Website/App.
              <br />
              <br />
              j. The user and/or the celebrity user shall not under any
              circumstances hold thesimrankaur.com responsible or liable for
              action and/or omission of either any celebrity user and/or any
              user.
              <br />
              <br />
              1.4.ii. The user covenants that the user;
              <br />
              <br />
              a. shall not use thesimrankaur.com Content for illegal purposes.
              <br />
              <br />
              b. abide by all applicable local, state, national, and
              international laws and regulations in his/her/its use of the
              Website/App/thesimrankaur.com Content (including laws regarding
              intellectual property).
              <br />
              <br />
              c. not interfere with or disrupt the use and enjoyment of the
              Website/App/ thesimrankaur.com Content by other users.
              <br />
              <br />
              d. not resell material on the Website/App.
              <br />
              <br />
              e. not engage, directly or indirectly, in transmission of "spam",
              chain letters, junk mail or any other type of unsolicited
              communication.
              <br />
              <br />
              f. not defame, harass, abuse, or disrupt use/access of other users
              including celebrity users of the Website/App/thesimrankaur.com
              Content.
              <br />
              <br />
              g. shall not initiate legal action against thesimrankaur.com
              and/or any celebrity user in the event of non-acceptance of user
              request or non-completion of user request, for any
              thesimrankaur.com Content or product either by thesimrankaur.com
              or by the celebrity user.
              <br />
              <br />
              1.5. Username & Password. The user and/or a celebrity user shall
              register and create an account on the thesimrankaur.com
              Website/App. After completion of the registration process, the
              user and/or the celebrity user shall move to an active environment
              thereby, having access to the Website/App and
              products/thesimrankaur.com content offered on the said
              Website/App. The user and/or celebrity user agrees to create
              his/her/its own unique login identity (“Username” and “Password”)
              that will be used by the said user and/or celebrity user to access
              thesimrankaur.com Content and/or services on thesimrankaur.com
              Website/App. The user and/or celebrity user covenants that it
              shall be his/her/its responsibility to maintain complete security
              of his/her/its personal login identity, content on the platform
              and his/her/its respective transactions/requests/activities. The
              user and/or celebrity user shall be entirely liable for all
              activities that occur under his/her/its login identity.
              Thesimrankaur.com shall not be responsible and/or liable for any
              unauthorized activity under any login identity of the user and/or
              celebrity user. The user and/or celebrity user shall notify
              thesimrankaur.com immediately, of any unauthorized use of any
              login identity of the user and/or celebrity user and
              thesimrankaur.com shall take action that in its discretion is
              appropriate to address the unauthorized activity. The user and/or
              celebrity user covenants to access the Website/App and avail
              thesimrankaur.com Content and/or service and/or products in a
              secure manner in compliance with T&C adopted by thesimrankaur.com
              from time to time.
              <br />
              <br />
              MEMBERSHIP:
              <br />
              <br />
              2.1. Membership. The user and/or celebrity user’s membership shall
              continue until terminated as per Clause 5 hereunder. To use
              Thesimrankaur.com OTT Platform the user and/or celebrity user
              shall need internet access and a device compatible with
              Thesimrankaur.com Website/App and a valid credit card or an
              accepted payment method option prompted at the time of procuring
              membership on the Thesimrankaur.com Website/App.
              <br />
              <br />
              2.2. Membership Plan. Thesimrankaur.com may offer varied
              membership plans, including promotional membership plans through
              third parties. Thesimrankaur.com shall not be responsible for
              services or service/content related representations provided by
              any third parties. Membership account details shall be available
              to the user and/or celebrity user within his/her/its individual
              account settings. Membership plan related queries may be addressed
              at the designated helpline number and/or e-mail address.
              <br />
              <br />
              2.3. Opt-In / Opt-Out Procedure. If a user and/or celebrity user
              decides to opt-in to the Thesimrankaur.com mailing list, they will
              receive emails that may include Thesimrankaur.com news, updates,
              related Thesimrankaur.com Content, product or service information,
              etc. If at any time the user would like to unsubscribe from
              receiving future emails, Thesimrankaur.com shall include detailed
              unsubscribe instructions in the footer of each email and the user
              and/or celebrity user may also unsubscribe receipt of
              Thesimrankaur.com news, updates, related product or service
              information, etc., within the individual account settings. The
              opt-out procedure must be read along with the terms of the privacy
              policy.
              <br />
              <br />
              2.4. Billing Cycle. The billing cycle for the account shall be on
              a monthly or annual basis. The user and/or celebrity user
              authorizes Thesimrankaur.com to renew the susbscribed membership
              for the next billing cycle unless the user and/or celebrity user
              cancels his/her/its membership before the next billing date. The
              user and/or celebrity user shall be responsible for paying taxes
              applicable upon membership sign up and/or purchase and/or
              subscription of Thesimrankaur.com Content.
              <br />
              <br />
              INDEMNIFICATION: The user will indemnify, defend and hold harmless
              Thesimrankaur.com and its affiliates, directors, officers,
              members, managers, shareholders, contractors and employees
              (collectively “Thesimrankaur.com parties”) from and against all
              claims (including third party claims), actions, liabilities,
              losses, expenses, damages and costs (including reasonable
              attorneys’ fees), that may at any time be incurred by reason of
              any claim and/or any claim from any third party arising out of or
              relating to a breach of this agreement and/or breach of the
              representations and/or warranties and/or covenants set forth in
              the T&C by the user and/or celebrity user; any claim from a user
              arising out of the celebrity user’s response to a user’s request
              or any claim arising from misuse of the Thesimrankaur.com
              Content/Website/App by user and/or celebrity user; or any claim
              that is inconsistent with either user or celebrity user’s
              representations or warranties; or any claim arising out of
              infringement and/or passing off any intellectual property or other
              proprietary right of any person or entity; or dispute arising out
              any violation of any provision of these T&C by the user and/or
              celebrity user; or dispute arising out of any information or data
              supplied by the user and/or celebrity user (collectively, ‘the
              Claims’) to Thesimrankaur.com contained under this Agreement. The
              user and/or celebrity user (“indemnifying party”) will be
              responsible for defending the claim with legal counsel of
              Thesimrankaur.com parties (“indemnified party”)’s choice and
              Thesimrankaur.com parties (“indemnified party”) agree(s) to
              co-operate with reasonable requests of the user and/or celebrity
              user (“indemnifying party”) in connection with the defense at the
              cost of the user and/or celebrity user (“indemnifying party”).
              Thesimrankaur.com parties (“indemnified party”) will have the sole
              right to approve or reject any compromise, or settlement in
              resolution of a claim that admits liability or imposes obligations
              on Thesimrankaur.com parties (“indemnified party”). The terms of
              this Clause will survive any termination or cancellation of these
              T&C or the user and/or celebrity user’s use of the Website/App or
              Thesimrankaur.com Content.
              <br />
              <br />
              LIMITED LIABILITY: Thesimrankaur.com’s aggregate liability in tort
              and contract, arising in connection with the T&C, will not, in any
              event, exceed the amount paid by the user for the
              Thesimrankaur.com Content and/or services purchased on the
              Website/App during a period one (1) month prior to the occurrence
              of the incident asserted. Thesimrankaur.com shall not be liable
              for any direct, indirect, incidental, special or consequential
              damages in connection with the T&C or the Thesimrankaur.com
              Content in any manner, including liabilities resulting from (1)
              the use or the inability to use the Website/App or
              Thesimrankaur.com Content or services; (2) the cost of procuring
              Thesimrankaur.com Content or services; (3) any Thesimrankaur.com
              Content or services purchased or obtained or transactions entered
              into through the Website/App; or (4) any lost profits alleged by
              the user and/or celebrity user.
              <br />
              <br />
              TERMINATION:
              <br />
              <br />
              5.1 The agreement by virtue of the T&C becomes effective on the
              date any user first registers on the Website/App of
              Thesimrankaur.com, by creating a login identity and agreeing to
              the terms and conditions / terms of use under the T&C. The
              agreement shall remain valid until service is provided by
              Thesimrankaur.com and/or availed of by the said user.
              <br />
              <br />
              5.2. Thesimrankaur.com reserves the right to terminate any user’s
              access to the Website/App if it reasonably believes, in its sole
              discretion, that the user has breached any of the terms and
              conditions of the T&C. Following termination, such user will not
              be permitted to use the Website/App and Thesimrankaur.com may, in
              its sole discretion and without advance notice to such user,
              cancel any outstanding orders for Thesimrankaur.com Content and/or
              service. If such user’s access to the Website/App is terminated,
              Thesimrankaur.com reserves the right to exercise whatever means it
              deems necessary to prevent unauthorized access to the Website/App.
              This agreement will survive indefinitely unless and until
              Thesimrankaur.com chooses, in its sole discretion and without
              advance to the user, to terminate it.
              <br />
              <br />
              DISCLAIMER:
              <br />
              <br />
              Thesimrankaur.com expressly disclaims that any condition,
              representation or warranty that access and/or use of the service
              offered by Thesimrankaur.com shall be error-free, secure and
              uninterrupted.
              <br />
              <br />
              ASSIGNMENT AND SUBCONTRACTING:
              <br />
              <br />
              The user shall not assign his/her/its rights and obligations under
              this agreement / T&C to anyone. Thesimrankaur.com may assign its
              rights and obligations under this agreement / T&C in its sole
              discretion and without advance notice to the user.
              <br />
              <br />
              GENERAL:
              <br />
              <br />
              Unless otherwise specified, any notice or other communication
              given to Thesimrankaur.com under the agreement / T&C will be in
              writing and be delivered by hand or by pre-paid first-class
              post/courier or be sent by email: to the notice address i.e.,
              Pearl Arcade Amusement Pvt.Ltd., Lotus Building, 803, New Link Rd,
              opposite Star bazar, Andheri West, Mumbai, Maharashtra 400053 or
              email at support@thesimrankaur.com.
              <br />
              <br />
              Arbitration. A person who is not a party to the agreement/ T&C has
              no right under Indian Contract Act, 1872 or under common law to
              rely upon or enforce any term of this agreement/ T&C. The
              agreement and any non-contractual obligations arising therefrom,
              will be governed by the laws of India, notwithstanding, the
              conflict of laws principle. Any dispute arising out of or in
              connection with the Agreement, including any question regarding
              its existence, validity or termination, shall be referred to and
              finally resolved by arbitration in accordance with the Indian
              Arbitration and Conciliation Act for the time being in force,
              which rules are deemed to be incorporated by reference in this
              clause. The arbitral tribunal shall consist of a sole arbitrator
              appointed by Thesimrankaur.com or in case of disagreement between
              the parties regarding the choice of such sole arbitrator; the
              sole-arbitrator shall be appointed by the High Court of Mumbai.
              The language of arbitration shall be English. The parties agree
              that the arbitral award may be enforced against the parties to the
              arbitration proceeding or their assets wherever they may be found,
              and that a court ruling upon enforcement of the arbitral award may
              be entered in any court having jurisdiction. The foregoing will be
              without prejudice to Thesimrankaur.com’s exclusive right to refer
              any dispute to the courts for resolution where necessary to
              preserve the subject matter of the action by way of injunctive or
              declaratory proceeding.
              <br />
              <br />
              Force Majeure. Under no circumstances will Thesimrankaur.com be
              liable for any delay or failure in performance due in whole or in
              part to any acts of God; earthquakes, unavoidable accidents, laws,
              rules, regulations or orders of government authorities, acts of
              war (declared or not), terrorism, hostilities, blockades, civil
              disturbances, embargoes, strikes, state emergencies, state
              announced public health lock downs or any other event or cause
              beyond the reasonable control of Thesimrankaur.com.
              <br />
              <br />
              Governing Law & Jurisdiction. These Terms shall be governed by and
              construed in accordance with the laws of India. Subject to Clause
              8.2, the courts at Mumbai, India shall have the jurisdiction over
              disputes arising out of the agreement/T&C.
              <br />
              <br />
              Severability. If any provision of the agreement/T&C is unlawful or
              unenforceable under any present or future applicable law, it will,
              to the extent permitted by such law, be severed without affecting
              the validity of the other provisions. Without prejudice to the
              foregoing, user and Thesimrankaur.com hereto shall mutually agree
              to provide a legal, valid and enforceable provision as similar in
              terms and effect to such illegal, invalid or unenforceable
              provision as may be possible.
              <br />
              <br />
              No Waiver. Thesimrankaur.com’s failure to enforce any right or
              provision of the agreement/T&C will not prevent Thesimrankaur.com
              from enforcing such right or provision in the future and will not
              be deemed to modify the agreement/T&C.
              <br />
              <br />
              Miscellaneous: The term “including” in this agreement/T&C will be
              interpreted broadly and will mean “including, without limitation.”
              Titles are for convenience only and will not be considered when
              interpreting these Terms.
              <br />
              <br />
              The governing language of this agreement shall be English.
              Translations of this agreement shall be non-binding. All
              communications with respect to this Agreement shall be in the
              English language. The English language version of this document is
              available here: www.thesimrankaur.com.com/terms&conditions.
              <br />
              <br />
              BY BROWSING/USING THE THESIMRANKAUR.COM WEBSITE AND/OR APP AND/OR
              THESIMRANKAUR.COM CONTENT FROM THIS WEBSITE/APP YOU AGREE TO BE
              BOUND BY ALL OF THE TERMS AND CONDITIONS AND ACKNOWLEDGE IT TO BE
              A BINDING AGREEMENT.
            </Typography>{" "}
          </Typography>
        ) : (
          <Typography variant="body1" className="stext">
            TERMS AND CONDITIONS:
            <br />
            <br />
            Simran k app official makes no representations or warranties as to
            the quality or availability of its entertainment products including
            but not limited to films, short films, funny videos, performing arts
            videos, culinary videos, travel videos, fitness videos, news, games;
            and/or viewing/purchasing personalized celebrity video messages
            (shout outs for occasions), video one-on-one calls, celebrity chats,
            one-on-one live interaction with celebrity, live broadcast with
            celebrity, direct line with celebrity, access to personal channels
            of celebrity, celebrity content, games with celebrities, in-app
            purchasing / e-shopping, features, celebrity members, documents,
            text, graphics, publications, downloads (such as mobile
            applications), content, tools, forums, resources and other wide
            range of services on its Website/App (“content”) to an individual
            and/or user and/or recipient (the “user”). Such content may be
            cancelled or suspended at any time. Content made available under
            third party links and/or third party app and/or celebrity apps
            and/or Pearl Arcade Amusement Pvt.Ltd. and/or any other aggregated
            content and/or licensed content shall be governed by Payment &
            Refund Policy of its respective third party link, third party app,
            celebrity app, Pearl Arcade Amusement Pvt.Ltd.or licensor.
            <br />
            <br />
            An individual and/or user and/or recipient (the “user”) accessing
            the Website/App provides his/her/its details/information during the
            registration process of the Website/App, represents and warrants as
            under:
            <br />
            <br />
            1. The user is above the age of 18 years
            <br />
            <br />
            2. The user is legally authorized to enter into this agreement with
            thesimrankaur.com.
            <br />
            <br />
            The user covenants that:
            <br />
            <br />
            The user is aware and acknowledges that the T&C govern his/her/its
            use of the Website/App together with his/her/its use and/or purchase
            of thesimrankaur.com Content.
            <br />
            <br />
            The user is aware and acknowledges that the T&C include and
            incorporate the policies and guidelines referenced below and
            consents to be bound by the terms of use of the Entertainment
            Content and/or other Content and/or services described and made
            available on the Website/App.
            <br />
            <br />
            The user acknowledges that thesimrankaur.com reserves the right to
            change and/or revise the T&C at any time by posting any changes or a
            revised agreement on the Website/App with the date of such change
            and/or revision and the user consents to be bound by any such
            revised and/or updated T&C regarding the Entertainment Content,
            Content and/or services described or made available on the
            Website/App as per the sole discretion of thesimrankaur.com
            <br />
            <br />
            The user consents to be bound by the T&C regarding the Entertainment
            Content and/or other Content and/or services described or made
            available on the Website/App; and/or made available at each log-in;
            and/or access to the Website/App.
            <br />
            <br />
            The user’s consent implies acceptance of the T&C of the
            Entertainment Content and/or other Content and/or services described
            or made available on the Website/App thereby creating a binding
            contract between the user and thesimrankaur.com.
            <br />
            <br />
            THESIMRANKAUR.COM ENCOURAGES THE USER TO REVIEW THE T&C WHENEVER
            HE/SHE/IT VISITS THE WEBSITE TO MAKE ENSURE THAT THE USER
            UNDERSTANDS THE TERMS AND CONDITIONS GOVERNING USE OF THE
            WEBSITE/APP AND/OR ENTERTAINMENT CONTENT AND/OR OTHER CONTENT AND/OR
            SERVICES OFFERRED ON THE WEBSITE/APP. IF THE USER DOES NOT AGREE TO
            THE T&C, THE USER MAY IMMEDIATELY TERMINATE USE OF THE WEBSITE/APP.
            <br />
            <br />
            The user is aware and acknowledges that the T&C include and
            incorporate the policies and guidelines referenced below and
            consents to be bound by the terms of use of the Entertainment
            Content and/or other Content and/or services described and made
            available on the Website/App.
            <br />
            <br />
            1. WEBSITE/APP/THESIMRANKAUR.COM CONTENT USAGE TERMS:
            <br />
            <br />
            1.1. Intellectual Property. Copyright, trade marks, trade names,
            trade dress, layout of Website/App and brand elements, in all
            Content including Entertainment Content and services made available
            on and/or associated with the Website/App, is owned by
            thesimrankaur.com (with an exception of Content aggregated under
            license contracts and/or created by celebrity users (“Celeb
            Content”) under their designated space or based on paid request of a
            user of the Website/App and/or any third-party information/website
            links made available on the Website/App). All intellectual property
            (other than licensed content, Celeb Content and third-party
            information/website links) including but not limited to
            Entertainment Content, software, ideas, processes, data, text,
            media, information, marketing materials and all other Content
            available on thesimrankaur.com Website/App (individually, and
            collectively, “thesimrankaur.com Content”) are owned by
            thesimrankaur.com and/or its subsidiaries, affiliated companies
            and/or its third-party licensors. Any links provided to third-party
            information/website are provided solely as a convenience to the
            user. Thesimrankaur.com does not endorse the information/contents
            regarding/on any such third-party website/celebrity app.
            Thesimrankaur.com is not responsible for such third-party
            content/celebrity app content or any damage that may result to the
            user from the user’s access to or reliance on such
            third-party/celebrity-app; content/information/website. All access
            to links to third-party/celebrity-app; content/information/website
            shall be at the sole risk of the user. Unless otherwise specified,
            thesimrankaur.com Content, products and services made available on
            the Website/App are for the personal use of the user and are for
            non-commercial use. The user shall not modify, make copies,
            distribute, sell, rent, assign, transmit, display, perform,
            reproduce, publish, license, create derivative works from, reverse
            engineer; create internet “links” to or from the service and/or
            products and/or thesimrankaur.com Content; send spam, infringing,
            unlawful or tortious material, viruses, harmful computer
            codes/files/programs; transfer or sell any or any part of
            information, software, services or products or thesimrankaur.com
            Content obtained from the Website/App without the written permission
            from thesimrankaur.com. thesimrankaur.com shall have an irrevocable,
            perpetual and world-wide license to use feedback, recommendations,
            testimonials, suggestions or such other related information provided
            by its user and/or network of professionals to enhance its goodwill
            and/or present its service.
            <br />
            <br />
            1.2. License. The user has a limited, non-exclusive,
            non-transferable right to access thesimrankaur.com Content and/or
            products and/or service information made available on
            thesimrankaur.com Website/App. The user is bound by the
            representation and warranties together with the covenants of the
            user and the limited non-exclusive license granted by
            thesimrankaur.com, as set out in the Agreement. The user shall not
            attempt to gain any unauthorized access to the service or the data
            contained on the Website/App. The acceptable use of
            thesimrankaur.com Content and/or products and/or service information
            made available on thesimrankaur.com Website/App shall be limited to
            the user’s personal purpose. The user is granted a limited,
            non-exclusive, non-transferable right to use thesimrankaur.com
            Content, products, materials and service on the Website/App in
            connection with the user's normal, non-commercial use of the
            Website/App. User shall not copy, reproduce, transmit, distribute or
            create derivative works of thesimrankaur.com Content, or information
            or intellectual property without express written authorization from
            thesimrankaur.com or the applicable third-party (if third-party
            content is at issue).
            <br />
            <br />
            1.3. User posts. By posting, storing or transmitting any content on
            the Website/App, the user hereby grants thesimrankaur.com a
            perpetual, worldwide, non-exclusive, royalty-free, assignable, right
            and license to use, copy, display, perform, create derivative works
            from, distribute, have distributed, transmit and assign such content
            in any form, in all media now known or hereinafter created, anywhere
            in perpetuity and throughout the world. thesimrankaur.com does not
            have the ability to control the nature of the user-generated content
            offered through the Website/App. The user shall be solely
            responsible for his/her/its interactions with other users of the
            Website/App and any content such users post. Thesimrankaur.com shall
            not be liable for any damage or harm resulting from any posts by or
            interactions between users. Thesimrankaur.com reserves the right,
            but has no obligation, to monitor interactions between and among
            users of the Website/App and to remove any content thesimrankaur.com
            deems objectionable.
          </Typography>
        )}
        <Box className="sappbar">
          <Button
            autoFocus
            color="primary"
            className="sappbarbtn"
            onClick={() => props.close()}
          >
            Close
          </Button>
        </Box>
      </>
    </Dialog>
  );
};

export default Fullpopup;
