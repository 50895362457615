import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { TabPanel } from "../../components/Login/Tabelpanel";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import "./SuperFan.scss";
import Today from "./Today";
import Month from "./Month";
import AllTime from "./AllTime";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SuperFanPage() {
  const [values, setValues] = useState(0);
  const navigate = useNavigate();
  const route = useLocation();
  const auth = getAuth();
  let isModal = JSON.parse(
    localStorage.getItem(process.env.REACT_APP_ISMODAL_FLAG)
  );

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValues(newValue);
  };
  return (
    <>
      <Box className="MyprofileHeadingMain">
        <Box
          className="MyprofileHeadingdiv"
          style={{
            position: "fixed",
            marginTop: "9px",
            width: "100%",
            zIndex: "500",
            backgroundColor: "var(--pink)",
          }}
        >
          <Typography variant="h6" id="myprofileheading">
            {process.env.REACT_APP_SUPER_FAN_TITLE}
          </Typography>
        </Box>
        <Box className="mypline" />
      </Box>
      <Box className="tabbox">
        <Box className="tab" style={{ borderTop: "2px solid white" }}>
          <Tabs
            value={values}
            onChange={handleChange}
            className="tab-menu "
            aria-label="basic tabs example"
          >
            <Tab
              className="typography-loginpage"
              label=" Today"
              {...a11yProps(0)}
            />
            <Tab
              className="typography-loginpage"
              label="Month"
              {...a11yProps(1)}
            />
            <Tab
              className="typography-loginpage"
              label="All Time"
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>

        <Box className="tabpanel">
          <TabPanel value={values} index={0}>
            <Today />
          </TabPanel>
          <TabPanel value={values} index={1}>
            <Month />
          </TabPanel>
          <TabPanel value={values} index={2}>
            <AllTime />{" "}
          </TabPanel>
          <ToastContainer
            closeButton={false}
            theme="dark"
            autoClose={3000}
            hideProgressBar={true}
            position="top-center"
            closeOnClick={true}
          />
        </Box>
      </Box>
    </>
  );
}

export default SuperFanPage;
