import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import MessageIcon from "@mui/icons-material/Message";
import FavoriteIcon from "@mui/icons-material/Favorite";
import dimonds from "../../../assests/diamands.png";
import LockIcon from "@mui/icons-material/Lock";
import "./HomeFeed.scss";
import ModelProfilePicture from "../../../assests/modelprofilepic.jpeg";
import InfiniteScroll from "react-infinite-scroll-component";
import play_icon from "../../../component/Images/play_icon.png";
import { useNavigate } from "react-router-dom";
// import React from 'react'

function GridViewVideo({ data, paginate, lastDoc }) {
  const navigate = useNavigate();
  return (
    <>
      {Object.keys(data).length === 0 ? (
        <Typography style={{ top: "50%", left: "40%", position: "fixed" }}>
          No Data
        </Typography>
      ) : (
        <Box
          id="scrollListVideoGrid"
          style={{ height: "100%", overflowY: "scroll" }}
        >
          <InfiniteScroll
            dataLength={Object.keys(data).length}
            next={() => {
              paginate(lastDoc);
            }}
            hasMore={true}
            useWindow={false}
            scrollableTarget="scrollListVideoGrid"
          >
            <Box className="Grid_view">
              {Object.values(data).map((item, index) => {
                return (
                  <Box
                    className="video_box"
                    key={index}
                    onClick={() =>
                      navigate("/back/updateVideo", {
                        state: { videoobject: item },
                      })
                    }
                  >
                    <img
                      alt="picture"
                      src={item.thumbUrl}
                      className="grid_img"
                    />
                    <Box className="img_text_box">
                      <Typography
                        variant="body2"
                        className="typography-loginpage text"
                      >
                        <FavoriteIcon className="video_text" />
                        <span className="video_text_span">
                          {item.likedCounter}
                        </span>
                      </Typography>
                      <Typography
                        variant="body2"
                        className="typography-loginpage text"
                      >
                        <MessageIcon className="img_text" />
                        <span className="img_text_span">
                          {item.commentCounter}
                        </span>
                      </Typography>
                    </Box>
                    {item.noOfGems != 0 ? (
                      <Typography
                        variant="body1"
                        className="typography-loginpage paid_text"
                      >
                        <LockIcon
                          style={{
                            fontSize: "18px",
                            marginRight: "4px",
                          }}
                        />
                        <span className="lock_text">Paid Video </span>
                      </Typography>
                    ) : null}
                    <Box className="videolockcontant">
                      <img alt="" src={play_icon} id="playimg" />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </InfiniteScroll>
        </Box>
      )}
    </>
  );
}

export default GridViewVideo;
// {arr.map((val) => {
//   return (
//     <>
//       <Box className='photo_contant'>
//         <Grid container className="photodiv">
//           <Grid item className="photodiv_imgdiv">
//             <img
//               alt=""
//               src={val.url}
//               className="photoimg"
//             />
//           </Grid>
//           <Grid item>
//             <Typography
//               variant="body1"
//               className="text-align-start"
//               id="photousername"
//             >
//               Simran K
//             </Typography>
//             <Typography
//               variant="body2"
//               className="text-align-start"
//               id="photocaption"
//             >
//               Calm!
//             </Typography>
//           </Grid>
//         </Grid>
//         <Box className='img_box'>
//           <img alt='' src={val.url} className='post_img' />
//           {val.id === "paid" ?
//           <Typography variant='body1' className='typography-loginpage paid_text'>
//           <LockIcon
//                             style={{
//                               fontSize: "18px",
//                               marginRight: "4px",
//                             }}
//                           />
//           Paid Video
//         </Typography>
//         :""}
//         </Box>
//         <Box className="img_text_box">
//           <Typography variant='body2' className='typography-loginpage text'>
//             <FavoriteIcon className='img_text' />
//             0
//           </Typography>
//           <Typography variant='body2' className='typography-loginpage text'>
//             <MessageIcon className='img_text' />
//             0
//           </Typography>
//           <Typography variant='body2' className='typography-loginpage text'>
//             <img alt='' src={dimonds} style={{ height: '20px' }} />
//             0
//           </Typography>
//         </Box>
//       </Box>
//       <Box className="photoendline" />
//     </>
//   )
// })

// }

// <Box className="profile_view" id="scrollList" style={{ height: "100%", overflowY: "scroll" }}>
//   <InfiniteScroll
//     dataLength={Object.keys(data).length}
//     next={() => {
//       paginate(lastDoc);
//     }}
//     hasMore={true}
//     scrollableTarget='scrollList'
//   >
//     {Object.values(data).map((item, index) => {
//       return (
//         <div key={index}>
//           <Box className="photo_contant">
//             <Grid container className="photodiv">
//               <Grid item className="photodiv_imgdiv">
//                 <img
//                   alt="profilePhoto"
//                   src={ModelProfilePicture}
//                   className="photoimg"
//                 />
//               </Grid>
//               <Grid item>
//                 <Typography
//                   variant="body1"
//                   className="text-align-start"
//                   id="photousername"
//                 >
//                   {item.title}
//                 </Typography>
//                 <Typography
//                   variant="body2"
//                   className="text-align-start"
//                   id="photocaption"
//                 >
//                   {item.desc}
//                 </Typography>
//               </Grid>
//             </Grid>
//             <Box className="img_box">
//               <img alt="photo" src={item.photoUrl} className="post_img" />
//               {item.noOfGems != 0 ? (
//                 <Typography
//                   variant="body1"
//                   className="typography-loginpage paid_text"
//                 >
//                   <LockIcon
//                     style={{
//                       fontSize: "18px",
//                       marginRight: "4px",
//                     }}
//                   />
//                   Paid Image
//                 </Typography>
//               ) : null}
//             </Box>
//             <Box className="img_text_box">
//               <Typography
//                 variant="body2"
//                 className="typography-loginpage text"
//               >
//                 <FavoriteIcon className="img_text" />
//                 {item.likedCounter}
//               </Typography>
//               <Typography
//                 variant="body2"
//                 className="typography-loginpage text"
//               >
//                 <MessageIcon className="img_text" />
//                 {item.commentCounter}
//               </Typography>
//               <Typography
//                 variant="body2"
//                 className="typography-loginpage text"
//               >
//                 <img alt="" src={dimonds} style={{ height: "20px" }} />
//                 {item.noOfGems}
//               </Typography>
//             </Box>
//           </Box>
//           <Box className="photoendline" />
//         </div>
//       );
//     })}
//   </InfiniteScroll>
// </Box>
