import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Divider,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  DialogTitle,
  Box,
} from "@mui/material";
import { db } from "../../firebaseConfig/firebaseConfig";
import {
  getAuth,
  fetchSignInMethodsForEmail,
  EmailAuthProvider,
} from "firebase/auth";
import { createUserWithEmailAndPassword } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./EmailPopup.scss";
import ModalComponent from "../ModalComponent/ModalComponent";
import Spinner2 from "../Spinner/Spinner2";

const EmailPopup = ({ openDialog, offPopup }) => {
  const auth = getAuth();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [text, setText] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [data, setData] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const oldUid = JSON.parse(localStorage.getItem("userUid"));
  useEffect(() => {
    localStorage.setItem(
      "userUid",
      JSON.stringify(data?.idUser)
      // JSON.stringify("a6pV92yCg3cEyqC5W1TJiAC8q773")
    );
  }, []);

  const catchBlockFunction = (error) => {
    setFormSubmitted(false);
    setText(error.code);
    setOpen(true);
    offPopup();
    setShowLoader(false);
  };

  const updatedUserCollectionData = (id, user, idType) => {
    const updateLastChatUnreadCount = doc(
      db,
      process.env.REACT_APP_USERS_COLLECTION_NAME,
      id
    );
    let updateLastMessage;
    if ("oldId" in idType) {
      updateLastMessage = {
        transfer: true,
        transferredUidFrom: idType.oldId,
      };
    } else {
      updateLastMessage = {
        transfer: true,
        transferredUid: user.uid,
      };
    }
    updateDoc(updateLastChatUnreadCount, updateLastMessage).then(() => {});
  };

  const handleSubmit = () => {
    const keysToRemove = [
      "users",
      "wallet",
      "myPurchasePhotos",
      "myPurchaseVdos",
      "promoteImage",
      "modelName",
      "deviceToken",
    ];
    keysToRemove.forEach((key) => {
      localStorage.removeItem(key);
    });

    const copyDataAndMessages = async (
      oldCollectionName,
      newCollectionName,
      oldUid,
      user
    ) => {
      const oldDocRef = doc(db, oldCollectionName, oldUid);
      const oldDocSnapshot = await getDoc(oldDocRef);

      if (oldDocSnapshot.exists()) {
        const oldDocData = oldDocSnapshot.data();

        const newDocRef = doc(db, newCollectionName, user.uid);
        await setDoc(newDocRef, oldDocData);

        const messagesCollectionRef = collection(oldDocRef, "messages");
        const messagesSnapshot = await getDocs(messagesCollectionRef);

        if (messagesSnapshot.size > 0) {
          const copyMessagesPromises = [];

          messagesSnapshot.forEach((messageDoc) => {
            const messageData = messageDoc.data();

            const newMessageDocRef = doc(
              collection(newDocRef, "messages"),
              messageDoc.id
            );

            const copyMessagePromise = setDoc(newMessageDocRef, messageData);
            copyMessagesPromises.push(copyMessagePromise);
          });

          await Promise.all(copyMessagesPromises);

          console.log("Data copied successfully");
        } else {
          console.log("No messages found in the 'messages' subcollection");
        }
      } else {
        console.error("Document not found with ID: ", oldUid);
      }
    };

    const updateCollection = async (
      oldUid,
      user,
      collectionName,
      localStorageKey
    ) => {
      let dataRef;

      if (
        collectionName === process.env.REACT_APP_USERTRANSACTIONS_COLLECTION
      ) {
        dataRef = query(
          collection(db, collectionName),
          where("user_id", "==", `${oldUid}`)
        );
      } else if (
        collectionName === process.env.REACT_APP_USERS_COLLECTION_NAME
      ) {
        dataRef = query(
          collection(db, collectionName),
          where("idUser", "==", `${oldUid}`)
        );
      } else if (
        collectionName !== process.env.REACT_APP_USERS_COLLECTION_NAME ||
        collectionName !== process.env.REACT_APP_USERTRANSACTIONS_COLLECTION
      ) {
        dataRef = query(
          collection(db, collectionName),
          where("uid", "==", `${oldUid}`)
        );
      }

      const dataSnapShot = await getDocs(dataRef);

      if (dataSnapShot.size >= 0) {
        const dataArray = [];

        dataSnapShot.forEach((docc) => {
          const data = docc.data();

          if (
            collectionName === process.env.REACT_APP_USERTRANSACTIONS_COLLECTION
          ) {
            data.user_id = user.uid;
          } else if (
            collectionName === process.env.REACT_APP_USERS_COLLECTION_NAME
          ) {
            updatedUserCollectionData(oldUid, user, { type: user.uid });

            data.idUser = user.uid;
          } else if (
            collectionName !== process.env.REACT_APP_USERS_COLLECTION_NAME ||
            collectionName !== process.env.REACT_APP_USERTRANSACTIONS_COLLECTION
          ) {
            data.uid = user.uid;
          }

          if (
            collectionName === process.env.REACT_APP_USERTRANSACTIONS_COLLECTION
          ) {
            data.user_email = user.email;
          } else if (
            collectionName ===
              process.env.REACT_APP_REJECTED_SHOUTOUT_REQUEST_COLLECTION ||
            collectionName ===
              process.env.REACT_APP_COMPLETED_SHOUTOUT_REQUEST_COLLECTION ||
            collectionName ===
              process.env.REACT_APP_PENDING_SHOUTOUT_REQUEST_COLLECTION
          ) {
            data.userEmail = user.email;
          } else if (
            collectionName !==
              process.env.REACT_APP_REJECTED_SHOUTOUT_REQUEST_COLLECTION ||
            collectionName !==
              process.env.REACT_APP_COMPLETED_SHOUTOUT_REQUEST_COLLECTION ||
            collectionName !==
              process.env.REACT_APP_PENDING_SHOUTOUT_REQUEST_COLLECTION ||
            collectionName !== process.env.REACT_APP_USERTRANSACTIONS_COLLECTION
          ) {
            data.email = user.email;
          }
          dataArray.push(data);
        });

        const addDocPromises = dataArray.map(async (data) => {
          if (collectionName === process.env.REACT_APP_USERS_COLLECTION_NAME) {
            const docRef = doc(db, collectionName, user.uid);
            return await setDoc(docRef, data);
          } else {
            return await addDoc(collection(db, collectionName), data);
          }
        });

        await Promise.all(addDocPromises);

        updatedUserCollectionData(user.uid, user, { oldId: oldUid });
        if (
          collectionName === process.env.REACT_APP_USERS_COLLECTION_NAME ||
          collectionName === process.env.REACT_APP_WALLET_COLLECTION_NAME
        ) {
          if (collectionName === process.env.REACT_APP_USERS_COLLECTION_NAME) {
            data.idUser = user.uid;
          } else if (
            collectionName === process.env.REACT_APP_WALLET_COLLECTION_NAME
          ) {
            data.uid = user.uid;
          }
        }

        const updatedLocalStorageData = dataArray.map((data) => {
          return data;
        });
        if (
          collectionName !==
            process.env.REACT_APP_VIDEO_CALL_COMPLETED_COLLECTION_NAME ||
          collectionName !== process.env.REACT_APP_REACHGEMS_COLLECTION ||
          collectionName !==
            process.env.REACT_APP_PENDING_SHOUTOUT_REQUEST_COLLECTION ||
          collectionName !==
            process.env.REACT_APP_COMPLETED_SHOUTOUT_REQUEST_COLLECTION ||
          collectionName !==
            process.env.REACT_APP_REJECTED_SHOUTOUT_REQUEST_COLLECTION ||
          collectionName !==
            process.env.REACT_APP_USERTRANSACTIONS_COLLECTION ||
          collectionName !==
            process.env.REACT_APP_VIDEO_CALL_REQUEST_COLLECTION_NAME
        ) {
          if (
            collectionName == process.env.REACT_APP_USERS_COLLECTION_NAME ||
            collectionName == process.env.REACT_APP_WALLET_COLLECTION_NAME
          ) {
            localStorage.setItem(
              localStorageKey,
              JSON.stringify(updatedLocalStorageData[0])
            );
          } else {
            localStorage.setItem(
              localStorageKey,
              JSON.stringify(updatedLocalStorageData)
            );
          }
        }
      } else {
        console.error("No documents found in the snapshot");
      }
    };
    setShowLoader(true);

    if (email.trim() == "") {
      setText(process.env.REACT_APP_ENTER_EMAIL_MODAL_TEXT);
      setOpen(true);
    } else if (
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email) == false
    ) {
      setText(process.env.REACT_APP_INVALID_EMAIL_MODAL_TEXT);
      setOpen(true);
    } else if (password.trim() == "") {
      setText(process.env.REACT_APP_ENTER_PASSWORD_MODAL_TEXT);
      setOpen(true);
    } else if (confirmPassword.trim() == "") {
      setText("Please Enter Confirm password");
      setOpen(true);
    } else if (password !== confirmPassword) {
      setText("Password and Confirm Password must match");
      setOpen(true);
    } else {
      setFormSubmitted(true);

      fetchSignInMethodsForEmail(auth, email)
        .then((signInMethods) => {
          if (
            signInMethods.indexOf(
              EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
            ) !== -1
          ) {
            setText(process.env.REACT_APP_EMAIL_ALREADY_EXISTS_TEXT);
            setFormSubmitted(false);
            setOpen(true);
          } else {
            createUserWithEmailAndPassword(auth, email, password)
              .then(async (userCredential) => {
                setShowLoader(true);
                const user = userCredential.user;

                // UPDATED USER COLLECTION DATA
                updateCollection(
                  oldUid,
                  user,
                  process.env.REACT_APP_USERS_COLLECTION_NAME,
                  process.env.REACT_APP_USERS_COLLECTION_NAME
                );
                // UPDATED PHOTO DATA
                updateCollection(
                  oldUid,
                  user,
                  process.env.REACT_APP_MYPURCHASEDPHOTOS_COLLECTION_NAME,
                  process.env.REACT_APP_MYPURCHASEDPHOTOS_COLLECTION_NAME
                );
                // Updated Video DATA
                updateCollection(
                  oldUid,
                  user,
                  process.env.REACT_APP_PURCHASEDVIDEOS_COLLECTION_NAME,
                  process.env.REACT_APP_PURCHASEDVIDEOS_COLLECTION_NAME
                );
                // WALLET
                updateCollection(
                  oldUid,
                  user,
                  process.env.REACT_APP_WALLET_COLLECTION_NAME,
                  process.env.REACT_APP_WALLET_COLLECTION_NAME
                );
                // VideoCallComplete
                updateCollection(
                  oldUid,
                  user,
                  process.env.REACT_APP_VIDEO_CALL_COMPLETED_COLLECTION_NAME,
                  process.env.REACT_APP_VIDEO_CALL_COMPLETED_COLLECTION_NAME
                );
                // UPDATED  VIDEOCALL REQUEST
                updateCollection(
                  oldUid,
                  user,
                  process.env.REACT_APP_VIDEO_CALL_REQUEST_COLLECTION_NAME,
                  process.env.REACT_APP_VIDEO_CALL_REQUEST_COLLECTION_NAME
                );
                // Updated  Recharge Gems
                updateCollection(
                  oldUid,
                  user,
                  process.env.REACT_APP_REACHGEMS_COLLECTION,
                  process.env.REACT_APP_REACHGEMS_COLLECTION
                );
                // userTransaction
                updateCollection(
                  oldUid,
                  user,
                  process.env.REACT_APP_USERTRANSACTIONS_COLLECTION,
                  process.env.REACT_APP_USERTRANSACTIONS_COLLECTION
                );
                // Pending shoutoutRequest
                updateCollection(
                  oldUid,
                  user,
                  process.env.REACT_APP_PENDING_SHOUTOUT_REQUEST_COLLECTION,
                  process.env.REACT_APP_PENDING_SHOUTOUT_REQUEST_COLLECTION
                );
                // COMPLETED SHOUTOUT
                updateCollection(
                  oldUid,
                  user,
                  process.env.REACT_APP_COMPLETED_SHOUTOUT_REQUEST_COLLECTION,
                  process.env.REACT_APP_COMPLETED_SHOUTOUT_REQUEST_COLLECTION
                );
                // REJECTED SHOUTOUT
                updateCollection(
                  oldUid,
                  user,
                  process.env.REACT_APP_REJECTED_SHOUTOUT_REQUEST_COLLECTION,
                  process.env.REACT_APP_REJECTED_SHOUTOUT_REQUEST_COLLECTION
                );

                // Updated User DM Chats data

                await copyDataAndMessages(
                  process.env.REACT_APP_NEW_CHATS_COLLECTION,
                  process.env.REACT_APP_NEW_CHATS_COLLECTION,
                  oldUid,
                  user
                );

                // Updated Help and support Chats

                await copyDataAndMessages(
                  process.env.REACT_APP_HELP_AND_SUPPORT_CHAT_COLLECTION_NAME,
                  process.env.REACT_APP_HELP_AND_SUPPORT_CHAT_COLLECTION_NAME,
                  oldUid,
                  user
                );

                const querySnapshot = doc(
                  db,
                  process.env.REACT_APP_WALLET_COLLECTION_NAME,
                  oldUid
                );
                updateDoc(querySnapshot, {
                  balance: 0,
                });

                offPopup();
                setShowLoader(false);
              })
              .catch((error) => {
                catchBlockFunction(error);
              });
          }
        })
        .catch((error) => {
          catchBlockFunction(error);
        });
    }
  };
  return (
    <>
      <Dialog
        open={openDialog}
        close={offPopup}
        className="photopopup"
        aria-labelledby="draggable-dialog-title"
        style={{ justifyContent: "flex-start" }}
      >
        {formSubmitted ? (
          <>
            <Box style={{ height: "45em" }}>
              <Typography
                variant="h4"
                sx={{
                  color: "white",
                  fontSize: "1.5em",
                  paddingTop: "20px",
                  textAlign: "justify",
                }}
              >
                We are collecting your data from old account and migrating. It
                might take few minutes.
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  color: "white",
                  fontSize: "1.5em",
                  paddingTop: "20px",
                  textAlign: "justify",
                }}
              >
                PLEASE DO NOT LEAVE SCREEN.
              </Typography>
              {showLoader && <Spinner2 />}
            </Box>
          </>
        ) : (
          <>
            <Typography id="modeltext" className="context-div" sx={{ mt: 2 }}>
              New Account Details
            </Typography>
            <Divider sx={{ backgroundColor: "var(--white)", mt: "1em" }} />

            <Box sx={{ textAlign: "center", pt: 2 }}>
              <Typography sx={{ textAlign: "justify", pt: 1 }}>
                We are migrating your account to email and password login
                instead of OTP login. Due to technical limitations, OTP login
                will be turned off in couple of days. We request you to migrate
                account immediately.{" "}
              </Typography>
            </Box>

            <TextField
              placeholder="Email"
              type="email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="textFeild"
              style={{ padding: "2em 0em" }}
            />

            <TextField
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ paddingBottom: "2em" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              placeholder="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              fullWidth
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              style={{ paddingBottom: "2em" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                id="popbtn"
                style={{ marginBottom: "2em", backgroundColor: "var(--pitch)" }}
                onClick={handleSubmit}
              >
                Proceed
              </Button>
            </Box>
          </>
        )}
      </Dialog>
      <ModalComponent
        close={() => setOpen(false)}
        open={open}
        text={text}
        title={process.env.REACT_APP_DIALOG_APP_NAME}
      />
    </>
  );
};
export default EmailPopup;
