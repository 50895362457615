import React from "react";
import { Box, Typography } from "@mui/material";
import dimond from "../../../assests/diamands.png";
import "./Overview.scss";
import "../../../App.scss";

const OverViewCommon = ({
  photos,
  videos,
  liveComRevenue,
  liveGifRevenue,
  liveRevenue,
  shoutOut,
  videoCallPlaced,
  Dm,
  orders,
  income,
}) => {
  const items = [
    { label: "PHOTOS", value: photos },
    { label: "VIDEO", value: videos },
    { label: "LIVE STREAMING", value: liveRevenue },
    { label: "LIVE STREAMING - GIFs", value: liveGifRevenue },
    { label: "LIVE STREAMING - COMMENT", value: liveComRevenue },
    { label: "SHOUTOUTS", value: shoutOut },
    { label: "DIRECT MESSAGING (DM)", value: Dm },
    { label: "VIDEO CALLS (1 ON 1)", value: videoCallPlaced },
    { label: "PRODUCTS (SHOP)", value: orders },
  ];

  const totalGems = items.reduce((total, item) => total + (item.value || 0), 0);

  const formattedTotal = totalGems.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });

  return (
    <Box className="overview_month">
      <Box className="part_a">
        <Box>
          <Typography variant="body2" className="typography_loginpage tt">
            Total Spending: {totalGems}{" "}
            <img alt="" src={dimond} className="d_img" />
          </Typography>
          <Typography variant="body2" className="typography_loginpage tt">
            Income for {income} is {formattedTotal}
          </Typography>
        </Box>
      </Box>
      <Box className="line" />
      <Box className="part_b">
        <Typography
          variant="body1"
          className="typography_loginpage part_b_heading"
        >
          Income Summary
        </Typography>
        {items?.map((item, index) => (
          <>
            {console.log(item.value, "ITEMS")}
            <div key={index} className="b_text_content">
              <Typography variant="body2" className="typography_loginpage">
                {item.label}
              </Typography>
              <Typography variant="body2" className="typography_loginpage t2">
                {item.value || item.value === 0 ? (
                  <div>
                    {item.value}
                    <img alt="" src={dimond} className="d_img" />
                  </div>
                ) : (
                  <span>Loading...</span>
                )}
              </Typography>
              <Box className="line" />
            </div>
          </>
        ))}
      </Box>
    </Box>
  );
};

export default OverViewCommon;
