import React, { useEffect, useState } from "react";
import "./Shoutoutmsgpage.scss";
import "../../../App.scss";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import shoutout_bg from "../../../assests/shoutout_bg.jpg";
import diamands from "../../../assests/diamands.png";
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  serverTimestamp,
  getDocs,
  query,
  where,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig/firebaseConfig";
import InsufficientBalance from "./InsufficientBalance";
import WalletAskModal from "./WalletAskModal";
import { useNavigate } from "react-router-dom";

function Shoutoutmsgpage() {
  const navigate = useNavigate();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [data, setdata] = useState({
    message: "",
    termsAndCondition: false,
    publicShoutout: false,
  });
  const [wallet, setWallet] = useState();
  const [text, setText] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTilte] = useState("");
  const [openInsufficientBalanceModal, setOpenInsufficientBalanceModal] =
    useState(false);
  const [openWalletAskModal, setOpenWalletAskModal] = useState(false);

  useEffect(() => {
    const q = query(
      collection(db, process.env.REACT_APP_WALLET_COLLECTION_NAME),
      where(
        "uid",
        "==",
        JSON.parse(
          localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE)
        ).idUser
      )
    );
    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setWallet(doc.data());
        localStorage.setItem(
          process.env.REACT_APP_WALLET_LOCALSTORAGE,
          JSON.stringify(doc.data())
        );
      });
    });
  }, []);
  const handleClose = () => {
    setdata({ ...data, message: data.message });
    setOpen(false);
    setModalTilte("");
  };
  const handleClose1 = () => {
    navigate("/mainback/shoutout", { state: { muted: false } });
    setdata({ ...data, message: data.message });
    setOpen(false);
    setModalTilte("");
  };

  const handleSubmit = () => {
    const q2 = query(
      collection(db, process.env.REACT_APP_USERS_COLLECTION_NAME),
      where("idUser", "==", wallet?.uid)
    );

    getDocs(q2).then((result) => {
      console.log(result, "REsult");
      result.forEach((users) => {
        if (users.data()?.blocked) {
          console.log("TRUE");
          // navigate("/login");
          navigate("/login");
          localStorage.clear();
        } else {
          console.log(data);

          if (
            wallet?.balance >=
            parseInt(process.env.REACT_APP_SHOUTOUT_REQUEST_GEMS)
          ) {
            let updatedWallet = {
              ...wallet,
              balance:
                parseInt(wallet?.balance) -
                parseInt(process.env.REACT_APP_SHOUTOUT_REQUEST_GEMS),
            };
            updateDoc(doc(db, "wallet", user.idUser), {
              balance: parseInt(updatedWallet.balance),
            }).then(() => {
              let pendingShoutoutObject = {
                message: data.message,
                noOfGems: parseInt(process.env.REACT_APP_SHOUTOUT_REQUEST_GEMS),
                platform: process.env.REACT_APP_PLATFORM,
                public: data.publicShoutout,
                requestedBy: user.name,
                requestedOn: serverTimestamp(),
                tnc: data.termsAndCondition,
                uid: user.idUser,
                userEmail: user.email,
                userImage: user.urlAvatar,
                version: process.env.REACT_APP_VERSION,
                "model-identifier": process.env.REACT_APP_MODEL_IDENTIFIER,
              };
              addDoc(
                collection(
                  db,
                  process.env.REACT_APP_PENDING_SHOUTOUT_REQUEST_COLLECTION
                ),
                pendingShoutoutObject
              ).then((result) => {
                let userTransactionnsTable = {
                  dm_message_gif: "",
                  dm_message_image: "",
                  dm_message_text: "",
                  live_session_purchase_gif_name: "",
                  live_session_ref_id: "",
                  platform: process.env.REACT_APP_PLATFORM,
                  purchased_media_description: "",
                  purchased_media_title: "",
                  purchased_media_url: "",
                  purchased_product_description: "",
                  purchased_product_title: "",
                  recharge_gems_transaction_amount: "",
                  recharge_gems_transaction_id: "",
                  transaction_gems: parseInt(
                    process.env.REACT_APP_SHOUTOUT_REQUEST_GEMS
                  ),
                  transaction_gems_string: `-${process.env.REACT_APP_SHOUTOUT_REQUEST_GEMS}`,
                  transaction_ref_id: result.id,
                  transaction_time: serverTimestamp(),
                  transaction_type_id: "9",
                  transaction_type_name: "shoutout_request",
                  updated_balance_after_transaction: `${updatedWallet.balance}`,
                  user_email: user.email,
                  user_id: user.idUser,
                  user_name: user.name,
                  version: process.env.REACT_APP_VERSION,
                };
                addDoc(
                  collection(db, "userTransactions"),
                  userTransactionnsTable
                ).then(() => {
                  setText("Your Shoutout Requet Has been sent!");
                  setModalTilte("Thank You!");
                  setOpenModal(true);
                  setOpenWalletAskModal(false);
                  setOpenInsufficientBalanceModal(false);
                });
              });
            });
          }
        }
      });
    });
  };

  const handleManageModal = () => {
    if (data.message.trim() == "") {
      setOpen(true);
      setText(process.env.REACT_APP_SHOUTOUT_MESSAGE_TEXT);
      setModalTilte(process.env.REACT_APP_DIALOG_APP_NAME);
    } else if (data.termsAndCondition === false) {
      setOpen(true);
      setText(process.env.REACT_APP_AGREE_WITH_TERMS_CONDITION_TEXT);
      setModalTilte(process.env.REACT_APP_DIALOG_APP_NAME);
    } else if (
      wallet?.balance >= parseInt(process.env.REACT_APP_SHOUTOUT_REQUEST_GEMS)
    ) {
      setOpenWalletAskModal(true);
    } else {
      setOpenInsufficientBalanceModal(true);
    }
  };

  return (
    <Box className="smsgmain">
      <Box style={{ display: "flex", alignItems: "end" }}>
        <img alt="" src={shoutout_bg} style={{ width: "100%" }} />
        <Typography variant="h5" className="sheading text">
          {process.env.REACT_APP_SHOUTOUT_HEADING}
        </Typography>
      </Box>
      <Box className="msgcontent">
        <Typography variant="body1" className="text">
          {process.env.REACT_APP_SHOUTOUT_MESSAGE_TEXTFIELD_TITLE} :
        </Typography>
        <TextareaAutosize
          value={data.message}
          onChange={(e) => setdata({ ...data, message: e.target.value })}
          aria-label="minimum height"
          minRows={5}
          placeholder={
            process.env.REACT_APP_SHOUTOUT_MESSAGE_TEXTFIELD_PLACEHOLDER
          }
          className="textarea"
        />
        <Box className="chekgroup   ">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  className="chekbox"
                  checked={data.termsAndCondition}
                  onChange={(e) =>
                    setdata({ ...data, termsAndCondition: e.target.checked })
                  }
                />
              }
              label="Terms And Conditions"
              className="checktext"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  className="chekbox"
                  checked={data.publicShoutout}
                  onChange={(e) =>
                    setdata({ ...data, publicShoutout: e.target.checked })
                  }
                />
              }
              label="Public ShoutOut"
              className="checktext"
            />
          </FormGroup>
        </Box>
        <Typography
          variant="body1"
          className="text"
          sx={{ textAlign: "center", margin: "10px 0px" }}
        >
          {process.env.REACT_APP_BACK_TO_PROCEED_BUTTON}
        </Typography>
        <Button
          variant="contained"
          className="shoutoutbtn"
          fullWidth
          onClick={() => handleManageModal()}
        >
          {process.env.REACT_APP_REQUEST_VIDEO_MESSAGE_NOW_HEADING}
          <img
            alt=""
            src={diamands}
            style={{ height: "15px", marginLeft: "3px" }}
          />
        </Button>
      </Box>
      {openModal && (
        <ModalComponent
          open={openModal}
          close={() => handleClose1()}
          text={text}
          title={
            modalTitle != ""
              ? modalTitle
              : process.env.REACT_APP_DIALOG_APP_NAME
          }
        />
      )}
      {open && (
        <ModalComponent
          open={open}
          close={() => handleClose()}
          text={text}
          title={
            modalTitle != ""
              ? modalTitle
              : process.env.REACT_APP_DIALOG_APP_NAME
          }
        />
      )}
      {wallet?.balance >=
        parseInt(process.env.REACT_APP_SHOUTOUT_REQUEST_GEMS) &&
        openWalletAskModal && (
          <WalletAskModal
            open={openWalletAskModal}
            close={() => setOpenWalletAskModal(false)}
            submit={() => handleSubmit()}
            wallet={wallet?.balance}
          />
        )}
      {openInsufficientBalanceModal &&
        wallet?.balance <
          parseInt(process.env.REACT_APP_SHOUTOUT_REQUEST_GEMS) && (
          <InsufficientBalance
            open={openInsufficientBalanceModal}
            close={() => setOpenInsufficientBalanceModal(false)}
            wallet={wallet?.balance}
          />
        )}
    </Box>
  );
}

export default Shoutoutmsgpage;
