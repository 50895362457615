import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import supercrown1 from "../../assests/super_crown_1.png";
import supercrown2 from "../../assests/super_crown_2.png";
import supercrown3 from "../../assests/super_crown_3.png";
import placeholder_image from "../../assests/placeholder_image.jpg";
import diamands from "../../assests/diamands.png";
import "./SuperFan.scss";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import InfiniteScroll from "react-infinite-scroll-component";
import { is } from "@babel/types";
import CopyMailPhoneNumberModal from "./CopyMailPhoneNumberModal";
import { toast } from "react-toastify";
import Spinners from "../../components/Spinner/Spinners";

function Today() {
  const [data, setData] = useState([]);
  const [userDetailsModal, setUserDetailsModal] = useState({
    open: false,
    val: "",
  });
  const [lastDoc, setLastDoc] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  let isModal = JSON.parse(
    localStorage.getItem(process.env.REACT_APP_ISMODAL_FLAG)
  );

  let date = new Date();
  let firstDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  useEffect(() => {
    getCurrentMonthSuperFans();
  }, []);

  const getCurrentMonthSuperFans = () => {
    setIsLoading(true);

    let q1;
    q1 = query(
      collection(db, process.env.REACT_APP_REACHGEMS_COLLECTION),
      where("rechargeDate", ">=", firstDay)
    );
    onSnapshot(q1, (querySnapshot) => {
      let currentMonthSuperFans = {};
      querySnapshot.forEach((element) => {
        let id = element.data().uid;
        if (Object.keys(currentMonthSuperFans).includes(id)) {
          currentMonthSuperFans = {
            ...currentMonthSuperFans,
            [id]: {
              ...currentMonthSuperFans[id],
              noOfGems:
                currentMonthSuperFans[id].noOfGems + element.data().noOfGems,
            },
          };
        } else {
          currentMonthSuperFans = {
            ...currentMonthSuperFans,
            [id]: element.data(),
          };
        }
      });

      let sortedArray = Object.values(currentMonthSuperFans).sort(
        (firstItem, secondItem) => secondItem.noOfGems - firstItem.noOfGems
      );
      if (sortedArray.length > 0) {
        let temp = sortedArray[0];
        sortedArray[0] = sortedArray[1];
        sortedArray[1] = temp;
      }

      setData(sortedArray);
      setIsLoading(false);
    });
  };
  const handleClose = () => {
    setUserDetailsModal(true);
  };
  const notify = (val) => toast(val);
  return (
    <Box className="month">
      {isLoading ? <Spinners /> : null}

      {data.length > 0 && (
        <Box className="win">
          {data.map((val, index) => {
            let isDataLength = data.length <= 2;
            return (
              <>
                {index === 0 && val !== undefined && (
                  <Box
                    className="no2"
                    onClick={() => {
                      setUserDetailsModal({ open: true, val: val });
                    }}
                  >
                    <img alt="" src={supercrown2} className="crown2" />
                    <Box className="placeimgbox2">
                      <img
                        alt=""
                        src={val?.image_url ? val.image_url : ""}
                        className="placeimg2"
                      />
                    </Box>
                    <Typography
                      variant="body1"
                      className="typography-loginpage "
                      style={{
                        lineHeight: "1.5rem",
                        marginTop: "10px",
                        fontSize: "13px",
                      }}
                    >
                      {val?.user_name}
                    </Typography>
                    <Typography
                      variant="body2"
                      className="je typography-loginpage "
                    >
                      {val?.noOfGems}
                      <img
                        alt=""
                        src={diamands}
                        style={{ height: "20px", marginLeft: "5px" }}
                      />
                    </Typography>
                  </Box>
                )}
                {index === 1 && (
                  <Box
                    className="no1"
                    onClick={() => {
                      setUserDetailsModal({ open: true, val: val });
                    }}
                  >
                    <img alt="" src={supercrown1} className="crown1" />
                    <Box className="placeimgbox1">
                      <img
                        alt=""
                        src={val?.image_url ? val.image_url : ""}
                        className="placeimg1"
                      />
                    </Box>
                    <Typography
                      variant="body1"
                      className="typography-loginpage "
                      style={{
                        lineHeight: "1.5rem",
                        marginTop: "10px",
                        fontSize: "13px",
                      }}
                    >
                      {val?.user_name}
                    </Typography>
                    <Typography
                      variant="body2"
                      className="je typography-loginpage "
                    >
                      {val?.noOfGems}
                      <img
                        alt=""
                        src={diamands}
                        style={{ height: "20px", marginLeft: "5px" }}
                      />
                    </Typography>
                  </Box>
                )}
                {isDataLength && <Box style={{ minwidth: "30%" }}></Box>}
                {index === 2 ? (
                  <Box
                    className="no3"
                    onClick={() => {
                      setUserDetailsModal({ open: true, val: val });
                    }}
                  >
                    <img alt="" src={supercrown3} className="crown3" />
                    <Box className="placeimgbox3">
                      <img
                        alt=""
                        src={val?.image_url ? val.image_url : ""}
                        className="placeimg3"
                      />
                    </Box>
                    <Typography
                      variant="body1"
                      className="typography-loginpage "
                      style={{
                        lineHeight: "1.5rem",
                        marginTop: "10px",
                        fontSize: "12px",
                      }}
                    >
                      {val?.user_name}
                    </Typography>
                    <Typography
                      variant="body2"
                      className="je typography-loginpage "
                    >
                      {val?.noOfGems}
                      <img
                        alt=""
                        src={diamands}
                        style={{ height: "20px", marginLeft: "5px" }}
                      />
                    </Typography>
                  </Box>
                ) : isDataLength ? (
                  <Box></Box>
                ) : null}
              </>
            );
          })}
        </Box>
      )}
      <Box id="list" style={{ width: "94vw" }}>
        <InfiniteScroll
          dataLength={data.length}
          next={() => {
            getCurrentMonthSuperFans(lastDoc);
          }}
          style={{ overflow: "inherit" }}
          scrollableTarget="list"
          hasMore={true}
          scrollThreshold={"300px"}
          initialScrollY={0}
        >
          {data.map((val, index) => {
            return (
              <>
                {index >= 3 && (
                  <Box
                    className="list_box"
                    onClick={() => {
                      setUserDetailsModal({ open: true, val: val });
                    }}
                  >
                    <Typography
                      variant="body2"
                      className="je typography-loginpage "
                      sx={{ width: "20px" }}
                    >
                      {index + 1}
                    </Typography>
                    <div className="superfan-profile-pic">
                      <img
                        alt=""
                        src={val?.image_url ? val.image_url : ""}
                        className="listimg input-field-shadow"
                      />
                    </div>
                    <Typography
                      variant="body1"
                      className="typography-loginpage "
                      style={{
                        lineHeight: "1rem",
                        fontSize: "13px",
                        width: "70%",
                        textAlign: "left",
                      }}
                    >
                      {val?.user_name}
                    </Typography>
                    <Typography
                      variant="body2"
                      className="je typography-loginpage "
                      sx={{ textAlign: "right", marginLeft: "10px" }}
                    >
                      {val?.noOfGems}
                      <img
                        alt=""
                        src={diamands}
                        style={{ height: "20px", marginLeft: "5px" }}
                      />
                    </Typography>
                  </Box>
                )}
              </>
            );
          })}
        </InfiniteScroll>
      </Box>
      {data.length == 0 && !isLoading && (
        <Typography style={{ position: "fixed", top: "50%", left: "35%" }}>
          No records found
        </Typography>
      )}
      {userDetailsModal.open && isModal && (
        <CopyMailPhoneNumberModal
          close={() => handleClose()}
          val={userDetailsModal.val}
          toast={(val) => notify(val)}
        />
      )}
    </Box>
  );
}

export default Today;
