import { Box, Button } from "@mui/material";
import React from "react";
import diamands from "../../assests/diamands.png";
import "./UserVideoCall.scss";
import { useNavigate } from "react-router-dom";
import Spinner2 from "../../components/Spinner/Spinner2";

function Book() {
  const navigate = useNavigate();
  const request = (time, gemsRequired) => {
    navigate("/mainback/sendrequestforvideocall", {
      state: { callDuration: time, gemsForCall: gemsRequired },
    });
  };

  return (
    <Box>
      <Button
        variant="contained"
        fullWidth
        className="bookbtn button-text-align-start"
        onClick={() =>
          request(5, parseInt(process.env.REACT_APP_GEMS_FOR_5_MIN_VIDEO_CALL))
        }
      >
        {process.env.REACT_APP_MINS_VIDEO_CALL_TEXT.replace("5", "5")} @{" "}
        {parseInt(process.env.REACT_APP_GEMS_FOR_5_MIN_VIDEO_CALL)}
        <img
          alt=""
          src={diamands}
          style={{ height: "20px", marginLeft: "10px" }}
        />
      </Button>
      <Button
        variant="contained"
        fullWidth
        className="bookbtn button-text-align-start"
        onClick={() =>
          request(
            10,
            parseInt(process.env.REACT_APP_GEMS_FOR_10_MIN_VIDEO_CALL)
          )
        }
      >
        {process.env.REACT_APP_MINS_VIDEO_CALL_TEXT.replace("5", "10")} @{" "}
        {parseInt(process.env.REACT_APP_GEMS_FOR_10_MIN_VIDEO_CALL)}
        <img
          alt=""
          src={diamands}
          style={{ height: "20px", marginLeft: "10px" }}
        />
      </Button>
      <Button
        variant="contained"
        fullWidth
        className="bookbtn button-text-align-start"
        onClick={() =>
          request(
            30,
            parseInt(process.env.REACT_APP_GEMS_FOR_30_MIN_VIDEO_CALL)
          )
        }
      >
        {process.env.REACT_APP_MINS_VIDEO_CALL_TEXT.replace("5", "30")} @{" "}
        {parseInt(process.env.REACT_APP_GEMS_FOR_30_MIN_VIDEO_CALL)}
        <img
          alt=""
          src={diamands}
          style={{ height: "20px", marginLeft: "10px" }}
        />
      </Button>
      <Button
        variant="contained"
        fullWidth
        className="bookbtn button-text-align-start"
        onClick={() =>
          request(
            60,
            parseInt(process.env.REACT_APP_GEMS_FOR_60_MIN_VIDEO_CALL)
          )
        }
      >
        {process.env.REACT_APP_MINS_VIDEO_CALL_TEXT.replace("5", "60")} @{" "}
        {parseInt(process.env.REACT_APP_GEMS_FOR_60_MIN_VIDEO_CALL)}
        <img
          alt=""
          src={diamands}
          style={{ height: "20px", marginLeft: "10px" }}
        />
      </Button>
    </Box>
  );
}

export default Book;
