import React from "react";
import Jump_Hug from "../assests/gifs/Jump Hug.gif";
import Cute_Kiss_Puppy from "../assests/gifs/Cute Kiss Puppy.gif";
import Bunny_Love_You from "../assests/gifs/Bunny Love You.gif";
import Anime_Puchi from "../assests/gifs/Anime Puchi.gif";
import Teddy_Kiss from "../assests/gifs/Teddy Kiss.gif";
import Romantic_Candles from "../assests/gifs/Romantic Candles.gif";
import Virtual_Hug from "../assests/gifs/Virtual Hug.gif";
import Be_Mine from "../assests/gifs/Be Mine.gif";
import Lips_Kiss from "../assests/gifs/Lips Kiss.gif";
import Dragon_Breath from "../assests/gifs/Dragon Breath.gif";
import Heart_Kiss from "../assests/gifs/Heart Kiss.gif";
import Beautiful_Soul from "../assests/gifs/Beautiful Soul.gif";
import Smitten from "../assests/gifs/Smitten.gif";
import All_my_Love from "../assests/gifs/All my Love.gif";
import Bear_Hug from "../assests/gifs/Bear Hug.gif";
import Hand_Hold from "../assests/gifs/Hand Hold.gif";
import Kisses_Me_to_You from "../assests/gifs/Kisses Me to You.gif";
import Love_Your_Everything from "../assests/gifs/Love Your Everything.gif";
import Cute_Kiss from "../assests/gifs/Cute Kiss.gif";
import I_Love_You_Art from "../assests/gifs/I Love You Art.gif";
import Romantic_Couple from "../assests/gifs/Romantic Couple.gif";
import Love_arrow from "../assests/gifs/Love arrow.gif";
import Hearts_OverDose from "../assests/gifs/Hearts OverDose.gif";
import Sexy_Kiss_X from "../assests/gifs/Sexy Kiss X.gif";
import Phoneix_Medallian from "../assests/gifs/Phoneix Medallian.gif";
import Ice_Heart from "../assests/gifs/Ice Heart.gif";
import Hot_Sexy_Superbabe from "../assests/gifs/Hot Sexy Superbabe.gif";

export const GifsData = [
  {
    gif_id: 1,
    noOfGems: 30,
    name: "Jump Hug.gif",
    image: Jump_Hug,
  },
  {
    gif_id: 2,
    noOfGems: 10,
    name: "Cute Kiss Puppy.gif",
    image: Cute_Kiss_Puppy,
  },
  {
    gif_id: 3,
    noOfGems: 20,
    name: "Bunny Love You.gif",
    image: Bunny_Love_You,
  },
  {
    gif_id: 4,
    noOfGems: 20,
    name: "Anime Puchi.gif",
    image: Anime_Puchi,
  },
  {
    gif_id: 5,
    noOfGems: 20,
    name: "Teddy Kiss.gif",
    image: Teddy_Kiss,
  },
  {
    gif_id: 6,
    noOfGems: 30,
    name: "Romantic Candles.gif",
    image: Romantic_Candles,
  },
  {
    gif_id: 7,
    noOfGems: 30,
    name: "Virtual Hug.gif",
    image: Virtual_Hug,
  },
  {
    gif_id: 8,
    noOfGems: 30,
    name: "Be Mine.gif",
    image: Be_Mine,
  },
  {
    gif_id: 9,
    noOfGems: 30,
    name: "Lips Kiss.gif",
    image: Lips_Kiss,
  },
  {
    gif_id: 10,
    noOfGems: 30,
    name: "Dragon Breath.gif",
    image: Dragon_Breath,
  },
  {
    gif_id: 11,
    noOfGems: 30,
    name: "Heart Kiss.gif",
    image: Heart_Kiss,
  },
  {
    gif_id: 12,
    noOfGems: 40,
    name: "Beautiful Soul.gif",
    image: Beautiful_Soul,
  },
  {
    gif_id: 13,
    noOfGems: 50,
    name: "Smitten.gif",
    image: Smitten,
  },
  {
    gif_id: 14,
    noOfGems: 50,
    name: "All my Love.gif",
    image: All_my_Love,
  },
  {
    gif_id: 15,
    noOfGems: 50,
    name: "Bear Hug.gif",
    image: Bear_Hug,
  },
  {
    gif_id: 16,
    noOfGems: 60,
    name: "Hand Hold.gif",
    image: Hand_Hold,
  },
  {
    gif_id: 17,
    noOfGems: 70,
    name: "Kisses Me to You.gif",
    image: Kisses_Me_to_You,
  },
  {
    gif_id: 18,
    noOfGems: 90,
    name: "Love Your Everything.gif",
    image: Love_Your_Everything,
  },
  {
    gif_id: 19,
    noOfGems: 100,
    name: "Cute Kiss.gif",
    image: Cute_Kiss,
  },
  {
    gif_id: 20,
    noOfGems: 100,
    name: "I Love You Art.gif",
    image: I_Love_You_Art,
  },
  {
    gif_id: 21,
    noOfGems: 100,
    name: "Romantic Couple.gif",
    image: Romantic_Couple,
  },
  {
    gif_id: 22,
    noOfGems: 100,
    name: "Love arrow.gif",
    image: Love_arrow,
  },
  {
    gif_id: 23,
    noOfGems: 200,
    name: "Hearts OverDose.gif",
    image: Hearts_OverDose,
  },
  {
    gif_id: 24,
    noOfGems: 250,
    name: "Sexy Kiss X.gif",
    image: Sexy_Kiss_X,
  },
  {
    gif_id: 25,
    noOfGems: 350,
    name: "Phoneix Medallian.gif",
    image: Phoneix_Medallian,
  },
  {
    gif_id: 26,
    noOfGems: 500,
    name: "Ice Heart.gif",
    image: Ice_Heart,
  },
  {
    gif_id: 27,
    noOfGems: 500,
    name: "Hot Sexy Superbabe.gif",
    image: Hot_Sexy_Superbabe,
  },
];

export const DisplayImage = ({ src, style }) => (
  <img src={src.image} style={{ ...style }} />
);
