import React, { useEffect, useState } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { useLocation, useNavigate } from "react-router-dom";
import "./AudienceSideLive.scss";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  startAfter,
  updateDoc,
  where,
  getDoc,
} from "firebase/firestore";
import SendIcon from "@mui/icons-material/Send";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { db } from "../../firebaseConfig/firebaseConfig";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  TextField,
  Typography,
} from "@mui/material";
// import VideocamIcon from "@mui/icons-material/Videocam";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import logo from "../../component/Images/logo.png";
import eye from "../../assests/eye.png";
// import { HandymanOutlined, SetMealSharp } from "@mui/icons-material";
import Gifs from "../../components/gifs/Gifs";
import jems from "../../component/Images/jems.png";
import InfiniteScroll from "react-infinite-scroll-component";
import { ExpandLess } from "@mui/icons-material";
import { GifsData, DisplayImage } from "../../configs/gifAssests";
var rtc = {
  client: null,
  joined: false,
  published: false,
  localStream: null,
  remoteStreams: [],
  params: {},
};

function AudienceSideLive() {
  const location = useLocation();
  const navigate = useNavigate();
  const [liveData, setLiveData] = useState({});
  const [viewCount, setTotalViewCount] = useState(0);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [hide, setHide] = useState(false);
  const [liveChatText, setLiveChatText] = useState("");
  const [gifOpenModal, setGifOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [messges, setMessages] = useState([]);
  const [sentMessage, setSendMessage] = useState({ open: false, val: "" });
  const [wallet, setWallet] = useState();
  const [liveChatGifs, setLiveChatGifs] = useState([]);
  const [liveChat, setLiveChat] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [inSufficientWallet, setInsufficientWallet] = useState({
    open: false,
    val: "",
  });
  const [pressed, setPressed] = useState(false);

  useEffect(() => {
    joinChannel(location.state?.role);

    const q2 = doc(
      db,
      process.env.REACT_APP_WALLET_COLLECTION_NAME,
      user.idUser
    );
    getDoc(q2).then((response) => {
      //  setSelectedUserWallet(response.data());
      const walletData = response?.data();
      setWallet(walletData);
    });
  }, []);

  var option = {
    appID: process.env.REACT_APP_AGORA_APP_ID,
    channel: process.env.REACT_APP_AGORA_CHANNEL_NAME,
    uid: null,
    token: location?.state?.accessToken || null,
  };

  async function joinChannel(role) {
    // Create a client
    rtc.client = AgoraRTC.createClient({ mode: "live", codec: "h264" });

    rtc.client.on("user-published", async (user, mediaType) => {
      if (mediaType === "video") {
        const remoteVideoStream = await rtc.client.subscribe(user, mediaType);
        remoteVideoStream.play("remote_video_");
      }
    });

    rtc.client.on("user-unpublished", async (user) => {
      const remoteVideoElement = document.getElementById(
        `remote_video_${user.uid}`
      );
      if (remoteVideoElement) {
        remoteVideoElement.innerHTML = "";
      }
    });

    rtc.client.on("connection-state-change", (currentState, prevState) => {
      if (currentState === "DISCONNECTED") {
      }
    });

    try {
      await rtc.client.join(option.appID, option.channel, option.token, null);
    } catch (err) {
      console.error("Error joining channel:", err);
      return;
    }

    rtc.params.uid = rtc.client.uid;

    // if (role === "host") {
    //   rtc.client.setClientRole("host");

    //   // Create a local audio track (but don't play it immediately)
    //   rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();

    //   rtc.client.on("connection-state-change", function (evt) {
    //     console.log("audience", evt);
    //   });
    // }

    if (role === "audience") {
      rtc.client.on("user-published", async (user, mediaType) => {
        if (mediaType === "audio") {
          // Subscribe to the host's audio stream and play it
          const remoteAudioStream = await rtc.client.subscribe(user, mediaType);
          remoteAudioStream.play(); // Automatically plays the host's audio
        }
        if (mediaType === "video") {
          const remoteVideoStream = await rtc.client.subscribe(user, mediaType);
          remoteVideoStream.play("remote_video_");
        }
      });

      rtc.client.on("user-unpublished", async (user) => {
        const remoteVideoElement = document.getElementById(
          `remote_video_${user.uid}`
        );
        if (remoteVideoElement) {
          remoteVideoElement.innerHTML = "";
        }
      });

      rtc.client.on("connection-state-change", function (evt) {
        // console.log("audience", evt);
      });
    }
  }

  if (liveData?.state == "finished") {
    navigate("/userlive", { replace: true });
  }
  const handleSendGif = (ele) => {
    if (ele.noOfGems !== 0) {
      if (wallet?.balance >= ele.noOfGems) {
        setSendMessage({ open: true, val: ele });
      } else {
        setInsufficientWallet({ open: true, val: ele });
      }
    } else {
      setSendMessage({ open: false, val: ele });
    }
    setGifOpenModal({ open: false });
  };
  useEffect(() => {
    let q2 = query(
      collection(
        db,
        process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
        location.state?.strimingid,
        process.env.REACT_APP_WHO_JOIN_LIVE_COLLECTION_NAME
      ),
      where("liveJoinStatus", "==", "join")
    );
    onSnapshot(q2, (querySnapshot) => {
      let liveJoinUsersArr = [];
      querySnapshot.forEach((doc) => {
        liveJoinUsersArr.push(doc.data());
      });

      setTotalViewCount(liveJoinUsersArr.length);
    });

    let q4 = query(
      collection(db, process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME),
      orderBy("createdOn", "desc"),
      limit(1)
    );
    onSnapshot(q4, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setLiveData(doc.data());
      });
    });
    getGifsUsersSend();
    getChatsMessages();
    window.scrollTo({ behavior: "smooth" });
  }, []);

  const getGifsUsersSend = () => {
    let q6 = query(
      collection(
        db,
        process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
        location.state?.strimingid,
        process.env.REACT_APP_LIVE_CHAT_GIFS_COLLECTION_NAME
      ),
      orderBy("sentOn", "desc")
    );
    onSnapshot(q6, (querySnapshot) => {
      let gifsofUsers = [];
      querySnapshot.forEach((doc) => {
        gifsofUsers.push(doc.data());
      });

      setLiveChatGifs(gifsofUsers);
    });
  };

  const getChatsMessages = (lastDoc = null) => {
    let q3;
    if (lastDoc) {
      q3 = query(
        collection(
          db,
          process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
          location.state?.strimingid,
          process.env.REACT_APP_LIVE_CHAT_MESSAGES_COLLECTION_NAME
        ),
        orderBy("createdAt", "desc"),
        startAfter(lastDoc),
        limit(15)
      );
    } else {
      q3 = query(
        collection(
          db,
          process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
          location.state?.strimingid,
          process.env.REACT_APP_LIVE_CHAT_MESSAGES_COLLECTION_NAME
        ),
        orderBy("createdAt", "desc"),
        limit(15)
      );
    }
    onSnapshot(q3, (querySnapshot) => {
      let chats = [];
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      // if (!hide) {

      querySnapshot.forEach((doc) => {
        chats.push(doc.data());
      });
      chats.reverse();
      if (liveChat.length < chats.length) {
        setLiveChat([...chats, ...liveChat]);
      }
      // setLiveChat((prevChat) => [...prevChat, ...chats]);
      // }

      // setMessages((prevMessages) => [...prevMessages, ...chats]);
    });
  };

  const handleSendMessages = (ele) => {
    let chatMessage = {
      gifPath: 0,
      giphyName: ele.name.replace(".gif", ""),
      noOfGems: ele.noOfGems,
      sentOn: serverTimestamp(),
      uid: user.idUser,
      senderName: user.name,
    };

    let updatedWallet = {
      ...wallet,
      balance: wallet?.balance - chatMessage.noOfGems,
    };
    setWallet(updatedWallet);
    localStorage.setItem(
      process.env.REACT_APP_WALLET_LOCALSTORAGE,
      JSON.stringify(updatedWallet)
    );

    const querySnapshot = doc(
      db,
      process.env.REACT_APP_WALLET_COLLECTION_NAME,
      user.idUser
    );
    updateDoc(querySnapshot, {
      balance: updatedWallet.balance,
    }).then(() => {});

    addDoc(
      collection(
        db,
        process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
        location.state?.strimingid,
        process.env.REACT_APP_LIVE_CHAT_GIFS_COLLECTION_NAME
      ),
      chatMessage
    ).then(async (result) => {
      let userTransactionnsTable = {
        dm_message_gif: "",
        dm_message_image: "",
        dm_message_text: "",
        live_session_purchase_gif_name: ele.name,
        live_session_ref_id: location.state?.strimingid,
        platform: process.env.REACT_APP_PLATFORM,
        purchased_media_description: "",
        purchased_media_title: "",
        purchased_media_url: "",
        purchased_product_description: "",
        purchased_product_title: "",
        recharge_gems_transaction_amount: "",
        recharge_gems_transaction_id: "",
        transaction_gems: chatMessage.noOfGems,
        transaction_gems_string: `-${chatMessage.noOfGems}`,
        transaction_ref_id: result.id,
        transaction_time: serverTimestamp(),
        transaction_type_id:
          process.env.REACT_APP_SENT_GIF_IN_LIVE_STRMINING_TYPE_ID,
        transaction_type_name:
          process.env.REACT_APP_SENT_GIF_IN_LIVE_STRMINING_TYPE_NAME,
        updated_balance_after_transaction: `${updatedWallet.balance}`,
        user_email: user.email,
        user_id: user.idUser,
        user_name: user.name,
        version: process.env.REACT_APP_VERSION,
        "model-identifier": process.env.REACT_APP_MODEL_IDENTIFIER,
      };

      await addDoc(
        collection(db, process.env.REACT_APP_USERTRANSACTIONS_COLLECTION),
        userTransactionnsTable
      ).then(() => {});
    });
    setGifOpenModal(false);
    setSendMessage({ open: false, val: ele });
  };

  const handleUserExit = () => {
    if (rtc.localStream) {
      rtc.localStream.muteAudio();
    }
    let userExitFormLive = query(
      collection(
        db,
        process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
        location.state?.strimingid,
        process.env.REACT_APP_WHO_JOIN_LIVE_COLLECTION_NAME
      ),
      where("uid", "==", user.idUser)
    );
    getDocs(userExitFormLive).then((result) => {
      result.forEach(async (result) => {
        await updateDoc(
          doc(
            db,
            process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
            location.state.strimingid,
            process.env.REACT_APP_WHO_JOIN_LIVE_COLLECTION_NAME,
            result.id
          ),
          { liveJoinStatus: "exit" }
        ).then(() => {
          rtc.client.leave();
          navigate("/userlive", { replace: true });
        });
      });
    });
  };

  const handleSendChatText = () => {
    let chatMessage = {
      brand: "-",
      deviceModel: "web",
      deviceOsVersion: "-",
      chatMessage: liveChatText.trimStart(),
      createdAt: serverTimestamp(),
      name: user.name,
      // noOfGemsPerComments: location.state?.gemForUserComment,
      // enableCommentsInLiveStreaming: false,
      userDp: user.urlAvatar,
      platform: process.env.REACT_APP_PLATFORM,
      version: process.env.REACT_APP_VERSION,
      email: user.email,
      idUser: user.idUser,
      isRead: process.env.REACT_APP_LIVE_CHAT_MESSAGES_ISREAD,
      sentBy: process.env.REACT_APP_DM_FROM_SENDER,
    };
    setLiveChatText("");

    addDoc(
      collection(
        db,
        process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
        location.state?.strimingid,
        process.env.REACT_APP_LIVE_CHAT_MESSAGES_COLLECTION_NAME
      ),
      chatMessage
    ).then(() => {});
  };

  return (
    <div onClick={() => (gifOpenModal ? setGifOpenModal(false) : null)}>
      <Box className="hh">
        <Box className="header">
          <Grid container className="headbox">
            <Grid item xs={1.5}>
              <Box className="menubtn">
                <ArrowBackIcon
                  style={{ color: "var(--white)" }}
                  onClick={() => setOpen(true)}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <img alt="" src={logo} className="simranimg" />
            </Grid>
            <Grid
              item
              xs={1.5}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <img
                alt=""
                src={eye}
                className="simranimg"
                style={{ width: "30px", height: "30px", aspectRatio: "1/1" }}
              />
              <Typography>{viewCount}</Typography>
            </Grid>
          </Grid>
          <Box className="cmline"></Box>
        </Box>
        {liveChatGifs.length > 0 && !hide && (
          <Box
            className="gif-box"
            style={{
              display: "flex",
              position: "fixed",
              top: "65px",
              overflow: "scroll",
              left: "0px",
              width: "100vw",
              zIndex: "500",
              padding: "10px",
            }}
          >
            {liveChatGifs.map((ele) => {
              let gifSrc = {};
              let timeString = `${ele.sentOn?.toDate().toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })}`;
              GifsData.map((gif) => {
                if (gif.name.replaceAll(".gif", "") === ele.giphyName) {
                  gifSrc = { ...gif };
                }
              });
              return (
                <div
                  style={{
                    width: "15vw",
                    minWidth: "15vw",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginRight: "20px",
                  }}
                >
                  <DisplayImage
                    src={gifSrc}
                    style={{
                      width: "inherit",
                      aspectRatio: "1/1",
                      borderRadius: "50%",
                      overflow: "hidden",
                    }}
                  />
                  <Typography className="text-align-center font-size text-shadow-typography">
                    {ele.senderName}
                  </Typography>
                  <Typography className="text-align-center font-size text-shadow-typography">
                    {timeString}
                  </Typography>
                </div>
              );
            })}
          </Box>
        )}
        <Box
          style={{
            position: "fixed",
            bottom: "75px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            zIndex: "1000",
            width: "100vw",
          }}
        >
          {!hide && (
            <Button
              className="typography-loginpage loginpage-button input-field-shadow"
              style={{
                zIndex: "1000",
                backgroundColor: "var(--trnaparentPink1)",
                color: "var(--white)",
                height: "38px",
                borderRadius: "18px",
                border: "1px solid var(--white)",
                textTransform: "none",
                marginBottom: "15px",
              }}
              onClick={() => setHide(true)}
            >
              <span style={{ color: "var(--white)" }}>Tap to hide</span>
            </Button>
          )}
          {!hide && (
            <>
              <Box
                id="chatBox1"
                className="chatBox"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "auto",
                }}
              >
                <InfiniteScroll
                  dataLength={liveChat.length}
                  next={() => {
                    getChatsMessages(lastDoc);
                  }}
                  style={{ display: "flex", flexDirection: "column" }}
                  scrollableTarget="chatBox1"
                  hasMore={true}
                  scrollThreshold={"300px"}
                  initialScrollY={0}
                >
                  {!hide &&
                    liveChat?.map((ele) => {
                      return (
                        <>
                          <div
                            className="simranK chatmessages"
                            style={{
                              display: "flex",
                              width: "calc(100vw - 10px)",
                              paddingLeft: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <img
                              className="input-field-shadow"
                              src={ele.urlAvatar}
                              style={{
                                width: "40px",
                                aspectRatio: "1/1",
                                height: "40px",
                                borderRadius: "50%",
                                border: "1px solid var(--white)",
                              }}
                            />
                            <Box style={{ marginLeft: "10px" }}>
                              <Typography className="text-shadow-typography chat-font-size">
                                {ele.name}
                              </Typography>
                              <Typography className="text-shadow-typography chat-font-size">
                                {ele.chatMessage}
                              </Typography>
                            </Box>
                          </div>
                        </>
                      );
                    })}
                </InfiniteScroll>
              </Box>
            </>
          )}
        </Box>
        <Box className="audience-side-live">
          <div
            id="remote_video_"
            style={{
              width: "100vw",
              position: "absolute",
              top: "64px",
              height: "calc(100vh - 64px)",
              overflow: "hidden",
            }}
          />
          {location.state?.role == "audience" && !hide && (
            <Box
              style={{
                display: "flex",
                position: "fixed",
                bottom: "10px",
                width: "calc(100vw - 20px)",
                justifyContent: "space-between",
                padding: "10px",
                alignItems: "center",
                borderRadius: "25px",
              }}
            >
              <TextField
                focused={false}
                placeholder="Type your message"
                value={liveChatText}
                onChange={(e) => setLiveChatText(e.target.value)}
                style={{
                  borderRadius: "25px",
                  outline: "none !important",
                  width: "70vw",
                  backgroundColor: "var(--trnaparentBlack)",
                }}
              />
              <Box className="dmc" onClick={() => setGifOpenModal(true)}>
                <CardGiftcardIcon />
              </Box>
              <Box
                className="dma"
                onClick={() =>
                  liveChatText.trim() !== "" ? handleSendChatText() : null
                }
              >
                <SendIcon style={{ color: "var(--white) !important" }} />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {gifOpenModal && (
        <Gifs
          open={gifOpenModal}
          close={(ele) => handleSendGif(ele)}
          color="transparent"
        />
      )}
      {open && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="responsive-dialog-title "
          className="loguotpop"
        >
          <DialogTitle className="dialog-title-background typography-loginpage">
            {process.env.REACT_APP_EXIT_DIALOG_NAME}
          </DialogTitle>

          <DialogContent>
            <DialogContentText className="typography-loginpage">
              {process.env.REACT_APP_LEAVE_SESSION_LIVE1}
            </DialogContentText>
            <DialogContentText className="typography-loginpage">
              {process.env.REACT_APP_LEAVE_SESSION_LIVE2}
            </DialogContentText>
            <Button
              className="typography-loginpage loginpage-button"
              onClick={() => handleUserExit()}
            >
              {process.env.REACT_APP_YES_BUTTON1}
            </Button>
            <Button
              className="typography-loginpage loginpage-button"
              onClick={() => setOpen(false)}
            >
              {process.env.REACT_APP_NO_BUTTON}
            </Button>
          </DialogContent>
        </Dialog>
      )}
      {sentMessage.open && (
        <Dialog
          open={sentMessage.open}
          onClose={() => setSendMessage({ open: false, val: "" })}
          className="photopopup"
          aria-labelledby="draggable-dialog-title"
        >
          <DialogContent id="context">
            <DialogContentText className="context-div">
              <Typography variant="h6" id="modeltext">
                {process.env.REACT_APP_WALLET_BALANCE_TEXT}:{wallet?.balance}
              </Typography>
              <img alt="" src={jems} className="jemsimg" />
            </DialogContentText>
          </DialogContent>
          <DialogActions className="modelbtndiv">
            <Button
              variant="contained"
              onClick={() => handleSendMessages(sentMessage.val)}
              id="popbtn"
            >
              {process.env.REACT_APP_BUY_FOR_TEXT} &nbsp;
              {sentMessage.val.noOfGems}
              <img alt="" src={jems} className="jemsimg" />
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setSendMessage({ open: false, val: "" });
                setGifOpenModal(false);
              }}
              id="popbtn"
            >
              {process.env.REACT_APP_MODAL_CANCEL_BUTTON_TEXT}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {hide && (
        <Box className="show-button">
          <Box onClick={() => setHide(false)} className="back-icon-box">
            <ExpandLess style={{ color: "var(--white)" }} />
          </Box>
        </Box>
      )}
      {inSufficientWallet.open && (
        <Dialog
          open={inSufficientWallet.open}
          onClose={() => inSufficientWallet({ open: false, val: "" })}
          className="photopopup"
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle id="modeltext" className="context-div">
            {process.env.REACT_APP_INSUFFICIENT_BALANCE}
          </DialogTitle>
          <Divider style={{ backgroundColor: "var(--white)" }} />

          <DialogContent id="context">
            <DialogContentText className="context-div">
              <Typography variant="h6" id="modeltext">
                {process.env.REACT_APP_WALLET_BALANCE_TEXT}: {wallet?.balance}
              </Typography>
              <img alt="" src={jems} className="jemsimg" />
            </DialogContentText>
          </DialogContent>

          <DialogContent id="context">
            <DialogContentText className="context-div">
              <Typography variant="h6" id="modeltext">
                {process.env.REACT_APP_NEED_GEMS_FOR_TEXT}:{" "}
                {inSufficientWallet.val.noOfGems - wallet?.balance}
              </Typography>
              <img alt="" src={jems} className="jemsimg" />
            </DialogContentText>
          </DialogContent>

          <DialogActions className="modelbtndiv">
            <Button
              variant="contained"
              id="popbtn"
              onClick={() =>
                window.open(process.env.REACT_APP_DARKMATTER_PAYMENT_LINK)
              }
            >
              {process.env.REACT_APP_BUY_FOR_TEXT}{" "}
              {inSufficientWallet.val.noOfGems}
              <img alt="" src={jems} className="jemsimg" />
            </Button>

            <Button
              variant="contained"
              onClick={() => setInsufficientWallet({ open: false, val: "" })}
              id="popbtn"
            >
              {process.env.REACT_APP_MODAL_CANCEL_BUTTON_TEXT}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/* <button onClick={() => joinChannel('host')}>Join Channel as Host</button>
            <button onClick={() => joinChannel('audience')}>Join Channel as Audience</button>
            <button onClick={() => leaveEventHost('host')}>Leave Event Host</button>
            <button onClick={() => leaveEventAudience('audience')}>Leave Event Audience</button> */}
    </div>
  );
}

export default AudienceSideLive;
