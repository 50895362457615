import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ModelProfilePicture from "../../assests/modelprofilepic.jpeg";
import play_icon from "../../component/Images/play_icon.png";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import { useNavigate } from "react-router-dom";
import Spinners from "../../components/Spinner/Spinners";
import Spinner2 from "../../components/Spinner/Spinner2";

function MyPurchaseVideo() {
  const navigate = useNavigate();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [myPurchedVideos, setMyPurchedVideos] = useState(
    JSON.parse(
      localStorage.getItem(
        process.env.REACT_APP_PURCHASEDVIDEOS_COLLECTION_NAME
      )
    )
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({ behavior: "smooth" });
    setIsLoading(true);

    let result1 = [];
    const q1 = query(
      collection(db, process.env.REACT_APP_PURCHASEDVIDEOS_COLLECTION_NAME),
      where("uid", "==", user.idUser),
      orderBy("boughtOn", "desc")
    );

    getDocs(q1).then((result) => {
      result.forEach((doc) => {
        result1.push(doc.data());
        setMyPurchedVideos(result1);
      });

      setIsLoading(false);
    });
  }, []);

  return (
    <>
      {isLoading ? <Spinner2 /> : null}
      {myPurchedVideos.length == 0 && !isLoading && (
        <Typography
          style={{
            position: "fixed",
            top: "50%",
            width: "100vw",
            textAlign: "center",
            left: 0,
          }}
        >
          No records found
        </Typography>
      )}
      <Box>
        {myPurchedVideos.map((ele) => {
          return (
            <>
              <Grid container className="videodiv" columnSpacing={0}>
                <Grid item className="videodiv_video">
                  <div className="profilepic">
                    <img alt="" src={ModelProfilePicture} className="video" />
                  </div>
                </Grid>
                <Grid item style={{ width: "calc(100% - 65px)" }}>
                  <Typography variant="body1" id="videousername">
                    {process.env.REACT_APP_MODAL_NAME}
                  </Typography>
                  <Typography variant="body2" id="videocaption">
                    {ele.vdodesc}
                  </Typography>
                </Grid>
              </Grid>
              <Box
                className="postvideodiv"
                onClick={() => navigate("/videos", { state: { val: ele } })}
              >
                <Box className="postdivvideo">
                  <img alt="" src={ele.thumbUrl} className="postvideo" />
                </Box>
                <Box className="videolockcontant">
                  <img alt="" src={play_icon} id="playimg" />
                </Box>
                <Box className="videolockcontant">
                  <img alt="" src={play_icon} id="playimg" />
                </Box>
              </Box>

              <Box className="photoendline" />
            </>
          );
        })}
      </Box>
    </>
  );
}

export default MyPurchaseVideo;
