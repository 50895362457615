import { Box, Button, Grid, Typography } from '@mui/material'
import VideocamIcon from '@mui/icons-material/Videocam';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react'
import logo from '../../../component/Images/logo.png'
import jems from '../../../component/Images/jems.png'
import '../MainHead/Mainhead.scss'
import { Link, Outlet, useNavigate } from 'react-router-dom';
function Backhead(props) {
    const navigate = useNavigate();
    let isModal = JSON.parse(localStorage.getItem(process.env.REACT_APP_ISMODAL_FLAG));

    return (
        <Box className='hh'>
            <Box className='header'>

                <Grid container className='headbox' >
                    <Grid item xs={1.5}>
                        <Box className='menubtn'>
                            <ArrowBackIcon style={{color:'var(--white)'}} onClick={()=>props?.close ? props.close() : navigate(-1)}/>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <img alt='' src={logo} className='simranimg' />
                    </Grid>
                    <Grid item xs={3}>
                        <Button id='videobtn' variant="contained" startIcon={<VideocamIcon />} onClick={()=>navigate(isModal ? "/back-s/model_call":"/mainback/uservideocall",{state:{muted:true}})}>CALL</Button>
                    </Grid>

                </Grid>
                <Box className='cmline'></Box>
            </Box>
            <Box>
                <Outlet />
            </Box>
        </Box>
    )
}

export default Backhead