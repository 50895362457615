import { Box, Typography } from "@mui/material";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebaseConfig/firebaseConfig";
import Spinners from "../../components/Spinner/Spinners";

function Model_call_completed() {
  const [completedRequest, setCompletedRequest] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getCompletedVideoCallRequest();
  }, []);
  const getCompletedVideoCallRequest = () => {
    let completedCalls = [];
    let completedCall = query(
      collection(
        db,
        process.env.REACT_APP_VIDEO_CALL_COMPLETED_COLLECTION_NAME
      ),
      orderBy("callEndedAt", "desc")
    );
    getDocs(completedCall).then((list) => {
      list.forEach((call) => {
        completedCalls.push({ ...call.data(), completedCallDocId: call.id });
      });

      setCompletedRequest(completedCalls);
      setIsLoading(false);
    });
  };

  return (
    <Box className="model_call_completed">
      {isLoading ? <Spinners /> : null}

      {completedRequest.length === 0 && !isLoading ? (
        <Typography style={{ top: "50%", left: "40%", position: "fixed" }}>
          No Data
        </Typography>
      ) : (
        completedRequest.map((val) => {
          const dateTimeString = `${val.callEndedAt
            ?.toDate()
            .toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })} ${new Date(val.callEndedAt?.toDate()).toLocaleDateString(
            "en-US",
            { year: "numeric", month: "long", day: "numeric" }
          )}`;
          const dateTimeString2 = `${val.requestedAt
            ?.toDate()
            .toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })} ${new Date(val.requestedAt?.toDate()).toLocaleDateString(
            "en-US",
            { year: "numeric", month: "long", day: "numeric" }
          )}`;
          return (
            <Box className="contant_box b">
              <Typography variant="body2" className="typography-loginpage tt">
                Name: {val.name}
              </Typography>
              <Typography variant="body2" className="typography-loginpage tt">
                Email: {val.email}
              </Typography>
              <Typography variant="body2" className="typography-loginpage tt">
                Contact: {val.phone}
              </Typography>
              <Typography variant="body2" className="typography-loginpage tt">
                Requested At: {dateTimeString2}
              </Typography>
              <Typography variant="body2" className="typography-loginpage tt">
                Completed At: {dateTimeString}
              </Typography>
              <Typography variant="body2" className="typography-loginpage tt">
                Duration: {val.mins}
              </Typography>
              {val?.specialReqMsg && (
                <Typography variant="body2" className="typography-loginpage tt">
                  Special Request: {val?.specialReqMsg}
                </Typography>
              )}
            </Box>
          );
        })
      )}
    </Box>
  );
}

export default Model_call_completed;
