import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import React from "react";
import "./ModalComponent.scss";
import "../../App.scss";

const ModalComponent = (props) => {
  console.log(props.information, "TEST");
  return (
    <Dialog
      open={props.open}
      onClose={() => props.close()}
      aria-labelledby="responsive-dialog-title"
      className="popup"
    >
      <DialogTitle className="dialog-title-background typography-loginpage">
        {props.title}
      </DialogTitle>
      <Divider />
      <DialogContent>
        {props.text.startsWith("Due") ? (
          <div
            dangerouslySetInnerHTML={{
              __html: props.text.replace(/\n/g, "<br>"),
            }}
            style={{ color: "white", fontFamily: "var(--font)" }}
          />
        ) : (
          <DialogContentText
            className="typography-loginpage"
            style={{ marginBottom: "20px" }}
          >
            {props.text}
          </DialogContentText>
        )}
        {props.text.startsWith("Due") ? (
          <Button
            className="typography-loginpage"
            onClick={() => props.close()}
            style={{ marginTop: "2em" }}
          >
            {process.env.REACT_APP_MODAL_OKAY_BUTTON}
          </Button>
        ) : (
          <Button
            className="typography-loginpage"
            onClick={() => props.close()}
          >
            {process.env.REACT_APP_MODAL_OKAY_BUTTON}
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalComponent;
