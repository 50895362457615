import { Box, Typography, duration } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./UserVideoCall.scss";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";

function VideoCompleted() {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [userCompletedRequest, setUserCompletedRequet] = useState([]);
  useEffect(() => {
    getCompletedRequest();
  }, []);

  const getCompletedRequest = () => {
    try {
      let q1 = query(
        collection(
          db,
          process.env.REACT_APP_VIDEO_CALL_COMPLETED_COLLECTION_NAME
        ),
        where("uid", "==", user.idUser),
        orderBy("requestedAt", "desc")
      );
      getDocs(q1).then((result) => {
        let details = [];
        result.forEach((doc) => {
          details.push(doc.data());
        });
        setUserCompletedRequet(details);
      });
    } catch (error) {
      console.log(error, "ERROR");
    }
  };

  return (
    <Box>
      {userCompletedRequest.length === 0 ? (
        <Typography style={{ left: "40%", position: "fixed" }}>
          No Data
        </Typography>
      ) : (
        userCompletedRequest.map((val) => {
          return (
            <>
              <Box className="cmdbox">
                <Typography variant="body2" className="cpmmsg">
                  {process.env.REACT_APP_REQIESTED_ON_TEXT.replaceAll('"', " ")}{" "}
                  {val.date} {val.time}{" "}
                </Typography>
                <Typography variant="body2" className="cpmmsg">
                  {process.env.REACT_APP_VIDEO_CALL_DURATION_TEXT}: {val.mins}{" "}
                  mins
                </Typography>
              </Box>
            </>
          );
        })
      )}
    </Box>
  );
}

export default VideoCompleted;
