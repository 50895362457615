import React, { useEffect, useState } from "react";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import Spinners from "../Spinner/Spinners";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Typography } from "@mui/material";
const Banner = () => {
  const [pageData, setPageData] = useState({
    bannerInfo: [],
    loading: true,
  });

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    const q2 = query(
      collection(db, process.env.REACT_APP_BANNER_INFO_COLLECTION_NAME),
      orderBy("uploadTime", "desc")
    );
    getDocs(q2).then((result) => {
      const banner = [];
      result.forEach((doc) => {
        banner.push(doc.data());
        setPageData({ bannerInfo: banner, loading: false });
      });
    });
  };

  return (
    <div>
      {pageData.loading && pageData.bannerInfo.length !== 0 ? (
        <Spinners />
      ) : pageData.bannerInfo.length === 0 && pageData.loading === false ? (
        <div>
          <Typography style={{ top: "50%", left: "40%", position: "fixed" }}>
            No Data
          </Typography>
        </div>
      ) : (
        <div className="bannerSection">
          <Carousel>
            {pageData.bannerInfo.map((ele) => {
              return (
                <div
                // onClick={() => {
                //   console.log("clicked");
                //   ele?.bannerType == "image"
                //     ? navigate("/userhomepage/photos")
                //     : navigate("/userhomepage/videos");
                // }}
                >
                  <img
                    src={ele.bannerUrl}
                    style={{
                      border: "1px solid white",
                      objectFit: "cover",
                    }}
                  />
                </div>
              );
            })}
          </Carousel>
        </div>
      )}{" "}
    </div>
  );
};

export default Banner;
